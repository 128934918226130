import React, { useState, useRef, useEffect } from 'react';
import { LoaderOne, TableStyle } from '../../../uiComponents';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { FcEditImage, FcPrint } from 'react-icons/fc';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { get } from '../../../utils/methods';
import VisaInvoicePrint from '../../../pages/VisaInvoice/VisaInvoicePrint';
import UsersRoles from '../../../utils/Constants/UserRoles.constants';
const { StyledTableCell, StyledTableRow } = TableStyle;
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const VisaInvoiceListRow = (props) => {
  const alert = useAlert();
  const reduxState = useSelector(({ visaInvoice, users }) => {
    return {
      user: users.user,
    };
  });
  let { user } = reduxState;

  let printDiv = useRef();
  const {
    invoiceId,
    invoiceDate,
    clientName,
    clientMobileNumber,
    noOfPassports,
    sellingPrice,
    visaForCountry,
    address,
    city,
    country,
    email,
    index,
    totalGST,
    mobile1,
    mobile2,
  } = props;
  const [loading, setloading] = useState(false);
  let [invoiceDetails, setinvoiceDetails] = useState({});
  // var invoiceDetails;
  const handleGetInvoice = async () => {
    if (printDiv?.current) {
      try {
        setloading(true);
        const response = await get(`/getInvoice?invoiceId=${invoiceId}`);
        if (response?.data?.status === 'failure') {
          setloading(false);
          response?.data?.message && alert.error(response?.data?.message);
        } else {
          setinvoiceDetails(response?.data);
          // handlePrint();
        }
      } catch (error) {
        console.log({ error });
        setloading(false);
        // CB && CB({ error: true, message: error?.response?.data });
      }
    }
  };
  const handlePrint = useReactToPrint({
    content: () => printDiv?.current,
  });

  useEffect(() => {
    if (Object.values(invoiceDetails).length) {
      handlePrint();
      setinvoiceDetails({});
      setloading(false);
    }
    return () => {};
  }, [invoiceDetails]);

  return (
    <>
      <StyledTableRow>
        <StyledTableCell>
          <Link to={`/visa-invoice-edit?invoiceId=${invoiceId}`}>
            {' '}
            <span style={{ color: 'black' }}>{invoiceId}</span>{' '}
          </Link>
        </StyledTableCell>
        <StyledTableCell align='center'>{invoiceDate}</StyledTableCell>
        <StyledTableCell align='center'>{clientName}</StyledTableCell>
        <StyledTableCell align='center'>
          {clientMobileNumber}
          {(mobile1 || mobile2) && ','} <br />
          {mobile1 && mobile1 + ','}
          <br />
          {mobile2}
        </StyledTableCell>
        <StyledTableCell align='center'>{noOfPassports}</StyledTableCell>
        <StyledTableCell align='center'>
          <IconButton onClick={handleGetInvoice} sx={{ width: '40px', height: '30px' }}>
            {loading ? <LoaderOne /> : <FcPrint style={{ fontSize: '20px', color: 'red' }} />}
          </IconButton>
        </StyledTableCell>
        {user?.role === UsersRoles.ROLE_ADMIN && (
          <StyledTableCell align='center'>
            <Link to={`/visa-invoice-edit?invoiceId=${invoiceId}`}>
              <IconButton onClick={() => {}}>
                <FcEditImage style={{ fontSize: '20px' }} />
              </IconButton>
            </Link>
          </StyledTableCell>
        )}
      </StyledTableRow>
      <StyledTableRow sx={{ display: 'none' }}>
        <div ref={printDiv}>
          <VisaInvoicePrint
            invoice={invoiceId}
            invoiceDate={invoiceDetails?.invoiceDate}
            paymentMethod={invoiceDetails?.paymentMethod}
            passportsData={invoiceDetails?.passportInfo}
            totalAmount={invoiceDetails?.totalAmount}
            clientName={invoiceDetails?.personalDetail?.name}
            consultantName={invoiceDetails?.personalDetail?.consultantName}
          />
        </div>
      </StyledTableRow>
    </>
  );
};

export default VisaInvoiceListRow;
