import { Table, TableBody, TableContainer, TableHead } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { TableStyle } from '../../../uiComponents';
import UsersRoles from '../../../utils/Constants/UserRoles.constants';
import MiscInvoiceListRow from './MiscInvoiceListRow';

const { StyledTableRow, StyledTableCellTranparent } = TableStyle;
const MiscInvoiceListTable = () => {
  const reduxState = useSelector(({ misc, users }) => {
    return {
      invoiceData: misc?.miscList,
      loading: misc.listLoading,
      user: users.user,
    };
  });
  let { invoiceData, loading, user } = reduxState;

  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 'max-content', marginBottom: '20px' }} aria-label='simple table'>
          <TableHead sx={{ borderBottom: '2px solid #EBEFF2' }}>
            <StyledTableRow>
              <StyledTableCellTranparent sx={{ width: '50px' }}>INVOICE No.</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '50px' }}>
                Date
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '200px' }}>
                CLIENT NAME
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '80px' }}>
                MOBILE
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '50px' }}>
                PRINT
              </StyledTableCellTranparent>
              {user?.role === UsersRoles.ROLE_ADMIN && (
                <StyledTableCellTranparent align='center' sx={{ width: '50px' }}>
                  EDIT
                </StyledTableCellTranparent>
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              (invoiceData?.data?.length > 0
                ? invoiceData?.data.map(
                    ({ invoiceId, clientName, clientMobile, date, totalAmount, productData }, index) => (
                      <MiscInvoiceListRow
                        key={invoiceId + index}
                        index={index}
                        invoiceId={invoiceId}
                        clientName={clientName}
                        mobile={clientMobile}
                        totalAmount={totalAmount}
                        invoiceDate={date}
                        productData={productData}
                      />
                    )
                  )
                : null)}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MiscInvoiceListTable;
