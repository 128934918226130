import { Button } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CommonHeading from '../../Components/VisaInvoice/CommonHeading';
import '../../Components/VisaInvoice/styles/VisaInvoice.css';
import { CommonGreenHeading, ErrorAlert, LoaderOne } from '../../uiComponents';
import { AllButton } from '../../uiComponents';
import RectSelect from '../../uiComponents/Select/RectSelect';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { ticketInvoiceValidate, ticketValidate } from '../../utils/validator';
import TicketInvoiceTable from '../../Components/Ticket/Invoice/TicketInvoiceTable';
import Ticket_Constants from '../../store/constants/Ticket';
import { editTicket, getTicketDetails, saveTicket } from '../../store/action/Ticket';
import PaymentMethodList from '../../utils/Constants/PaymentMethods.constants';
import moment from 'moment';

const { FullSecondButton } = AllButton;
const DashedButton = styled(Button)(({ theme }) => ({
  color: '#000',
  margin: '0px',
  fontFamily: 'Nunito',
  textTransform: 'capitalize',
  whiteSpace: ' nowrap',
  fontWeight: 700,
  border: '1px dashed black',
  backgroundColor: '#fff !important',
  fontSize: '14px',
  [theme.breakpoints.up('md')]: {
    padding: '2px 40px 2px 35px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '2px 35px 2px 25px',
  },
  '&:hover': {
    backgroundColor: '#fffff',
  },
  '&:active': {
    backgroundColor: '#fffff',
  },
}));

const TicketInvoice = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const invoiceId = searchParams.get('ticketId');

  const reduxState = useSelector(({ tickets }) => {
    return {
      ticketsData: tickets.ticketsData,
      loading: tickets.saveLoading,
      ticketDetails: tickets.ticketDetails,
    };
  });
  let { ticketsData, loading, ticketDetails } = reduxState;
  const initialState = {
    clientName: '',
    mobileNumber: '',
    paxName: '',
    departureDate: '',
    ticketNumber: '',
    comments: '',
    flightNumber: '',
    route: '',
    taxes: null,
    skyTravelFee: null,
    fare: null,
    consultantName:''
  };
  const [values, setValues] = useState(initialState);
  const {
    clientName,
    mobileNumber,
    paxName,
    departureDate,
    ticketNumber,
    comments,
    taxes,
    skyTravelFee,
    fare,
    flightNumber,
    route,
    consultantName
  } = values;
  const [errors, seterrors] = useState({});
  const [ticketError, setTicketError] = useState({});
  const [paxType, setPaxType] = useState({ key: '', label: 'Select Pax Type', value: '' });
  const [totalfee, settotalfee] = useState(0);
  const [gst, setGst] = useState(0);
  const [grandTotal, setgrandTotal] = useState(0);
  const [total, settotal] = useState(0);
  const [balance, setbalance] = useState(0);
  const [amountList, setamountList] = useState([0]);
  const [refrenceIdList, setrefrenceIdList] = useState(['']);
  const [paymentList, setpaymentList] = useState([
    {
      amountPaid: 0,
      paymentType: '',
    },
  ]);
  const [paymentListError, setpaymentListError] = useState({});

  // useEffect(() => {
  //   let total = Number(skyTravelFee || 0) + Number(fare || 0) + Number(taxes || 0);
  //   total = total.toFixed(2);
  //   settotalfee(total);
  //   return () => {};
  // }, [skyTravelFee, fare, taxes]);

  useEffect(() => {
    if (!ticketsData) return;
    if (ticketsData?.length === 0) return;
    var reducedArray = ticketsData.reduce(function (accumulator, item) {
      accumulator = Number(accumulator) + Number(item['totalAmount']);
      return Number(accumulator);
    }, 0);
    setgrandTotal(reducedArray);
    setbalance(reducedArray - total);
    return () => {};
  }, [ticketsData]);

  useEffect(() => {
    if (invoiceId) {
      dispatch(getTicketDetails({ ticketId: invoiceId }));
    }
    return () => {
      dispatch({ type: Ticket_Constants.GET_TICKET_DETAILS, loading: false, data: {} });
      dispatch({
        type: Ticket_Constants.ADD_TICKETS,
        ticketsData: [],
      });
    };
  }, []);
  useEffect(() => {
    if (invoiceId && ticketDetails && Object.values(ticketDetails)?.length !== 0) {
      setValues({
        clientName: ticketDetails?.clientName,
        mobileNumber: ticketDetails?.mobile,
        paxName: '',
        departureDate: '',
        ticketNumber: '',
        comments: ticketDetails?.comments,
        flightNumber: '',
        route: '',
        consultantName: ticketDetails?.consultantName
      });
      dispatch({
        type: Ticket_Constants.ADD_TICKETS,
        ticketsData: ticketDetails?.passengerData,
      });
      let tempList = ticketDetails?.paymentMethod?.map(({ paymentMethod, paidAmount }) => paidAmount);
      let tempPaymentList = ticketDetails?.paymentMethod?.map(({ paymentMethod, paidAmount }) => {
        return { amountPaid: paidAmount, paymentType: paymentMethod };
      });
      const tempRefList = ticketDetails?.paymentMethod?.map(({ referenceNumber }) => referenceNumber);
      setrefrenceIdList(tempRefList);
      setpaymentList(tempPaymentList);
      setamountList(tempList);
      settotal(parseFloat(ticketDetails?.totalAmount).toFixed(2));
    }
    return () => {};
  }, [ticketDetails]);
  const handleChangeTotalFee = (e) => {
    settotalfee(e.target.value);
    if (fare && taxes) {
      const tempTotal = Number(fare) + Number(taxes);
      const skyfee = e.target.value - Number(tempTotal);
      setValues({
        ...values,
        skyTravelFee: skyfee,
      });
    }
  };
  const handleSkyFee = (e) => {
    setValues({
      ...values,
      skyTravelFee: e.target.value,
    });
    let total = Number(e.target.value || 0) + Number(fare || 0) + Number(taxes || 0);
    total = total.toFixed(2);
    settotalfee(total);
  };
  const handleFareFee = (e) => {
    setValues({
      ...values,
      fare: e.target.value,
    });
    let total = Number(skyTravelFee || 0) + Number(e.target.value || 0) + Number(taxes || 0);
    total = total.toFixed(2);
    settotalfee(total);
  };
  const handleTaxesFee = (e) => {
    setValues({
      ...values,
      taxes: e.target.value,
    });
    let total = Number(skyTravelFee || 0) + Number(fare || 0) + Number(e.target.value || 0);
    total = total.toFixed(2);
    settotalfee(total);
  };
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleChangePaymentMethod = (e, index, amountPaid = '') => {
    let newArray = [...paymentList];
    newArray[index] = { amountPaid, paymentType: e.value };
    setpaymentList(newArray);
  };
  const handleChangePayment = (enterAmount, index) => {
    let newArray = [...amountList];
    newArray.splice(parseInt(index), 1, enterAmount);
    var reducedArray = newArray.reduce(function (accumulator, item) {
      accumulator = accumulator + Number(item);
      return accumulator;
    }, 0);
    const newPaymentTotal = reducedArray;
    const newBalance = grandTotal - newPaymentTotal;
    if (newBalance < 0) return;
    setamountList(newArray);
    settotal(reducedArray);
    setbalance(newBalance);
  };
  const handleChangeReferenceId = (refId, index) => {
    let tempRefList = [...refrenceIdList];
    tempRefList.splice(parseInt(index), 1, refId);
    setrefrenceIdList(tempRefList);
  };

  const handleAddPaymentMethod = () => {
    if (paymentList.length === PaymentMethodList.length) return;
    setpaymentList([...paymentList, { amountPaid: 0, paymentType: '' }]);
    setamountList([...amountList, 0]);
    setrefrenceIdList([...refrenceIdList, '']);
  };
  const handleRemovePaymentMethod = (index) => {
    const paymentMethodArr = [...paymentList];
    paymentMethodArr.splice(index, 1);
    let newArray = [...amountList];
    newArray.splice(index, 1);
    setpaymentList(paymentMethodArr);
    setamountList(newArray);
    // refrence id list
    let newRefList = [...refrenceIdList];
    newRefList.splice(index, 1);
    setrefrenceIdList(newRefList);
    var reducedArray = newArray.reduce(function (accumulator, item) {
      accumulator = accumulator + Number(item);
      return accumulator;
    }, 0);
    let tempObject = { ...paymentListError };
    delete tempObject[index];
    setpaymentListError(tempObject);
    settotal(Number(reducedArray));
    setbalance(grandTotal - reducedArray);
  };
  const checkAvailability = async (e) => {
    e.preventDefault();
    let validationValues = {
      ticketNumber,
      paxName,
      paxType: paxType?.value,
      departureDate,
      flightNumber,
      route,
      fare,
      taxes,
      skyTravelFee,
      gst,
      totalfee,
    };
    setTicketError(ticketValidate(validationValues));
    if (Object.values(ticketValidate(validationValues)).length != 0) return;
    addTicketData();
  };

  const addTicketData = (e) => {
    let ticketObject = {
      paxName: paxName.toUpperCase(),
      ticketNumber: ticketNumber.toUpperCase(),
      paxType: paxType.value.toUpperCase(),
      departureDate: moment(departureDate).format('DD-MM-YYYY'),
      flightNumber,
      route,
      fare: Number(fare).toFixed(2),
      taxes: taxes ? Number(taxes).toFixed(2) : 0,
      skyTravelFee: Number(skyTravelFee).toFixed(2),
      gstAmount: gst,
      totalAmount: totalfee,
    };
    let isAlready = false;
    ticketsData.forEach((element) => {
      if (element.ticketNumber == ticketNumber.toUpperCase()) {
        isAlready = true;
      }
    });
    if (isAlready) {
      alert.info('ticket number already added');
    } else {
      dispatch({
        type: Ticket_Constants.ADD_TICKETS,
        ticketsData: [...ticketsData, ticketObject],
      });
      setValues({
        paxName: '',
        ticketNumber: '',
        flightNumber: '',
        route: '',
        departureDate: '',
        fare: '',
        taxes: '',
        skyTravelFee: '',
        clientName,
        mobileNumber,
        comments,
        consultantName
      });
      setGst('');
      setPaxType({ key: '', label: 'Select paxType', value: '' });
    }
  };

  const addInvoiceData = async (e) => {
    e.preventDefault();
    let validationValues = {
      clientName: clientName.toUpperCase(),
      mobile: mobileNumber,
      passengerData: ticketsData,
      consultantName,
      comments,
      paymentMethod: paymentList.map((element, index) => {
        return {
          amountPaid: amountList[index],
          paymentType: element.paymentType,
          referenceNumber: refrenceIdList[index] || null,
        };
      }),
    };
    console.log({ validationValues });
    let isError = false;
    let mainTempError = { ...paymentListError };
    paymentList.forEach((element, index) => {
      let tempObject = { ...mainTempError };
      tempObject[index] = {
        amountPaid: '',
        paymentType: '',
      };
      if (amountList[index] === '' || amountList[index] == 0) {
        tempObject[index].amountPaid = 'Either enter payment or remove it';
        isError = true;
      }
      if (element.paymentType === '') {
        tempObject[index].paymentType = 'Either select method or remove it';
        isError = true;
      }
      if ((amountList[index] !== '' || amountList[index] !== 0) && element.paymentType !== '') {
        delete tempObject[index];
      }
      mainTempError = { ...tempObject };
    });
    setpaymentListError(mainTempError);
    seterrors(ticketInvoiceValidate(validationValues));
    if (balance > 0) return alert.error('Balance Amount should be zero');
    if (Object.values(ticketInvoiceValidate(validationValues)).length != 0 || isError) return;
    if (ticketsData?.length == 0) return alert.error('Please add tickets');
    if (invoiceId) {
      dispatch(editTicket(validationValues, invoiceId, addInvoiceCallback));
    } else {
      dispatch(saveTicket(validationValues, addInvoiceCallback));
    }
  };

  const addInvoiceCallback = ({ error, message, data }) => {
    if (error) {
      message?.error && alert.error(message?.error);
      return;
    }
    if (data?.status == 'failure') {
      data?.message && alert.error(data?.message);
      return;
    }

    if (invoiceId) {
      alert.success('Invoice updated Successfully');
    } else {
      alert.success('Invoice added Successfully');
    }

    dispatch({
      type: Ticket_Constants.ADD_TICKETS,
      ticketsData: [],
    });
    navigate(`/ticket-invoice-print?ticketId=${data.ticketId}`);
  };
  const handleEditTicket = (data) => {
    setValues({
      ...values,
      paxName: data.paxName,
      departureDate: data.departureDate,
      ticketNumber: data.ticketNumber,
      taxes: data.taxes,
      skyTravelFee: data.skyTravelFee,
      fare: data.fare,
      flightNumber: data.flightNumber,
      route: data.route,
    });
    setGst(data.gstAmount);
    settotalfee(data.totalAmount);
    setPaxType({ key: 'kbwda', label: data.paxType, value: data.paxType });
    let copyArray = ticketsData.filter((ticket) => ticket.ticketNumber != data.ticketNumber);
    dispatch({
      type: Ticket_Constants.ADD_TICKETS,
      ticketsData: copyArray,
    });
  };
  return (
    <div className='visa_invoice'>
      {/* client details section start */}
      <section>
        <CommonHeading heading={'Ticket'} tag={'Please add details of your clients'} />
      </section>
      {invoiceId && <section style={{marginTop:"20px"}}>
        <h3>Invoice No: <span>{invoiceId}</span></h3>
      </section>}
      {/* header section end */}
      <div className='visa_invoice_second'>
        {/* client details section start */}
        <section className='visa_invoice_details_div' style={{ marginBottom: '50px' }}>
          <section className='visa_invoice_input_div'>
            <h5>Client Name</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='text'
                placeholder='Client Name'
                value={clientName}
                name={'clientName'}
                onChange={handleChangeInput}
              />
            </div>
            {errors?.clientName && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={errors?.clientName} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Mobile Number</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='number'
                placeholder='Mobile Number'
                value={mobileNumber}
                name={'mobileNumber'}
                onChange={handleChangeInput}
              />
            </div>
            {errors?.mobileNumber && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={errors?.mobileNumber} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Consultant name</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='text'
                placeholder='Consultant name'
                value={consultantName}
                name={'consultantName'}
                onChange={handleChangeInput}
              />
            </div>
            {errors?.consultantName && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={errors?.consultantName} />
              </div>
            )}
          </section>
          <br />
        </section>
        <CommonGreenHeading heading={'Ticket Details'} />
        {/* client details section end */}
        {/* members details section start */}
        <section className='visa_invoice_details_div'>
          <section className='visa_invoice_input_div'>
            <h5>Pax Name</h5>
            <div className='visa_invoice_input_inner'>
              <input type='text' placeholder='Pax Name' value={paxName} name={'paxName'} onChange={handleChangeInput} />
            </div>
            {ticketError?.paxName && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={ticketError?.paxName} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Pax Type </h5>
            <div style={{ marginTop: '5px' }}>
              <RectSelect
                defaultValue={paxType}
                data={[
                  {
                    key: 1 + 'ADULT',
                    value: 'ADULT',
                    label: 'ADULT',
                  },
                  {
                    key: 2 + 'CHILD',
                    value: 'CHILD',
                    label: 'CHILD',
                  },
                  {
                    key: 3 + 'INFANT',
                    value: 'INFANT',
                    label: 'INFANT',
                  },
                ]}
                callback={setPaxType}
              />
            </div>
            {ticketError?.paxType && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={ticketError?.paxType} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Ticket Number</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='text'
                placeholder='Ticket Number'
                value={ticketNumber}
                name={'ticketNumber'}
                onChange={handleChangeInput}
              />
            </div>
            {ticketError?.ticketNumber && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={ticketError?.ticketNumber} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Departure Date</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='date'
                placeholder='Departure Date'
                value={departureDate}
                name={'departureDate'}
                onChange={handleChangeInput}
              />
            </div>
            {ticketError?.departureDate && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={ticketError?.departureDate} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Flight Number</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='text'
                placeholder='Flight Number'
                value={flightNumber}
                name={'flightNumber'}
                onChange={handleChangeInput}
              />
            </div>
            {ticketError?.flightNumber && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={ticketError?.flightNumber} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Route</h5>
            <div className='visa_invoice_input_inner'>
              <input type='text' placeholder='Route' value={route} name={'route'} onChange={handleChangeInput} />
            </div>
            {ticketError?.route && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={ticketError?.route} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Fare (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input type='number' placeholder='Fare' value={fare} name={'fare'} onChange={handleFareFee} />
            </div>
            {ticketError?.fare && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={ticketError?.fare} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Taxes (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='number'
                step={0.01}
                placeholder='Taxes'
                value={taxes}
                name={'taxes'}
                onChange={handleTaxesFee}
              />
            </div>
            {ticketError?.taxes && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={ticketError?.taxes} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Sky Service Fee (S$) </h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='number'
                placeholder='Sky Service Fee'
                value={skyTravelFee}
                name={'skyTravelFee'}
                onChange={handleSkyFee}
              />
            </div>
            {ticketError?.skyTravelFee && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={ticketError?.skyTravelFee} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>GST (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input disabled type='number' placeholder='GST' value={gst} name={'gst'} onChange={handleChangeInput} />
            </div>
            {ticketError?.gst && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={ticketError?.gst} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Total Amount (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='number'
                placeholder='Sky travel GST'
                value={totalfee}
                name={'totalfee'}
                onChange={handleChangeTotalFee}
              />
            </div>
            {ticketError?.totalfee && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={ticketError?.totalfee} />
              </div>
            )}
          </section>
        </section>
        <section style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
          <DashedButton onClick={checkAvailability}> +Add to List </DashedButton>
        </section>
        {/* members details section end */}
      </div>

      {/* passport details section start */}
      <section style={{ marginBottom: '40px' }}>
        <TicketInvoiceTable callback={(data) => handleEditTicket(data)} />
        {errors?.passengerData && (
          <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
            <ErrorAlert error={errors?.passengerData} />
          </div>
        )}
      </section>
      <CommonGreenHeading heading={'Charges'} />
      {/* passport details section end */}
      {/* charges and commonets details section start */}
      <section>
        <section className='visa_invoice_input_div'>
          <h5>Grand Total (S$)</h5>
          <div className='visa_invoice_input_inner'>
            <input
              disabled
              type='number'
              placeholder='Total '
              value={grandTotal}
              name={'grandTotal'}
              onChange={(e) => setgrandTotal(e.target.value)}
            />
          </div>
        </section>
        {paymentList?.map(({ amountPaid, paymentType }, index) => (
          <div key={amountPaid + paymentType + index} className='visa_invoice_details_div'>
            <section className='visa_invoice_input_div' style={{ width: '230px' }}>
              <h5>Payment Method</h5>
              <div style={{ marginTop: '5px' }}>
                <RectSelect
                  defaultValue={{ key: index + amountPaid, label: paymentType || 'Select', value: paymentType }}
                  data={PaymentMethodList}
                  callback={(e) => handleChangePaymentMethod(e, index)}
                />
              </div>
              {paymentListError[index] && paymentListError[index].paymentType && (
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                  <ErrorAlert error={paymentListError[index].paymentType} />
                </div>
              )}
            </section>
            <section className='visa_invoice_input_div' style={{ width: '220px' }}>
              <h5>Paid Amount (S$)</h5>
              <div className='visa_invoice_input_inner'>
                <input
                  type='number'
                  placeholder='Total '
                  value={amountList[index]}
                  onChange={(e) => handleChangePayment(e.target.value, index)}
                />
              </div>
              {paymentListError[index] && paymentListError[index].amountPaid && (
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                  <ErrorAlert error={paymentListError[index].amountPaid} />
                </div>
              )}
            </section>
            {paymentType !== 'CASH' && (
              <section className='visa_invoice_input_div' style={{ width: '220px' }}>
                <h5>Reference Id</h5>
                <div className='visa_invoice_input_inner'>
                  <input
                    type='text'
                    placeholder='Reference ID '
                    value={refrenceIdList[index]}
                    onChange={(e) => handleChangeReferenceId(e.target.value, index)}
                  />
                </div>
                {paymentListError[index] && paymentListError[index]?.refrenceId && (
                  <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                    <ErrorAlert error={paymentListError[index]?.refrenceId} />
                  </div>
                )}
              </section>
            )}
            {paymentList?.length !== 1 && (
              <section className='visa_invoice_input_div' style={{ marginTop: '55px' }}>
                <DashedButton sx={{ py: 50 }} onClick={() => handleRemovePaymentMethod(index)}>
                  - Remove
                </DashedButton>
              </section>
            )}
          </div>
        ))}
        {paymentList?.length !== PaymentMethodList.length && (
          <section className='visa_invoice_input_div' style={{ marginTop: '55px' }}>
            <DashedButton sx={{ py: 50 }} onClick={handleAddPaymentMethod}>
              +Add Payment Option{' '}
            </DashedButton>
          </section>
        )}
        <section style={{ display: 'flex', flexWrap: 'wrap' }}>
          <section className='visa_invoice_input_div'>
            <h5>Total (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                disabled
                type='number'
                placeholder='Total '
                value={total}
                name={'total'}
                onChange={(e) => settotal(e.target.value)}
              />
            </div>
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Balance (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                disabled
                type='number'
                placeholder='Balance '
                value={balance}
                name={'balance'}
                onChange={(e) => setbalance(e.target.value)}
              />
            </div>
          </section>
        </section>
        <section>
          {errors?.totalError && (
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
              <ErrorAlert error={errors?.totalError} />
            </div>
          )}
        </section>
      </section>

      {/* charges and commonets details section end */}
      <section className='visa_invoice_comments'>
        <textarea
          type='text'
          placeholder='Add comments(if any)'
          value={comments}
          name='comments'
          onChange={handleChangeInput}
        />
      </section>
      {Object.values(errors).length != 0 && (
        <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
          <ErrorAlert error={'Please resolve all errors'} />
        </div>
      )}
      <section style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
        <div style={{ minWidth: '280px' }}>
          {loading ? (
            <FullSecondButton>
              <LoaderOne />
            </FullSecondButton>
          ) : (
            <FullSecondButton onClick={addInvoiceData}>Submit </FullSecondButton>
          )}
        </div>
      </section>
    </div>
  );
};

export default TicketInvoice;
