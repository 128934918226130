import { Table, TableBody, TableCell, TableContainer, TableHead } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisaInvoiceConstant from "../../../store/constants/VisaInvoice.constants";
import { TableStyle } from "../../../uiComponents";
import PassportStatus from "../../../utils/Constants/passportStatus.constants";
import PassportDeliveryRow from "./PassportDeliveryRow";
import PassportDe from "./PassportDeliveryRow";

const { StyledTableCell, StyledTableRow, StyledTableCellTranparent, GreyTableRow } = TableStyle;
const delivery_status = {
  DELIVERED: "DELIVERED",
  NOT_DELIVERED: "RETURNED",
};
const PassportDeliveryTable = () => {
  const dispatch = useDispatch();
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      invoiceDetails: visaInvoice.invoiceDetails,
      loading: visaInvoice.detailsLoading,
      deliveryPassportsData: visaInvoice.deliveryPassportsData,
    };
  });
  let { invoiceDetails, loading, deliveryPassportsData } = reduxState;
  let passportList = invoiceDetails?.passportInfo;

  useEffect(() => {
    let tempData = [];
    if (passportList?.length < 0) return;
    passportList?.forEach((element) => {
      tempData.push({
        mode: element?.delivered ? delivery_status.DELIVERED : delivery_status.NOT_DELIVERED,
        passportnumber: element.passportNumber,
      });
    });
    if (tempData?.length > 0) {
      dispatch({
        type: VisaInvoiceConstant.SET_DELIVERY_PASSPORTS,
        deliveryPassportsData: tempData,
      });
    }

    return () => {};
  }, []);

  return (
    <div>
      <TableContainer>
        {console.log({ invoiceDetails })}
        <Table sx={{ minWidth: 650, marginBottom: "20px", minWidth: "max-content" }} aria-label="simple table">
          <TableHead sx={{ borderBottom: "2px solid #EBEFF2" }}>
            <StyledTableRow>
              <StyledTableCellTranparent sx={{ width: "70px" }}>PASssport</StyledTableCellTranparent>
              <StyledTableCellTranparent align="center" sx={{ width: "110px" }}>
                Expiry Date
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align="center" sx={{ width: "220px" }}>
                Name{" "}
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align="center" sx={{ width: "50px" }}>
                SEX
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align="center" sx={{ width: "150px" }}>
                Nationality
              </StyledTableCellTranparent>
              {/* <StyledTableCellTranparent align="center">Pass Type</StyledTableCellTranparent>
              <StyledTableCellTranparent align="center" sx={{ width: "110px" }}>
                Pass Expiry
              </StyledTableCellTranparent> */}
              <StyledTableCellTranparent align="center" sx={{ width: "120px" }}>
                Status
              </StyledTableCellTranparent>
              {/* <StyledTableCellTranparent align="center" sx={{ width: "150px" }}>
                Delivery Status
              </StyledTableCellTranparent> */}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {passportList?.length > 0 &&
              passportList?.map(
                ({
                  name,
                  gender,
                  nationality,
                  passExpiry,
                  passportExpiry,
                  passportNumber,
                  typeOfPass,
                  visaForCountry,
                  status,
                  delivered,
                }) => (
                  <PassportDeliveryRow
                    key={passportNumber + name}
                    passport_no={passportNumber}
                    passport_expiry_date={passportExpiry}
                    name={name}
                    gender={gender}
                    nationality={nationality}
                    type_of_pass={typeOfPass}
                    pass_expiry={passExpiry}
                    visa_for_country={visaForCountry}
                    comments={""}
                    return_status={status}
                    delivered={delivered}
                  />
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PassportDeliveryTable;
