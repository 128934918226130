import React from "react";
import PassportStatus from "../../utils/Constants/passportStatus.constants";

const TrackDataItem = ({ data, date, isCurrent, isCompleted, isRemaining, isLast, comments, status }) => {
  let textColor;
  if (isCompleted) {
    textColor = "#838383";
  } else if (isCurrent) {
    textColor = "#727272";
  } else if (isRemaining) {
    textColor = "#B4B4B4";
  }
  return (
    <div className="trackDataItem" style={{ color: textColor, display: "flex", justifyContent: "start" }}>
      <section
        style={{ marginLeft: "-70px", marginRight: isLast ? "-30px" : "0px", maxWidth: "150px", textAlign: "center" }}
      >
        <h3 style={{ fontWeight: isCurrent ? 700 : 500, fontSize: "20px" }}>{data}</h3>
        <p style={{ fontSize: "14px", fontWeight: 600, marginTop: "5px" }}>{date}</p>
        {status == PassportStatus.REJECTED && isCurrent && (
          <p style={{ fontSize: "14px", fontWeight: 600, marginTop: "20px", color: "red" }}>Rejected</p>
        )}
        {comments && isCurrent && <p style={{ fontSize: "14px", fontWeight: 600, marginTop: "20px" }}>{comments}</p>}
      </section>
    </div>
  );
};

export default TrackDataItem;
