const PaymentMethodList = [
  {
    key: 'CASH',
    value: 'CASH',
    label: 'CASH',
  },
  {
    key: 'CHEQUE',
    value: 'CHEQUE',
    label: 'CHEQUE',
  },
  {
    key: 'NETS',
    value: 'NETS',
    label: 'NETS',
  },
  {
    key: 'PAYNOW',
    value: 'PAYNOW',
    label: 'PAYNOW',
  },
  {
    key: 'CREDIT CARD',
    value: 'CREDIT CARD',
    label: 'CREDIT CARD',
  },
  {
    key: 'CREDIT 1',
    value: 'CREDIT 15 DAYS',
    label: 'CREDIT 15 DAYS',
  },
  {
    key: 'CREDIT 2',
    value: 'CREDIT 30 DAYS',
    label: 'CREDIT 30 DAYS',
  },
  {
    key: 'CREDIT 3',
    value: 'CREDIT 60 DAYS',
    label: 'CREDIT 60 DAYS',
  },
];

export default PaymentMethodList;
