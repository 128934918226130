import { Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableStyle } from '../../../uiComponents';
import TicketInvoiceRow from './TicketInvoiceRow';

const { StyledTableCell, StyledTableRow, GreyTableRow } = TableStyle;
const TicketInvoiceTable = ({ callback }) => {
  const reduxState = useSelector(({ tickets }) => {
    return {
      ticketsData: tickets.ticketsData,
      loading: tickets.saveLoading,
    };
  });
  let { ticketsData, loading } = reduxState;
  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 650, marginBottom: '20px', minWidth: 'max-content' }} aria-label='simple table'>
          <TableHead sx={{ borderBottom: '2px solid #EBEFF2' }}>
            <StyledTableRow>
              <StyledTableCell sx={{ width: '200px' }}>PAX NAME</StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '70px' }}>
                PAX TYPE
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                TICKET NUMBER
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '150px' }}>
                DEPARTURE DATE
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                FLIGHT NAME
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                ROUTE
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                FARE(S$)
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                TAXES(S$)
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                ST. Fee(S$)
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                Total(S$)
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '50px' }}></StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {ticketsData?.length > 0 &&
              ticketsData?.map(
                (
                  {
                    paxName,
                    ticketNumber,
                    paxType,
                    departureDate,
                    flightNumber,
                    route,
                    fare,
                    taxes,
                    skyTravelFee,
                    gstAmount,
                    totalAmount,
                  },
                  index
                ) => (
                  <TicketInvoiceRow
                    key={paxName + ticketNumber + index}
                    paxName={paxName}
                    ticketNumber={ticketNumber}
                    paxType={paxType}
                    departureDate={departureDate}
                    flightNumber={flightNumber}
                    route={route}
                    fare={fare}
                    taxes={taxes}
                    skyTravelFee={skyTravelFee}
                    gstAmount={gstAmount}
                    totalAmount={totalAmount}
                    callback={callback}
                  />
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TicketInvoiceTable;
