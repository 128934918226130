const Statement_Constant = {
  GET_VISA_INVOICE: 'GET_VISA_INVOICE',
  GET_VISA_TOTAL: 'GET_VISA_TOTAL',
  GET_TICKET_INVOICE: 'GET_TICKET_INVOICE',
  GET_TICKET_TOTAL: 'GET_TICKET_TOTAL',
  GET_MISC_INVOICE: 'GET_MISC_INVOICE',
  GET_MISC_TOTAL: 'GET_MISC_TOTAL',
};

export default Statement_Constant;
