import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SIDEBAR_FUNCTION } from "../../store/constants/FrontEnd";
import Hamburger from "../../uiComponents/Hamburger/Hamburger";
import "./styles/Header.css";

const Header = () => {
  let screenWidth = window.innerWidth;
  const dispatch = useDispatch();
  const { frontEnd, users } = useSelector((state) => {
    return { frontEnd: state.FrontEnd, users: state.users };
  });
  let { open } = frontEnd;
  let { user } = users;
  const handleSidebar = () => {
    dispatch({
      type: SIDEBAR_FUNCTION,
      open: !open,
    });
  };
  return (
    <div className="main_header_style">
      <section className="">
        <Hamburger />
      </section>
      <section className="welcome_name">
        <h2>
          Welcome Back, <span>{user?.userName}</span>
        </h2>
      </section>
    </div>
  );
};

export default Header;
