import { Checkbox } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../Components/Auth/styles/Login.css";
import { AllButton, ErrorAlert, LoaderOne } from "../../uiComponents";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/action/Users";
import { signInValidate } from "../../utils/validator";
import { useAlert } from "react-alert";
import { _setDataToCookies } from "../../utils/storage/Functions";
import { TOKEN } from "../../utils/storage/Constant";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const { FullWidthButton } = AllButton;
const Login = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const reduxState = useSelector(({ users }) => {
    return {
      loginLoading: users.loginLoading,
      userLoggedIn: users.userLoggedIn,
      user: users.user,
    };
  });
  const { loginLoading, userLoggedIn } = reduxState;

  const [userName, setuserName] = useState("");
  const [password, setpassword] = useState("");
  const [remember, setremember] = useState(false);
  const [passVisible, setpassVisible] = useState(false);
  const [errors, seterrors] = useState({});

  const validateDetails = (e) => {
    e.preventDefault();
    seterrors(signInValidate({ email: userName, password }));
    if (Object.values(signInValidate({ email: userName, password })) != 0) return;
    handleLogin();
  };
  const handleLogin = () => {
    let payload = {
      username: userName,
      password,
    };
    dispatch(login(payload, loginCallback));
  };
  const loginCallback = ({ error, message, data }) => {
    if (error) {
      message?.error && alert.error(message?.error);
      return;
    }
    if (data?.status == "failure") {
      data?.message && alert.error(data?.message);
      return;
    }
    _setDataToCookies(TOKEN, data.token?.token);
    alert.success("Login Successfully");
    navigate("/", { replace: true });
  };
  return (
    <div className="login_main flex_row">
      {/* login image */}
      <section className="login_left"></section>
      {/* login form */}
      <section className="login_right flex_col">
        <div className="login_header_div text_center">
          <h1 className="login_header">SKY CENTRE</h1>
          <h4 className="login_tag_line">AIR TRAVEL PVT LTD</h4>
        </div>
        <div className="login_form_main_div">
          <h3 className="">Enter Your Login Details</h3>
          <form action="" className="login_form">
            <div className="login_input_main_div">
              <h5>Username</h5>
              <div>
                <input
                  type="text"
                  value={userName}
                  onChange={(e) => setuserName(e.target.value)}
                  placeholder="mail@abc.com"
                />
              </div>
            </div>
            {errors.email && <ErrorAlert error={errors.email} />}
            <div className="login_input_main_div">
              <h5>Password</h5>
              <div className="password_div">
                <input
                  type={passVisible ? "text" : "password"}
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                  placeholder="**************"
                />
                <div onClick={() => setpassVisible(!passVisible)}>{passVisible ? <BsEye /> : <BsEyeSlash />}</div>
              </div>
            </div>
            {errors.password && <ErrorAlert error={errors.password} />}
            <div style={{ marginTop: "30px" }}>
              {loginLoading ? (
                <FullWidthButton>
                  <LoaderOne />
                </FullWidthButton>
              ) : (
                <FullWidthButton type="submit" onClick={validateDetails}>
                  Login
                </FullWidthButton>
              )}
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Login;
