import React, { useEffect } from 'react';
import VisaPrintDateItem from '../../Components/VisaInvoice/Print/VisaPrintDateItem';
import VisaInvoiceTable from '../../Components/VisaInvoice/Print/VisaPrintTable';
import styles from '../../Components/VisaInvoice/styles/VisaInvoicePrint.module.css';
import CurrencyFormatter from '../../uiComponents/CurrencyFormatter/CurrencyFormatter';
import { getInvoiceDetail } from '../../store/action/VisaInvoice';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import VisaPrintTopItem from '../../Components/VisaInvoice/Print/VisaPrintTopItem';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import VisaPrintRow from '../../Components/VisaInvoice/Print/VisaPrintRow';
import { Table, TableBody, TableContainer, TableHead } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fff',
    color: theme.palette.common.black,
    fontSize: 13,
    fontWeight: 700,
    textTransform: 'uppercase',
    fontFamily: 'Nunito',
    padding: '2px 5px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    fontWeight: 500,
    color: '#000',
    fontFamily: 'Nunito',
    textTransform: 'uppercase',
    padding: '10px 5px',
    border: '0px ',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#FFF',
    fontFamily: 'Nunito',
  },
  //   hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    fontFamily: 'Nunito',
  },
  [`&:hover`]: {
    backgroundColor: '#FFF',
    color: '#000 !important',
  },
}));

const VisaInvoicePrint = ({ invoice, invoiceDate, paymentMethod, passportsData, totalAmount, clientName, consultantName }) => {
  const [searchParams] = useSearchParams();

  return (
    <div className={styles.main_print_div}>
      <section style={{ display: 'flex' }}>
        <div className={styles.logo_div}>
          <img
            src={require('../../assets/logo/mainLogo.png')}
            alt='sky centre '
            className=''
            style={{ objectFit: 'contain', width: '150px' }}
          />
        </div>
        <div className='' style={{ textAlign: 'center', width: '100%', marginLeft: '-150px' }}>
          <h2 className={styles.heading}>Sky Centre Air Travel </h2>
          <p> 93A Syed Alwi Road,</p>
          <p>S(207669),</p>
          <p>Phone No. : +65 62911218,</p>
          <p>GST REG NO : 198701479E</p>
        </div>
      </section>
      <hr style={{ padding: '0.5px', background: '#000', marginTop: '15px' }} />
      <h4 style={{ textAlign: 'center', fontSize: '20px', marginTop: '20px', fontWeight: '700' }}>INVOICE</h4>
      <section style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <h6>To: {clientName}</h6>
        </div>
        <div style={{ marginRight: '20px' }}>
          <VisaPrintTopItem heading='INVOICE NO' label={invoice} isMain={true} />
          <VisaPrintTopItem heading='INVOICE DATE' label={invoiceDate} />
          <VisaPrintTopItem heading="CONSULTANT" label={consultantName} />
          <VisaPrintTopItem heading='CREDIT TERMS' label={paymentMethod} />
          {/* <VisaPrintTopItem heading="YOUR REF NO." label="MR RAJIV" />  */}
        </div>
      </section>

      <section style={{ width: '100%', marginTop: '20px' }}>
        <TableContainer>
          <Table sx={{ marginBottom: '20px' }} aria-label='simple table'>
            <TableHead sx={{ borderBottom: '1px solid #000', borderTop: '1px solid #000' }}>
              <StyledTableRow>
                <StyledTableCell sx={{ width: '100px' }} align='left'>
                  Description
                </StyledTableCell>
                <StyledTableCell sx={{ width: '200px' }} align='left'>
                  Name
                </StyledTableCell>
                <StyledTableCell sx={{ width: '150px' }} align='left'>
                  Passport No
                </StyledTableCell>
                <StyledTableCell sx={{ width: '130px' }} align='left'>
                  Nationality
                </StyledTableCell>
                <StyledTableCell align='center' sx={{ width: '50px' }}>
                  Amount(S$)
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {passportsData?.map(({ passportNumber, name, nationality, totalAmount }, index) => (
                <StyledTableRow key={index + passportNumber}>
                  <StyledTableCell align='left'>Malaysia Visa</StyledTableCell>
                  <StyledTableCell align='left'>{name}</StyledTableCell>
                  <StyledTableCell align='left'>{passportNumber}</StyledTableCell>
                  <StyledTableCell align='left'>{nationality}</StyledTableCell>
                  <StyledTableCell align='center'>{Number(totalAmount)?.toFixed(2)}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
      {/* <section>
        <p style={{ textAlign: "center" }}>************** END ***************</p>
      </section> */}
      <hr style={{ padding: '0.5px', background: '#000', marginTop: '40px' }} />
      <section style={{ display: 'flex', padding: '10px 20px', textTransform: 'uppercase' }}>
        <div style={{ marginLeft: 'auto', display: 'flex', fontWeight: '700', fontSize: '15px' }}>
          <p style={{ marginRight: '10px' }}>Total</p>:{' '}
          <p style={{ marginLeft: '20px' }}>{totalAmount ? Number(totalAmount).toFixed(2) : 0}</p>
        </div>
      </section>
      <section style={{ width: 'max-content', marginTop: '20px' }}>
        <h4 style={{ borderTop: '2px solid #000', padding: '10px 30px' }}>Authorised Signature</h4>
      </section>
      <hr style={{ padding: '0.5px', background: '#000', marginTop: '40px' }} />
      <section>
        <div className={styles.terms}>
          <h3 style={{ fontWeight: '500', fontSize: '14px' }}>Terms and conditions.</h3>
          <ul>
            <li>All visa services are non refundable. </li>
            <li>
              Visa applications are subjected to approval by the immigration authority and we do not take guarantee of
              approval.
            </li>
            <li> For collection of Visa, please bring the original hardcopy receipt. </li>
          </ul>
        </div>
      </section>
      <section style={{  marginTop:'100px', textAlign:'center', backgroundColor:'#dce7f2a5', padding:'10px', borderRadius:"5px"}}>
        <h4 style={{textAlign:'center'}}>To track your visa status please visit <a href="http://skycentreairtravel.com" target="_blank">skycentreairtravel.com</a></h4>
      </section>
    </div>
  );
};

export default VisaInvoicePrint;
