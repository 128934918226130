import React from "react";

const PassportStatuIcon = ({ height = "29", width = "31", color = "#809FB8" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.9516 18.759C4.0681 18.7265 4.09435 18.7168 4.1206 18.7135C5.79096 18.4877 6.91657 18.8159 7.91747 20.5269C10.2474 24.5079 15.1995 26.3148 19.6395 25.2424C24.2995 24.1179 27.673 20.3904 28.2079 15.7741C28.8692 10.0788 24.777 4.60618 19.1703 3.68324C13.1271 2.6888 7.5286 6.38706 6.24219 12.2204C6.04529 13.1141 6.09123 13.171 7.03143 13.1726C8.17344 13.1743 9.31546 13.1515 10.4558 13.1824C10.9251 13.1954 11.2008 13.0556 11.4059 12.625C11.8702 11.655 12.4002 10.7142 12.8728 9.74897C13.1665 9.14939 13.5504 8.70417 14.274 8.69929C14.9927 8.69442 15.3816 9.11852 15.6917 9.71973C16.7484 11.7768 17.8395 13.8193 18.9799 15.987C19.3606 15.2736 19.7117 14.6659 20.012 14.0354C20.327 13.3741 20.8308 13.0638 21.5593 13.0703C22.3469 13.0768 23.1361 13.0573 23.9237 13.0768C24.8869 13.1011 25.476 13.6503 25.4694 14.479C25.4628 15.3224 24.8902 15.9171 23.8958 15.7952C22.5405 15.6295 21.7775 16.1088 21.3526 17.3665C21.131 18.0213 20.7126 18.6079 20.4074 19.2384C20.1105 19.8526 19.7511 20.353 18.9652 20.3433C18.2202 20.3335 17.8855 19.8542 17.5852 19.2725C16.6368 17.4282 15.6655 15.597 14.7023 13.7608C14.5792 13.5269 14.448 13.2961 14.2806 12.989C13.8999 13.7121 13.5504 14.3474 13.2305 14.9958C12.9351 15.5937 12.4527 15.8797 11.798 15.8813C8.37362 15.8862 4.94758 15.896 1.52318 15.8765C0.582982 15.8732 0.020179 15.3289 0.000489095 14.5392C-0.0192008 13.7088 0.556729 13.1775 1.52646 13.1125C2.0909 13.0751 2.7702 13.3367 3.1919 12.989C3.61359 12.6413 3.46427 11.9312 3.58733 11.3869C4.97383 5.23826 10.6691 0.768175 17.1356 0.771425C23.5874 0.774675 29.2827 5.25776 30.656 11.4145C32.6907 20.5302 25.4284 28.9569 16.0133 28.1785C10.2917 27.7057 6.32751 24.5875 4.1206 19.3229C4.05005 19.1539 4.01395 18.9703 3.9516 18.7606V18.759Z"
        fill={color}
      />
    </svg>
  );
};

export default PassportStatuIcon;
