import React, { useRef, useState } from 'react';
import './../../Components/VisaInvoice/styles/StatusTrack.css';
import { AllButton } from '../../uiComponents';
import StatusSecondItem from '../../Components/VisaInvoice/StatusSecondItem';
import TrackBar from '../../uiComponents/TrackBar/TrackBar';
import { get } from '../../utils/methods';
import { useAlert } from 'react-alert';
import LoaderTwo from '../../uiComponents/Loader/LoaderTwo';
import PassportStatus from '../../utils/Constants/passportStatus.constants';
const { FullSecondButton } = AllButton;

const StatusTrack = () => {
  const alert = useAlert();
  let trackRef = useRef();
  const [referenceNo, setreferenceNo] = useState('');
  const [percentage, setpercentage] = useState(33);
  const [loading, setloading] = useState(false);
  const [statusData, setstatusData] = useState({});
  const [trackMessage, settrackMessage] = useState([
    {
      message: 'Application Received',
      date: '',
    },
    {
      message: 'Sent To Embassy',
      date: '',
    },
    {
      message: 'Return From Embassy',
      date: '',
    },
    {
      message: 'Delivered',
      date: '',
    },
  ]);

  const handleSearch = async (e) => {
    try {
      e.preventDefault();
      setloading(true);
      const response = await get(`/trackVisa?passportNumber=${referenceNo}`);

      if (response?.data?.status === 'failure') {
        setloading(false);
        alert.error(response?.data?.message);
      } else {
        setloading(false);

        setstatusData(response?.data);
        console.log({status:response?.data});
        switch (response?.data?.status) {
          case 'INVOICED':
            setpercentage(0);
            break;
          case 'PENDING':
              setpercentage(0);
              break;
          case 'DELIVERED':
            setpercentage(100);
            break;
          case PassportStatus.APPROVED:
            setpercentage(66);
            break;
          case PassportStatus.REJECTED:
            setpercentage(66);
            break;
          default:
            setpercentage(33);
            break;
        }
        settrackMessage([
          {
            message: 'Application Received',
            date: response?.data?.invoiceDate,
          },
          {
            message: 'Sent To Embassy',
            date: response?.data?.submittionDate,
          },
          {
            message: 'Return From Embassy',
            date: response?.data?.returnedDate,
          },
          {
            message: 'Delivered',
            date: response?.data?.deliveredDate,
          },
        ]);
        if (trackRef && trackRef.current) {
          trackRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }
    } catch (error) {
      console.log({ error });
      setloading(false);
    }
  };
  return (
    <div className='paddingGlobal status_track_main '>
      <section className='status_track_first'>
        <div className='status_track_inner_main'>
          <h2>
            Track Your <span>Visa Easily </span>{' '}
          </h2>
          <p className='track_tag_line'>Just enter your Passport no or Invoice no & it’s done.</p>
          <div className='status_track_inner'>
            <form action=''>
              <div className='track_enter_details_main'>
                <h5>Passport No.</h5>
                <div>
                  <input
                    type='text'
                    value={referenceNo}
                    onChange={(e) => setreferenceNo(e.target.value)}
                    placeholder='Enter Passport No'
                  />
                </div>
                <section>
                  <FullSecondButton type='submit' onClick={handleSearch}>
                    Track Now
                  </FullSecondButton>
                </section>
                <section className='status_not_find_section'>
                  <h4>Can’t Find Your Order Details?</h4>
                  <p>Please check passport no. in your invoice</p>
                </section>
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* second div custmer details start */}
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '100px' }}>
          <LoaderTwo />
        </div>
      )}
      {Object.values(statusData)?.length != 0 && (
        <section className='status_track_second'>
          <StatusSecondItem value={`Welcome ${statusData?.userName}`} />
          <StatusSecondItem value={'Status / Order Tracking / Tracking'} />
          <StatusSecondItem value={`Ref Id: ${statusData?.referenceNo}`} />
        </section>
      )}

      {/* second div custmer details end */}

      {/* third div  start */}
      <section className='status_track_third '>
        {Object.values(statusData)?.length != 0 && (
          <>
            <h2>Tracking</h2>
            <div className='tracking_main_div'>
              <TrackBar
                parsePercentage={parseInt(percentage)}
                milestone={3}
                trackMessage={trackMessage}
                comments={statusData?.comments}
                status={statusData?.status}
              />
            </div>
            <section className='track_data'>{/* make another compo or add with Track bar */}</section>
          </>
        )}
      </section>
      <section style={{ margin: '100px 0' }} ref={trackRef} />

      {/* third div  end */}
    </div>
  );
};

export default StatusTrack;
