import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { AllButton, CommonModal, TableStyle } from '../../../uiComponents';
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import VisaInvoiceConstant from '../../../store/constants/VisaInvoice.constants';
import Ticket_Constants from '../../../store/constants/Ticket';
import MiscConstants from '../../../store/constants/Misc.constants';
import { FcEditImage } from 'react-icons/fc';
const { StyledTableCell, StyledTableRow } = TableStyle;
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const { BorderSecondButton } = AllButton;

const MiscInvoiceRow = (props) => {
  const { productName, description, rate, quantity, amount, index, callback } = props;
  const dispatch = useDispatch();
  const reduxState = useSelector(({ misc }) => {
    return {
      miscData: misc.miscData,
      loading: misc.saveLoading,
      miscDetails: misc.miscDetails,
    };
  });
  let { miscData, loading } = reduxState;
  const [showModal, setShowModal] = useState(false);

  const handleRemoveRow = (e) => {
    e.preventDefault();
    let copyArray = [...miscData];
    copyArray.splice(index, 1);
    dispatch({
      type: MiscConstants.ADD_MISC,
      miscData: copyArray,
    });
  };

  const updateModal = () => {
    return (
      <CommonModal showModal={showModal} callback={(e) => setShowModal(false)}>
        <div>
          <section>
            <section className='' style={{ fontSize: '20px', padding: '30px' }}>
              <h5>Are you sure you want to delete it ?</h5>
            </section>

            <section style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <BorderSecondButton sx={{ fontSize: '14px', marginRight: '15px' }} onClick={() => setShowModal(false)}>
                  cancel
                </BorderSecondButton>
              </div>
              <div>
                <BorderSecondButton
                  sx={{
                    fontSize: '14px',
                    marginLeft: '15px',
                    background: '#FF3131',
                    borderColor: '#FF3131',
                    color: '#fff',
                  }}
                  onClick={handleRemoveRow}
                >
                  Delete
                </BorderSecondButton>
              </div>
            </section>
          </section>
        </div>
      </CommonModal>
    );
  };

  return (
    <>
      {showModal && updateModal()}
      <StyledTableRow>
        <StyledTableCell
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            callback({
              productName,
              description,
              rate,
              quantity,
              amount,
              index,
            })
          }
        >
          {productName}
        </StyledTableCell>
        <StyledTableCell align='center'>{description}</StyledTableCell>
        <StyledTableCell align='center'>{quantity}</StyledTableCell>
        <StyledTableCell align='center'>{rate}</StyledTableCell>
        <StyledTableCell align='center'>{amount}</StyledTableCell>
        <StyledTableCell align='center'>
          <IconButton
            onClick={() =>
              callback({
                productName,
                description,
                rate,
                quantity,
                amount,
                index,
              })
            }
          >
            <FcEditImage style={{ fontSize: '20px' }} />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align='center'>
          <IconButton onClick={(e) => setShowModal(true)}>
            <IoClose style={{ fontSize: '20px', color: 'red' }} />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default MiscInvoiceRow;
