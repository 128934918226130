import { IconButton, TableCell } from "@mui/material";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { AllButton, CommonModal, TableStyle } from "../../../uiComponents";
import PassportStatus from "../../../utils/Constants/passportStatus.constants";
import { MdDelete } from "react-icons/md";
import { getDeliveryList } from "../../../store/action/VisaInvoice";
import { get } from "../../../utils/methods";
const { StyledTableCell, StyledTableRow, StyledTableCellTranparent, GreyTableRow } = TableStyle;

const { BorderSecondButton } = AllButton;

const DeliveryRow = (props) => {
  const { date, id, invoiceId, passportNumber, status, userId } = props.data;
  const alert = useAlert();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const updateAction = async (e) => {
    try {
      e.preventDefault();
      const response = await get(`/unDeliver?invoiceId=${invoiceId}&passportNumber=${passportNumber}`);
      if (response?.data?.status == "failure") {
        alert.error(response?.data?.message);
      } else {
        alert.success("Deleted Successfully");
        dispatch(getDeliveryList({ pageNo: 1, searchValue: "" }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateModal = () => {
    return (
      <CommonModal showModal={showModal} callback={(e) => setShowModal(false)}>
        <div>
          <section>
            <section className="" style={{ fontSize: "20px", padding: "30px" }}>
              <h5>this action will reset it to return mode </h5>
            </section>

            <section style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <BorderSecondButton sx={{ fontSize: "14px", marginRight: "15px" }} onClick={() => setShowModal(false)}>
                  cancel
                </BorderSecondButton>
              </div>
              <div>
                <BorderSecondButton
                  sx={{
                    fontSize: "14px",
                    marginLeft: "15px",
                    background: "#FF3131",
                    borderColor: "#FF3131",
                    color: "#fff",
                  }}
                  onClick={updateAction}
                >
                  Delete
                </BorderSecondButton>
              </div>
            </section>
          </section>
        </div>
      </CommonModal>
    );
  };

  const returnStatusComponents = () => {
    switch (status) {
      case PassportStatus.APPROVED:
        return (
          <div
            style={{
              color: "#2FB182",
              backgroundColor: "#E7FDF5",
              padding: "5px 12px",
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            Approved
          </div>
        );
      case "DELIVERED":
        return (
          <div
            style={{
              color: "#2FB182",
              backgroundColor: "#E7FDF5",
              padding: "5px 12px",
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            Deliverd
          </div>
        );
      case "NOT_RETURN":
        return (
          <div
            style={{
              color: "#DE486C",
              backgroundColor: "#FFECF0",
              padding: "5px 12px",
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            Not Return
          </div>
        );
      case PassportStatus.REJECTED:
        return (
          <div
            style={{
              color: "#FFAE00",
              backgroundColor: "#FFF7E6",
              padding: "5px 12px",
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            Rejected
          </div>
        );

      default:
        break;
    }
  };
  return (
    <>
      {showModal && updateModal()}
      <StyledTableRow>
        <StyledTableCell align="center">{date}</StyledTableCell>
        <StyledTableCell align="center">{invoiceId}</StyledTableCell>
        <StyledTableCell align="center">{passportNumber}</StyledTableCell>
        <StyledTableCell align="center">{returnStatusComponents()}</StyledTableCell>
        <StyledTableCell align="center">{userId}</StyledTableCell>
        <StyledTableCell align="center">
          <IconButton onClick={() => setShowModal(true)}>
            <MdDelete style={{ fontSize: "20px", color: "red" }} />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default DeliveryRow;
