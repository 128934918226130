import { Table, TableBody, TableContainer, TableHead } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import DetailItem from '../../Components/VisaInvoice/DetailItem';
import { Logo, AllButton } from '../../uiComponents';
import './../../Components/VisaInvoice/styles/VisaInvoiceReview.css';
import { TableStyle } from '../../uiComponents';
import CurrencyFormatter from '../../uiComponents/CurrencyFormatter/CurrencyFormatter';
import { MdModeEditOutline } from 'react-icons/md';
import ReactToPrint from 'react-to-print';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceDetail } from '../../store/action/VisaInvoice';
import VisaInvoicePrint from './VisaInvoicePrint';
import VisaInvoiceConstant from '../../store/constants/VisaInvoice.constants';
const { StyledTableCell, StyledTableRow } = TableStyle;
const { MainButton, BorderButton } = AllButton;
const VisaInvoiceReview = () => {
  let printDiv = useRef();
  const [searchParams] = useSearchParams();
  const invoiceid = searchParams.get('invoiceId');
  const dispatch = useDispatch();
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      invoiceDetails: visaInvoice.invoiceDetails,
      loading: visaInvoice.detailsLoading,
    };
  });
  let { invoiceDetails, loading } = reduxState;
  let personalDetails = invoiceDetails?.personalDetail;
  let passportList = invoiceDetails?.passportInfo;

  useEffect(() => {
    dispatch(getInvoiceDetail({ invoiceId: invoiceid }));

    return () => {
      dispatch({
        type: VisaInvoiceConstant.SET_PASSPORT_DATA,
        passportDetails: [],
      });
    };
  }, []);

  return (
    <div className=' visa_invoice_review_main_div'>
      {/* print div start */}
      <div className='paddingGlobal'>
        <section>
          <div className=''>
            <Logo />
          </div>
        </section>
        <section className='flex_row visa_invoice_review_second'>
          {/* persion details div */}
          <div className='visa_review_second_inner'>
            <h5 className='details_div_heading'>Personal Details</h5>
            <div className='details_main_div'>
              <DetailItem label={'Name:'} value={personalDetails?.name} />
              {/* <DetailItem label={'Address:'} value={personalDetails?.address} />{' '}
              <DetailItem label={'City:'} value={personalDetails?.city} />
              <DetailItem label={'Country:'} value={personalDetails?.country} />{' '} */}
            </div>
          </div>
          {/* invoice details div */}
          <div className='visa_review_second_inner'>
            <h5 className='details_div_heading'>Invoice</h5>
            <div className='details_main_div'>
              <DetailItem label={'Invoice No.'} value={invoiceDetails?.invoiceId} />
              <DetailItem label={'Date'} value={invoiceDetails?.invoiceDate} />
            </div>
            <div className='details_main_div' style={{ marginTop: '15px' }}>
              <DetailItem label={'Mobile No:'} value={personalDetails?.mobile} />
              {/* <DetailItem label={'Email Id:'} value={personalDetails?.email} /> */}
            </div>
          </div>
        </section>
        {/* passport information section */}
        <section className='visa_invoice_review_table'>
          <h3>Passport Information</h3>
          <div className='passport_info_table'>
            <TableContainer sx={{ marginBottom: '20px', border: '0.5px solid #DBDBDB', borderRadius: '3px' }}>
              <Table sx={{ minWidth: 'max-content', marginBottom: '20px' }} aria-label='simple table'>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Passport</StyledTableCell>
                    {/* <StyledTableCell align="center" sx={{ width: "80px" }}>
                      Expiry Date
                    </StyledTableCell> */}
                    <StyledTableCell align='center' sx={{ width: '200px' }}>
                      Name
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">Gender</StyledTableCell>   */}
                    <StyledTableCell align='center' sx={{ width: '150px' }}>
                      Nationality
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">Pass Type</StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: "80px" }}>
                      Pass Expiry
                    </StyledTableCell> */}
                    <StyledTableCell align='center' sx={{ width: '120px' }}>
                      Visa Fee(S$)
                    </StyledTableCell>
                    <StyledTableCell align='center' sx={{ width: '120px' }}>
                      Agent(S$)
                    </StyledTableCell>
                    <StyledTableCell align='center' sx={{ width: '120px' }}>
                      Agt Fee GST(S$)
                    </StyledTableCell>
                    <StyledTableCell align='center' sx={{ width: '120px' }}>
                      ST Fee(S$)
                    </StyledTableCell>
                    <StyledTableCell align='center' sx={{ width: '120px' }}>
                      ST Fee GST(S$)
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {passportList?.length > 0 &&
                    passportList?.map(
                      ({
                        name,
                        gender,
                        nationality,
                        passExpiry,
                        passportExpiry,
                        passportNumber,
                        typeOfPass,
                        visaFee,
                        agentFee,
                        agentFeeGST,
                        skyServicefee,
                        skyServicefeeGST,
                      }) => (
                        <StyledTableRow key={passportNumber + name}>
                          <StyledTableCell>{passportNumber}</StyledTableCell>
                          {/* <StyledTableCell align="center">{passportExpiry}</StyledTableCell> */}
                          <StyledTableCell align='center'>{name}</StyledTableCell>
                          {/* <StyledTableCell align="center">{gender}</StyledTableCell> */}
                          <StyledTableCell align='center'>{nationality}</StyledTableCell>
                          {/* <StyledTableCell align="center">{typeOfPass}</StyledTableCell>
                          <StyledTableCell align="center">{passExpiry}</StyledTableCell> */}
                          <StyledTableCell align='center'>{visaFee}</StyledTableCell>
                          <StyledTableCell align='center'>{agentFee}</StyledTableCell>
                          <StyledTableCell align='center'>{agentFeeGST}</StyledTableCell>
                          <StyledTableCell align='center'>{skyServicefee}</StyledTableCell>
                          <StyledTableCell align='center'>{skyServicefeeGST}</StyledTableCell>
                        </StyledTableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </section>
        {/* comment and total price seciton */}
        <section className='align_center_col visa_invoice_review_third' style={{ justifyContent: 'end' }}>
          <div className='visa_payment_summary_div_main'>
            <h4>Payment Summary</h4>
            <div className='  visa_review_total '>
              <div className='flex_row justify_between'>
                <p>Total Charges</p>
                <p className='flex_row '>
                  <span style={{ marginRight: '5px' }}>S$</span> (
                  <CurrencyFormatter price={invoiceDetails?.totalAmount} />)
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* print div end */}
      {/* Button div */}
      <section className='paddingGlobal align_center visa_invoice_button_div'>
        <div style={{ marginRight: '20px' }}>
          {/* <Link to={`/visa-invoice-print?invoiceId=${invoiceid}`}> */}
          <ReactToPrint trigger={() => <MainButton>Print</MainButton>} content={() => printDiv} />
          {/* <MainButton>Print Preview</MainButton> */}
          {/* </Link> */}
        </div>
        <section style={{ display: 'none' }}>
          <div ref={(el) => (printDiv = el)}>
            <VisaInvoicePrint
              invoice={invoiceid}
              invoiceDate={invoiceDetails?.invoiceDate}
              paymentMethod={invoiceDetails?.paymentMethod}
              passportsData={invoiceDetails?.passportInfo}
              totalAmount={invoiceDetails?.totalAmount}
              clientName={invoiceDetails?.personalDetail?.name}
              consultantName={invoiceDetails?.personalDetail?.consultantName}
            />
          </div>
        </section>

        <div>
          <Link to={`/visa-invoice-edit?invoiceId=${invoiceid}`}>
            <BorderButton>
              <MdModeEditOutline style={{ marginRight: '5px', fontSize: '20px' }} /> Edit
            </BorderButton>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default VisaInvoiceReview;
