import { Pagination, PaginationItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CommonHeading from '../../Components/VisaInvoice/CommonHeading';
import '../../Components/VisaInvoice/styles/VisaInvoice.css';
import { AllButton } from '../../uiComponents';
import TicketInvoiceListTable from '../../Components/Ticket/List/TicketInvoiceListTable';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getTicketList } from '../../store/action/Ticket';
import DeliveryTable from '../../Components/Passport/DeliveryList/DeliveryTable';
import { getDeliveryList } from '../../store/action/VisaInvoice';
import PassportSearch from '../../Components/Passport/PassportSearch';
const { FullSecondButton } = AllButton;

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: '#528DC8 !important',
    color: '#fff !important',
  },
}));

const DeliveryList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      loading: visaInvoice.deliveryListLoading,
      deliveryList: visaInvoice.deliveryList,
    };
  });
  let { deliveryList, loading } = reduxState;
  const [page, setpage] = useState(1);
  const [search, setsearch] = useState('');
  useEffect(() => {
    dispatch(getDeliveryList({ pageNo: page, search: search.toUpperCase() }));
  }, []);
  useEffect(() => {
    if (page) {
      dispatch(getDeliveryList({ pageNo: page, search: search.toUpperCase() }));
    }
  }, [page]);
  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getDeliveryList({ pageNo: page, search: search.toUpperCase() }));
  };

  return (
    <div className='visa_invoice'>
      {/* client details section start */}
      <section className='visa_invoice_list_header_div'>
        <CommonHeading heading={'Delivery List'} tag={''} />
        <div style={{ minWidth: '280px' }}>
          <Link to={`/passport-delivery`}>
            <FullSecondButton>Add new </FullSecondButton>
          </Link>
        </div>
      </section>
      <section className=' passport_return_second' style={{ display: 'flex' }}>
        <section>
          <form action='' onSubmit={handleSearch}>
            <PassportSearch
              value={search}
              callback={(e) => setsearch(e.target.value)}
              placeholder='Search by Invoice No, Passport No, Name'
            />
          </form>
        </section>
        <div className='' style={{ marginLeft: '50px' }}>
          <FullSecondButton sx={{ fontSize: '14px' }} onClick={handleSearch}>
            Show
          </FullSecondButton>
        </div>
      </section>
      {/* passport details section start */}
      <section style={{ margin: '40px 0' }}>
        <DeliveryTable />
      </section>
      <section className='pagination_passport'>
        <Pagination
          count={deliveryList?.totalPages}
          shape='rounded'
          variant='outlined'
          color='primary'
          page={page}
          onChange={(e, v) => setpage(v)}
          renderItem={(item) => <PaginationItem {...item} classes={{ selected: classes.selected }} />}
        />
      </section>
      <section style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}></section>
    </div>
  );
};

export default DeliveryList;
