import { Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { TableStyle } from '../../../uiComponents';
import LoaderTwo from '../../../uiComponents/Loader/LoaderTwo';
import TicketStatementRow from './TicketStatementRow';

const { StyledTableCell, StyledTableRow, StyledTableCellTranparent, GreyTableRow } = TableStyle;
const TicketStamentsTable = () => {
  const reduxState = useSelector(({ statements }) => {
    return {
      ticketData: statements.ticketData,
      loading: statements.ticketLoading,
    };
  });
  let { ticketData, loading } = reduxState;

  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 'max-content', marginBottom: '20px' }} aria-label='simple table'>
          <TableHead sx={{ borderBottom: '2px solid #EBEFF2' }}>
            <StyledTableRow>
              <StyledTableCellTranparent>Invoice No.</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Invoice Date</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '200px' }}>
                Client Name
              </StyledTableCellTranparent>
              {/* <StyledTableCellTranparent align='center'>Destination</StyledTableCellTranparent> */}
              <StyledTableCellTranparent align='center'>Fare (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Taxes (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>ST FEE (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Total (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Cash (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Cheque (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Nets (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Paynow (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Credit Card (S$)</StyledTableCellTranparent>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              (ticketData?.tickets?.length > 0
                ? ticketData?.tickets.map((data, index) => (
                    <TicketStatementRow key={data?.invoiceId + index} invoiceId={data?.invoiceId} data={data} />
                  ))
                : null)}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TicketStamentsTable;
