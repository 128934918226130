import { Checkbox, IconButton, TableCell } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TableStyle } from '../../../uiComponents';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import VisaInvoiceConstant from '../../../store/constants/VisaInvoice.constants';
const { StyledTableCell, StyledTableRow, StyledTableCellTranparent, GreyTableRow } = TableStyle;
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const PassportSubmissionRow = (props) => {
  const [checked, setchecked] = useState(false);
  const [passports, setpassports] = useState([]);
  const dispatch = useDispatch();
  const { id, gender, name, nationality, number, comments, date, mode, status, userId } = props.data;
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      submissionPassports: visaInvoice.submissionPassports,
      submissionPassportList: visaInvoice.submissionPassportList,
    };
  });
  let { submissionPassports, submissionPassportList } = reduxState;

  const addPassports = () => {
    if (submissionPassports.indexOf(number) === -1 && submissionPassportList.indexOf(props.data) === -1) {
      dispatch({
        type: VisaInvoiceConstant.ADD_PASSPORT_SUBMISSION,
        submissionPassports: [...submissionPassports, number],
        submissionPassportList: [...submissionPassportList, props.data],
      });
      setchecked(true);
    } else {
      dispatch({
        type: VisaInvoiceConstant.ADD_PASSPORT_SUBMISSION,
        submissionPassports: submissionPassports?.filter((d) => d != number),
        submissionPassportList: submissionPassportList?.filter((d) => d != props.data),
      });
      setchecked(false);
    }
  };
  useEffect(() => {
    if (submissionPassports.indexOf(number) === -1) {
      setchecked(false);
    } else {
      setchecked(true);
    }
  }, [submissionPassports]);

  return (
    <>
      <StyledTableRow>
        <StyledTableCell>{number}</StyledTableCell>
        <StyledTableCell align='center'>{name}</StyledTableCell>
        <StyledTableCell align='center'>{nationality}</StyledTableCell>
        <StyledTableCell align='center'>
          <Checkbox {...label} checked={checked} onChange={addPassports} />
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default PassportSubmissionRow;
