import moment from 'moment';
import * as XLSX from 'xlsx-js-style';
// import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

const AgentExcelFile = async (props) => {
  // extracting props
  let { data, pageNo } = props;
  var currentDate = moment(new Date(Date.now())).format('DD-MM-YYYY');
  const secondHeaderStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
    font: { bold: true, sz: '12' },
  };
  const bodyStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
  };
  const nameStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'left' },
  };
  const lastLineStyle = {
    border: { top: { style: 'thin' } },
  };
  let secondheader = {
    A: { v: 'S.NO.', s: secondHeaderStyle },
    B: { v: 'NAME', s: secondHeaderStyle },
    C: { v: 'PASSPORT NO', s: secondHeaderStyle },
    D: { v: 'NATIONALITY', s: secondHeaderStyle },
  };
  const wb = XLSX.utils.book_new();
  const breakLength = 10;
  const tempstudentarray = [...data];
  for (let i = 0; i < Math.ceil(data?.length / breakLength); i++) {
    let table1 = [];
    let j = 0;
    table1.push(secondheader);
    const tableData = tempstudentarray.splice(j, (j += breakLength));
    for (let i in tableData) {
      table1.push({
        A: { v: parseInt(i) + 1, s: bodyStyle },
        B: { v: tableData[i].name, s: nameStyle },
        C: { v: tableData[i].number, s: bodyStyle },
        D: { v: tableData[i].nationality, s: bodyStyle },
      });
    }
    table1.push({
      A: { v: '', s: lastLineStyle },
      B: { v: '', s: lastLineStyle },
      C: { v: '', s: lastLineStyle },
      D: { v: '', s: lastLineStyle },
    });
    //create a worksheet
    let sheet = XLSX.utils.json_to_sheet(table1, { skipHeader: true });
    // setting width
    var wscols = [{ wch: 5 }, { wch: 30 }, { wch: 20 }, { wch: 25 }];
    sheet['!cols'] = wscols;
    XLSX.utils.book_append_sheet(wb, sheet, `Submission_List_Agent_${i + 1}`);
  }
  // const workBookBlob = workbook2blob(wb);
  return XLSX.writeFile(wb, `SUBMISSION-LIST_AGENT_${pageNo}_${currentDate}.xlsx`);
};

export default AgentExcelFile;

const s2ab = (s) => {
  // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
  // create an ArrayBuffer with a size in bytes
  const buf = new ArrayBuffer(s.length);

  //create a 8 bit integer array
  const view = new Uint8Array(buf);

  //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i);
  }

  return buf;
};
