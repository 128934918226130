import { ModalUnstyled } from "@mui/base";
import { Button } from "@mui/material";
import { styled } from "@mui/system";

export const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  overflow: scroll;
  border: 0;
  font-family: "Nunito Sans",
  & :focus {
    border: 0;
  }
`;

export const Backdrop = styled("div")`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100vw;
  heght: 100vh;
  background-color: rgba(, 0, 0);
  -webkit-tap-highlight-color: transparent;
`;
export const CloseButton = styled(Button)({
  color: "#FF0000 !important",
  fontSize: "20px",
  padding: "5px !important",
  // backgroundColor: "#FF0000 !important",
});
