import { get, post } from '../../utils/methods';
import Statement_Constant from '../constants/Statements';

export const getTicketStatement =
  ({ payload }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: Statement_Constant.GET_TICKET_INVOICE, loading: true });
      const response = await post(`/getTickets`, payload);
      if (response?.data?.status === 'failure') {
        dispatch({ type: Statement_Constant.GET_TICKET_INVOICE, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: Statement_Constant.GET_TICKET_INVOICE, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      dispatch({ type: Statement_Constant.GET_TICKET_INVOICE, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const getTicketStatementTotal =
  ({ from, to }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: Statement_Constant.GET_TICKET_TOTAL, loading: true });
      const response = await get(`/ticketTotal{from}{to}?from=${from}&to=${to}`);
      if (response?.data?.status === 'failure') {
        dispatch({ type: Statement_Constant.GET_TICKET_TOTAL, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: Statement_Constant.GET_TICKET_TOTAL, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      console.log(error.response);
      dispatch({ type: Statement_Constant.GET_TICKET_TOTAL, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const getVisaStatement =
  ({ payload }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: Statement_Constant.GET_VISA_INVOICE, loading: true });
      const response = await post(`/getInvoicesByDate`, payload);
      if (response?.data?.status === 'failure') {
        dispatch({ type: Statement_Constant.GET_VISA_INVOICE, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: Statement_Constant.GET_VISA_INVOICE, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      console.log(error?.response);
      dispatch({ type: Statement_Constant.GET_VISA_INVOICE, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const getVisaStatementTotal =
  ({ from, to }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: Statement_Constant.GET_VISA_TOTAL, loading: true });
      const response = await get(`/invoiceTotal{from}{to}?from=${from}&to=${to}`);
      if (response?.data?.status === 'failure') {
        dispatch({ type: Statement_Constant.GET_VISA_TOTAL, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: Statement_Constant.GET_VISA_TOTAL, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      console.log(error.response);
      dispatch({ type: Statement_Constant.GET_VISA_TOTAL, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const getMiscStatement =
  ({ payload }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: Statement_Constant.GET_MISC_INVOICE, loading: true });
      const response = await post(`/miscInvoice/getMiscInvoices`, payload);
      if (response?.data?.status === 'failure') {
        dispatch({ type: Statement_Constant.GET_MISC_INVOICE, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: Statement_Constant.GET_MISC_INVOICE, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      dispatch({ type: Statement_Constant.GET_MISC_INVOICE, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const getMiscStatementTotal =
  ({ from, to }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: Statement_Constant.GET_MISC_TOTAL, loading: true });
      const response = await get(`/miscInvoice/miscInvoiceTotal{from}{to}?from=${from}&to=${to}`);
      if (response?.data?.status === 'failure') {
        dispatch({ type: Statement_Constant.GET_MISC_TOTAL, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: Statement_Constant.GET_MISC_TOTAL, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      console.log(error.response);
      dispatch({ type: Statement_Constant.GET_MISC_TOTAL, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };
