import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AllButton } from '../uiComponents';
import '../Components/Dashboard/styles/styles.css';
import InvoiceIcon from '../Components/Dashboard/Icons/InvoiceIcon';
import DashboardSummaryItem from '../Components/Dashboard/DashboardSummaryItem';
import TicketIcon from '../Components/Dashboard/Icons/TicketIcon';
import DeliveredIcon from '../Components/Dashboard/Icons/DeliveredIcon';
import SubmissionIcon from '../Components/Dashboard/Icons/SubmissionIcon';
import ReturnIcon from '../Components/Dashboard/Icons/ReturnIcon';
import DollarIcon from '../Components/Dashboard/Icons/DollarIcon';
import CurrencyFormatter from '../uiComponents/CurrencyFormatter/CurrencyFormatter';
import CollectionItem from '../Components/Dashboard/CollectionItem';
import VisaChart from '../Components/Dashboard/VisaChart';
import moment from 'moment';
import { get } from '../utils/methods';
import { useAlert } from 'react-alert';

const { MainButton } = AllButton;
const Dashboard = () => {
  const alert = useAlert();
  const [dashboardData, setdashboardData] = useState({});
  const [loading, setloading] = useState(false);

  useEffect(() => {
    getDashboardData(dashboardCallback);
  }, []);

  const getDashboardData = async (CB) => {
    try {
      const response = await get(`/dashboard`);
      if (response?.data?.status === 'failure') {
        CB && CB({ error: false, data: response?.data });
      } else {
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      CB && CB({ error: true, message: error?.response?.data });
    }
  };
  const dashboardCallback = ({ error, message, data }) => {
    if (error) {
      message?.error && alert.error(message?.error);
      return;
    }
    if (data?.status == 'failure') {
      data?.message && alert.error(<div style={{ textTransform: 'lowercase' }}>{data?.message}</div>);
      return;
    }
    setdashboardData(data);
  };

  return (
    <div>
      <div className='dashboard_main_div'>
        {/* date seciotn  */}
        <section
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', fontSize: '14px', fontWeight: '400' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h5 style={{ marginLeft: '5px' }}>
              {new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(new Date(Date.now()))},{' '}
              {moment(new Date(Date.now())).format('Do MMMM YYYY')}{' '}
            </h5>
          </div>
        </section>
        {/* summary section  */}
        <section>
          <h3 style={{ fontSize: '20px', fontWeight: '700' }}>Today's Summary</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <section className='dashborad_summary_item'>
              <DashboardSummaryItem
                Icon={InvoiceIcon}
                label={'Visa Invoices'}
                value={dashboardData?.visaInvoices}
                backColor={'#FE6BBA33'}
              />
            </section>
            <section className='dashborad_summary_item'>
              <DashboardSummaryItem
                Icon={TicketIcon}
                label={'Ticket Invoices'}
                value={dashboardData?.ticketInvoices}
                backColor='#D8D7F8'
              />
            </section>
            <section className='dashborad_summary_item'>
              <DashboardSummaryItem
                Icon={DeliveredIcon}
                label={'Delivered'}
                value={dashboardData?.delivered}
                backColor='#CCE9FF'
              />
            </section>
            <section className='dashborad_summary_item'>
              <DashboardSummaryItem
                Icon={SubmissionIcon}
                label={'Submission'}
                value={dashboardData?.submissions}
                backColor='#FFE6C1'
              />
            </section>
            <section className='dashborad_summary_item'>
              <DashboardSummaryItem
                Icon={ReturnIcon}
                label={'Return'}
                value={dashboardData?.returns}
                backColor='#FCD1C9'
              />
            </section>
          </div>
        </section>
        {/* financials section */}
        <section style={{ marginTop: '34px' }}>
          <h3 style={{ fontSize: '20px', fontWeight: '700' }}>Today's Financials</h3>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <section className='dashborad_financial_item'>
              <CollectionItem
                Icon={DollarIcon}
                label={'Visa Collection'}
                value={dashboardData?.visaCollections}
                backColor={'#E3FBED'}
              />
            </section>
            <section className='dashborad_financial_item'>
              <CollectionItem
                Icon={DollarIcon}
                label={'Ticket Collection'}
                value={dashboardData?.ticketCollections}
                backColor='#E3FBED'
              />
            </section>
            <section className='dashborad_financial_item'>
              <CollectionItem
                Icon={DollarIcon}
                label={'Miscellaneous Collection'}
                value={dashboardData?.miscInvoiceTotal}
                backColor='#E3FBED'
              />
            </section>
          </div>
        </section>
      </div>
      {/* chart section  */}
      <section className='dashboard_chart_section'>
        <div className='chart_main_div'>
          <h5>Overall Visa Invoicing Date wise Graph</h5>
          <section className='visa_chart'>
            {dashboardData?.visaMonthlyData && <VisaChart data={dashboardData?.visaMonthlyData} />}
          </section>
        </div>
        <div className='chart_main_div'>
          <h5>Overall Ticket Invoicing Date wise Graph</h5>
          {dashboardData?.ticketMonthlyData && (
            <section className='visa_chart'>
              <VisaChart data={dashboardData?.ticketMonthlyData} />
            </section>
          )}
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
