const Users = {
  CHECK_AUTH_API: "CHECK_AUTH_API",
  LOGOUT_USER_API: "LOGOUT_USER_API",
  LOGIN_USER_API: "LOGIN_USER_API",
  SIGN_UP_USER_API: "SIGN_UP_USER_API",
  SET_LOGGED_IN_USER: "SET_LOGGED_IN_USER",
  GET_USER_LIST: "GET_USER_LIST",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CREATE_USER: "CREATE_USER",
};

export default Users;
