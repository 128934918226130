import React from "react";

const SubmissionIcon = ({ width = "24", height = "22", color = "#F3A52A" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_331_179)">
        <path
          d="M1.65039 11.0036C1.65039 7.56774 1.65039 4.13189 1.65039 0.694838C1.65039 0.141364 1.7796 0.00479198 2.30216 0.00479198C5.83544 0.00479198 9.36873 0.00598998 12.902 0C13.1604 0 13.3514 0.0814637 13.5332 0.271945C14.9694 1.78382 16.4113 3.2909 17.8578 4.79198C18.0396 4.98127 18.123 5.18133 18.1173 5.45208C18.1036 6.12895 18.1139 6.80701 18.1127 7.48388C18.1127 7.90917 17.9481 8.13559 17.6485 8.1308C17.3501 8.12601 17.1991 7.90438 17.198 7.46951C17.198 6.87171 17.1934 6.27391 17.2014 5.6773C17.2037 5.52636 17.1625 5.41973 17.0596 5.31311C15.7161 3.91505 14.3759 2.5134 13.0392 1.10934C12.9375 1.00272 12.838 0.957199 12.6916 0.957199C9.39617 0.961991 6.10073 0.963189 2.80528 0.954803C2.59946 0.954803 2.56058 1.0159 2.56173 1.21836C2.56744 7.74145 2.56744 14.2645 2.56173 20.7888C2.56173 20.9997 2.61089 21.0476 2.811 21.0476C7.52547 21.0416 12.2399 21.0416 16.9544 21.0476C17.1522 21.0476 17.2151 21.0033 17.2048 20.79C17.1865 20.4127 17.1957 20.0329 17.2014 19.6555C17.206 19.3333 17.3969 19.126 17.6679 19.1332C17.9264 19.1392 18.1093 19.3416 18.1127 19.6483C18.1185 20.2557 18.1185 20.8631 18.1127 21.4705C18.1093 21.8179 17.9321 21.9904 17.5936 22C17.5364 22.0012 17.4793 22 17.4221 22C12.3932 22 7.36539 22 2.33646 22C1.77388 22 1.65382 21.873 1.65382 21.2812C1.65039 17.8549 1.65039 14.4287 1.65039 11.0036Z"
          fill={color}
        />
        <path
          d="M12.4486 19.5992C12.1079 19.6088 11.8952 19.2961 11.9924 18.9319C12.2645 17.9088 12.5458 16.8882 12.8248 15.8663C12.9026 15.5799 13.0089 15.2984 13.0558 15.0073C13.1587 14.3676 13.4949 13.9039 13.9294 13.4559C16.0791 11.2396 18.2048 8.99935 20.3396 6.76748C20.7432 6.34579 20.901 6.34578 21.307 6.76988C22.2766 7.78338 23.2463 8.79689 24.2159 9.81159C24.6161 10.2309 24.6184 10.419 24.2262 10.8287C21.9576 13.2019 19.6878 15.5728 17.4215 17.9496C17.2546 18.1245 17.0693 18.2347 16.8406 18.3006C15.4708 18.7019 14.1032 19.1152 12.7345 19.5225C12.6338 19.5525 12.5321 19.5765 12.4509 19.598L12.4486 19.5992ZM16.9458 17.0679C17.0099 17.0056 17.067 16.954 17.1196 16.8977C19.1596 14.7629 21.1995 12.6269 23.2428 10.4968C23.3709 10.3639 23.3892 10.2932 23.2497 10.1506C22.4882 9.36953 21.7346 8.57885 20.9868 7.78458C20.8599 7.6504 20.7901 7.63483 20.6552 7.77979C20.0034 8.47822 19.3379 9.16347 18.6782 9.85352C17.29 11.3043 15.903 12.7551 14.5137 14.2034C14.4108 14.3101 14.3365 14.3748 14.4851 14.5257C15.2684 15.326 16.0391 16.1406 16.8155 16.9492C16.8543 16.99 16.8978 17.0247 16.9458 17.0679ZM16.0379 17.5291C15.3255 16.7839 14.6349 16.0615 13.9271 15.3224C13.6492 16.3395 13.3702 17.359 13.0821 18.4132C14.094 18.1101 15.0568 17.8226 16.0379 17.5291Z"
          fill={color}
        />
        <path
          d="M9.07046 13.2343C9.35861 12.9204 9.62961 12.6149 9.91204 12.319C11.115 11.0563 12.3213 9.7972 13.5265 8.53811C13.5997 8.46143 13.674 8.37757 13.7621 8.32486C13.9599 8.20626 14.152 8.2422 14.3075 8.41232C14.4641 8.58243 14.4859 8.7837 14.367 8.98616C14.3189 9.07002 14.2457 9.1395 14.1794 9.20898C12.6323 10.8287 11.0841 12.4484 9.53585 14.0681C9.14707 14.4754 8.97555 14.473 8.58563 14.0657C7.57482 13.0102 6.56514 11.9548 5.55433 10.9006C5.43083 10.7712 5.30963 10.6394 5.32106 10.4429C5.3325 10.2512 5.42283 10.1015 5.59664 10.0236C5.79445 9.93497 5.9694 9.98529 6.11805 10.1398C6.47595 10.5112 6.83271 10.885 7.18947 11.2588C7.80694 11.9069 8.42441 12.5562 9.07046 13.2355V13.2343Z"
          fill={color}
        />
        <path
          d="M16.0379 17.5303C15.0568 17.8238 14.0928 18.1125 13.082 18.4144C13.3702 17.3602 13.6492 16.3407 13.927 15.3236C14.6348 16.0627 15.3255 16.7851 16.0379 17.5303Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_331_179">
          <rect width="22.8669" height="22" fill="white" transform="translate(0.697266)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SubmissionIcon;
