import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SIDEBAR_FUNCTION } from "../../store/constants/FrontEnd";
import "./styles/Hamburger.css";
const Hamburger = ({ sidebar = false }) => {
  const dispatch = useDispatch();
  const { frontEnd } = useSelector((state) => {
    return { frontEnd: state.FrontEnd };
  });
  let { open } = frontEnd;
  const handleSidebar = () => {
    dispatch({
      type: SIDEBAR_FUNCTION,
      open: !open,
    });
  };
  return (
    <div className={`menu-btn-6 ${sidebar ? "active" : ""} `} onClick={handleSidebar}>
      <span></span>
    </div>
  );
};

export default Hamburger;
