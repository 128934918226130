import * as XLSX from 'xlsx-js-style';
import moment from 'moment';

const ReturnExporToExcel = async (props) => {
  // extracting props
  let { data } = props;
  //   if (data.length == 0) return;
  // file header, and required variable
  const mainHeaderStyle = { alignment: { horizontal: 'center' }, font: { bold: true, sz: '13' } };
  const title = [
    { A: { v: 'PASSPORTS STATUS LIST', s: mainHeaderStyle } },
    { A: { v: 'SKY CENTRE AIR TRAVEL', s: mainHeaderStyle } },
    { A: { v: '93A Syed Alwi Road, S(207669)', s: mainHeaderStyle } },
    { A: { v: 'Phone No.: +65 62911218', s: mainHeaderStyle } },
  ];
  var currentDate = moment(new Date(Date.now())).format('DD-MM-YYYY');
  let table1 = [];
  table1 = title.concat(table1);
  const secondHeaderStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
    font: { bold: true, sz: '12' },
  };
  let secondheader = {
    A: { v: 'S/NO.', s: secondHeaderStyle },
    B: { v: 'NAME', s: secondHeaderStyle },
    C: { v: 'PASSPORT NO', s: secondHeaderStyle },
    D: { v: 'NATIONALITY', s: secondHeaderStyle },
    E: { v: 'STATUS', s: secondHeaderStyle },
  };
  table1.push(secondheader);
  // pushing body
  const bodyStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
  };
  const nameStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'left' },
  };
  for (let i in data) {
    table1.push({
      A: { v: parseInt(i) + 1, s: bodyStyle },
      B: { v: data[i].name, s: nameStyle },
      C: { v: data[i].number, s: bodyStyle },
      D: { v: data[i].nationality, s: bodyStyle },
      E: { v: data[i].status, s: bodyStyle },
    });
  }
  const lastLineStyle = {
    border: { top: { style: 'thin' } },
  };
  table1.push({
    A: { v: '', s: lastLineStyle },
    B: { v: '', s: lastLineStyle },
    C: { v: '', s: lastLineStyle },
    D: { v: '', s: lastLineStyle },
    E: { v: '', s: lastLineStyle },
  });

  // create a web book
  const wb = XLSX.utils.book_new();
  //create a worksheet
  const sheet = XLSX.utils.json_to_sheet(table1, { skipHeader: true });
  // merging cells
  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 4 } },
    { s: { r: 2, c: 0 }, e: { r: 2, c: 4 } },
    { s: { r: 3, c: 0 }, e: { r: 3, c: 4 } },
  ];
  // cells width
  var wscols = [{ wch: 5 }, { wch: 25 }, { wch: 15 }, { wch: 20 }, { wch: 10 }];
  sheet['!merges'] = merge;
  sheet['!cols'] = wscols;

  XLSX.utils.book_append_sheet(wb, sheet, 'RETURN_DATA');
  return XLSX.writeFile(wb, `PASSPORT-RETURN-${currentDate}.xlsx`);
};

export default ReturnExporToExcel;
