import Statement_Constant from '../constants/Statements';
import Users from '../constants/Users';

const initialState = {
  visaloading: false,
  ticketLoading: false,
  miscLoading: false,
  ticketData: {},
  visaData: {},
  miscData: {},
  ticketTotalLoading: false,
  visatTotalLoading: false,
  miscTotalLoading: false,
  ticketTotal: {},
  visaTotal: {},
  miscTotal: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case Statement_Constant.GET_TICKET_INVOICE:
      return {
        ...state,
        ticketLoading: action.loading,
        ticketData: action.data,
      };
    case Statement_Constant.GET_VISA_INVOICE:
      return {
        ...state,
        visaloading: action.loading,
        visaData: action.data,
      };
    case Statement_Constant.GET_MISC_INVOICE:
      return {
        ...state,
        miscLoading: action.loading,
        miscData: action.data,
      };
    case Statement_Constant.GET_TICKET_TOTAL:
      return {
        ...state,
        ticketTotalLoading: action.loading,
        ticketTotal: action.data,
      };
    case Statement_Constant.GET_MISC_TOTAL:
      return {
        ...state,
        miscTotalLoading: action.loading,
        miscTotal: action.data,
      };
    case Statement_Constant.GET_VISA_TOTAL:
      return {
        ...state,
        visatTotalLoading: action.loading,
        visaTotal: action.data,
      };
    default:
      return state;
  }
};
