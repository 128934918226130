import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { Auth, Root } from './routing';
import { MainLoader } from './uiComponents';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { interceptor } from './utils/interceptor';
import { useEffect } from 'react';
import { checkAuth } from './store/action/Users';

function App() {
  interceptor();
  const dispatch = useDispatch();
  const reduxState = useSelector(({ users }) => {
    return {
      loading: users.loading,
      userLoggedIn: users.userLoggedIn,
      user: users.user,
    };
  });
  const { loading, userLoggedIn } = reduxState;
  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  const renderRoute = (value) => {
    switch (value) {
      case true:
        return <Root />;
      default:
        return <Auth />;
    }
  };

  return <div className=''>{loading ? <MainLoader /> : renderRoute(userLoggedIn)}</div>;
}

export default App;
