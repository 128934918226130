import { IconButton, TableCell } from '@mui/material';
import React, { useState } from 'react';
import { AllButton, CommonModal, LoaderOne, TableStyle } from '../../../uiComponents';
import PassportStatus from '../../../utils/Constants/passportStatus.constants';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { changeStatus, getSubmittedList } from '../../../store/action/VisaInvoice';
import { MdDelete } from 'react-icons/md';
import PassportMode from '../../../utils/Constants/PassportMode.constants';
import moment from 'moment';
const { StyledTableCell, StyledTableRow } = TableStyle;

const { BorderSecondButton } = AllButton;

const SubmissionListRow = (props) => {
  const {
    id,
    date,
    passportNumber,
    status,
    userId,
    invoiceDate,
    invoiceId,
    name,
    passportExpiryDate,
    visaForCountry,
    page,
  } = props.data;
  console.log(props.data);
  const alert = useAlert();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loadingDelete, setloadingDelete] = useState(false);
  const updateAction = (e) => {
    e.preventDefault();
    let payload = {
      passportNumber: passportNumber,
      status: PassportStatus.PENDING,
      mode: PassportMode.INVOICED,
      comments: '',
    };
    setloadingDelete(true);
    dispatch(changeStatus(payload, passportCallback));
  };

  const passportCallback = ({ error, message, data }) => {
    if (error) {
      message?.error && alert.error(message?.error);
      setloadingDelete(false);
      return;
    }
    if (data?.status == 'failure') {
      data?.message && alert.error(data?.message);
      setloadingDelete(false);
      return;
    }
    alert.success('Deleted Successfully');
    setloadingDelete(false);
    dispatch(getSubmittedList({ pageNo: page, search: '' }));
  };

  const updateModal = () => {
    return (
      <CommonModal showModal={showModal} callback={(e) => setShowModal(false)}>
        <div>
          <section>
            <section className='' style={{ fontSize: '20px', padding: '30px' }}>
              <h5>Are you sure you want to delete it ?</h5>
            </section>

            <section style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <BorderSecondButton sx={{ fontSize: '14px', marginRight: '15px' }} onClick={() => setShowModal(false)}>
                  cancel
                </BorderSecondButton>
              </div>
              <div>
                {loadingDelete ? (
                  <div style={{ width: '50px', height: '30px' }}>
                    <LoaderOne />
                  </div>
                ) : (
                  <BorderSecondButton
                    sx={{
                      fontSize: '14px',
                      marginLeft: '15px',
                      background: '#FF3131',
                      borderColor: '#FF3131',
                      color: '#fff',
                    }}
                    onClick={updateAction}
                  >
                    Delete
                  </BorderSecondButton>
                )}
              </div>
            </section>
          </section>
        </div>
      </CommonModal>
    );
  };

  return (
    <>
      {showModal && updateModal()}
      <StyledTableRow>
        <StyledTableCell align='center'>{moment(date).format('DD/MM/YYYY')}</StyledTableCell>
        <StyledTableCell align='center'>{name}</StyledTableCell>
        <StyledTableCell align='center'>{passportNumber}</StyledTableCell>
        <StyledTableCell align='center'>{invoiceId}</StyledTableCell>
        <StyledTableCell align='center'>{invoiceDate}</StyledTableCell>
        <StyledTableCell align='center'>
          <IconButton onClick={() => setShowModal(true)}>
            <MdDelete style={{ fontSize: '20px', color: 'red' }} />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default SubmissionListRow;
