import {  IconButton } from '@mui/material';
import React, { useState } from 'react';
import { AllButton, CommonModal, ErrorAlert, TableStyle } from '../../../uiComponents';
import RectSelect from '../../../uiComponents/Select/RectSelect';
import PassportStatus from '../../../utils/Constants/passportStatus.constants';
import UsersRoles from '../../../utils/Constants/UserRoles.constants';
import { useSelector } from 'react-redux';
import { Android12Switch } from '../../../uiComponents/Switch/AndroidSwitch';
import ExportIcon from '../../../uiComponents/Icons/ExportIcon';
import { post } from '../../../utils/methods';
import { useAlert } from 'react-alert';
const { StyledTableCell, StyledTableRow } = TableStyle;

const { FullSecondButton } = AllButton;

const UsersRow = (props) => {
  const { emailId, id, mobile, name, role, username, active } = props;
  const [showModal, setShowModal] = useState(false);
  const [password, setpassword] = useState('')
  const [passwordError, setpasswordError] = useState('')
  const [status, setstatus] = useState(active)
  const alert = useAlert()
  const reduxState = useSelector(({ users }) => {
    return {
      loading: users.getUserLoading,
      usersList: users.usersList,
      user: users.user,
    };
  });
  let { user } = reduxState;
  const updateAction = async(e) => {
    try {
      e.preventDefault();
      if (password.length < 6) return setpasswordError('Password length greater than 6');
      setpasswordError('');
      const body = {
        emailId,
        password,
      };
      const response = await post('/changeUserPassword', body);
      if (response?.data?.status === 'failure') {
        setShowModal(false);
        alert.error(response?.data?.message);
        console.log(response?.data);
      } else {
        setShowModal(false);
        alert.success(response?.data?.message);
      }
    } catch (err) {
      alert.error(err?.response?.data?.message);
    }
  
  };

  const updateModal = () => {
    return (
      <CommonModal showModal={showModal} callback={(e) => setShowModal(false)}>
        <div>
          <section>
            <h5>Change Password </h5>
          </section>
          <section>
            <form action=''>
              <section className='visa_invoice_input_div'>
                <h5>New Password</h5>
                <div className='visa_invoice_input_inner' style={{marginTop:"10px"}}>
                  <input
                    type='text'
                    placeholder='New Password'
                    value={password}
                    name={'password'}
                    style={{textTransform:"none"}}
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </div>
                {passwordError?.length !== 0 && (
                  <div style={{ marginTop: '10px' }}>
                    <ErrorAlert error={passwordError} />
                  </div>
                )}
              </section>
              <section style={{marginTop:"20px"}}>
                  <FullSecondButton sx={{ fontSize: '14px' }} onClick={updateAction}>
                    Update
                  </FullSecondButton>
              </section>
            </form>
          </section>
        </div>
      </CommonModal>
    );
  };

  const changeStatus = async () => {
    try {
      setstatus(!status);
      const body = {
        emailId,
        status: status ? 'INACTIVE':'ACTIVE' ,
      };
      const response = await post('/changeUserStatus', body);
      console.log('i', response);
      if (response?.data?.status === 'failure') {
        alert.error(response?.data?.message);
      } else {
        alert.success(response?.data?.message);
      }
    } catch (err) {
      alert.error(err?.response?.data?.message);
    }
  };

  return (
    <>
    {showModal && updateModal()}
      <StyledTableRow>
        <StyledTableCell>{name}</StyledTableCell>
        <StyledTableCell align='center'>{emailId}</StyledTableCell>
        <StyledTableCell align='center'>{mobile}</StyledTableCell>
        <StyledTableCell align='center'>{role === UsersRoles.ROLE_USER ? 'User' : role}</StyledTableCell>
        {user.role === UsersRoles.ROLE_ADMIN && (
          <StyledTableCell align='center'>
            <Android12Switch checked={status} onChange={changeStatus}  />
          </StyledTableCell>
        )}
        {user.role === UsersRoles.ROLE_ADMIN && (
          <StyledTableCell align='center'>
            <IconButton onClick={(e)=>setShowModal(!showModal)}>
              <ExportIcon />
            </IconButton>
          </StyledTableCell>
        )}
      </StyledTableRow>
    </>
  );
};

export default UsersRow;
