const showPayment = (paymentType, paymentData) => {
    let amount = 0;
    const specificPaymentDate = paymentData.filter(({ paymentMethod, paidAmount }, index) => paymentMethod === paymentType)
    console.log(specificPaymentDate);
  
    amount = specificPaymentDate.reduce((data, num)=> {
      console.log(num);
      return data + Math.round(num?.paidAmount);
    }, 0)
    return amount
  }

export {showPayment}