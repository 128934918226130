import { Pagination, PaginationItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CommonHeading from '../../Components/VisaInvoice/CommonHeading';
import '../../Components/VisaInvoice/styles/VisaInvoice.css';
import { AllButton } from '../../uiComponents';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import '../../Components/VisaInvoice/styles/VisaInvoiceList.css';
import { getMiscList } from '../../store/action/Misc';
import MiscInvoiceListTable from '../../Components/Misc/List/MiscInvoiceListTable';
import PassportSearch from '../../Components/Passport/PassportSearch';
import LoaderTwo from '../../uiComponents/Loader/LoaderTwo';

const { FullSecondButton } = AllButton;
const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: '#528DC8 !important',
    color: '#fff !important',
  },
}));

const MiscList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reduxState = useSelector(({ misc }) => {
    return {
      invoiceData: misc?.miscList,
      loading: misc.listLoading,
    };
  });
  let { invoiceData, loading } = reduxState;
  const [page, setpage] = useState(1);
  const [search, setsearch] = useState('');

  useEffect(() => {
    dispatch(getMiscList({ pageNo: page, pageSize: 50, search: search.toUpperCase() }));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page) {
      dispatch(getMiscList({ pageNo: page, pageSize: 50, search: search.toUpperCase() }));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getMiscList({ pageNo: page, pageSize: 50, search: search.toUpperCase() }));
  };

  return (
    <div className='visa_invoice'>
      {/* client details section start */}
      <section className='visa_invoice_list_header_div'>
        <div>
          <CommonHeading heading={'Miscellaneous Invoices List'} tag={''} />
        </div>

        <div style={{ minWidth: '280px' }}>
          <Link to={`/misc-invoice`}>
            <FullSecondButton>Add new </FullSecondButton>
          </Link>
        </div>
      </section>
      <section className=' passport_return_second' style={{ display: 'flex' }}>
        <section>
          <form action='' onSubmit={handleSearch}>
            <PassportSearch
              value={search}
              callback={(e) => setsearch(e.target.value)}
              placeholder='Search by Invoice No. and Name'
            />
          </form>
        </section>
        <div className='' style={{ marginLeft: '50px' }}>
          <FullSecondButton sx={{ fontSize: '14px' }} onClick={handleSearch}>
            Show
          </FullSecondButton>
        </div>
      </section>
      {/* passport details section start */}
      <section style={{ margin: '40px 0' }}>
        <MiscInvoiceListTable />
        {invoiceData?.invoices?.length === 0 && (
          <div style={{ display: 'flex', justifyContent: 'center', margin: '80px 0' }}>No Data available </div>
        )}
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
            <LoaderTwo />
          </div>
        )}
      </section>
      <section className='pagination_passport'>
        <Pagination
          count={invoiceData?.totalPages}
          shape='rounded'
          variant='outlined'
          color='primary'
          page={page}
          onChange={(e, v) => setpage(v)}
          renderItem={(item) => <PaginationItem {...item} classes={{ selected: classes.selected }} />}
        />
      </section>
    </div>
  );
};

export default MiscList;
