import { get, post } from '../../utils/methods';
import Ticket_Constants from '../constants/Ticket';

export const getTicketList =
  ({ pageNo, pageSize = 50, search }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: Ticket_Constants.GET_TICKET_LIST, loading: true });
      const response = await get(`/getTickets?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}`);
      if (response?.data?.status === 'failure') {
        dispatch({ type: Ticket_Constants.GET_TICKET_LIST, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: Ticket_Constants.GET_TICKET_LIST, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      dispatch({ type: Ticket_Constants.GET_TICKET_LIST, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const saveTicket = (payload, CB) => async (dispatch) => {
  try {
    dispatch({ type: Ticket_Constants.SAVE_TICKET, loading: true });
    const response = await post(`/saveTicket`, payload);
    if (response?.data?.status === 'failure') {
      dispatch({ type: Ticket_Constants.SAVE_TICKET, loading: false });
      CB && CB({ error: false, data: response?.data });
    } else {
      dispatch({ type: Ticket_Constants.SAVE_TICKET, loading: false });
      CB && CB({ error: false, data: response?.data });
    }
  } catch (error) {
    dispatch({ type: Ticket_Constants.SAVE_TICKET, loading: false });
    CB && CB({ error: true, message: error?.response?.data });
  }
};

export const editTicket = (payload, invoiceId, CB) => async (dispatch) => {
  try {
    dispatch({ type: Ticket_Constants.SAVE_TICKET, loading: true });
    const response = await post(`/editTicket?ticketId=${invoiceId}`, payload);
    if (response?.data?.status === 'failure') {
      dispatch({ type: Ticket_Constants.SAVE_TICKET, loading: false });
      CB && CB({ error: false, data: response?.data });
    } else {
      dispatch({ type: Ticket_Constants.SAVE_TICKET, loading: false });
      CB && CB({ error: false, data: response?.data });
    }
  } catch (error) {
    dispatch({ type: Ticket_Constants.SAVE_TICKET, loading: false });
    CB && CB({ error: true, message: error?.response?.data });
  }
};

export const getTicketDetails =
  ({ ticketId }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: Ticket_Constants.GET_TICKET_DETAILS, loading: true });
      const response = await get(`/ticket?ticketId=${ticketId}`);
      if (response?.data?.status === 'failure') {
        dispatch({ type: Ticket_Constants.GET_TICKET_DETAILS, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: Ticket_Constants.GET_TICKET_DETAILS, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      dispatch({ type: Ticket_Constants.GET_TICKET_DETAILS, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };
