import moment from 'moment';
import * as XLSX from 'xlsx-js-style';
const ExporToExcel = async (props) => {
  // extracting props
  let { data, pageNo } = props;
  var currentDate = moment(new Date(Date.now())).format('DD-MM-YYYY');

  const secondHeaderStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
    font: { bold: true, sz: '12' },
  };
  const bodyStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
  };
  const nameStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'left' },
  };
  const lastLineStyle = {
    border: { top: { style: 'thin' } },
  };
  let secondheader = {
    A: { v: 'S.NO.', s: secondHeaderStyle },
    B: { v: 'NAME', s: secondHeaderStyle },
    C: { v: 'PASSPORT NO', s: secondHeaderStyle },
    D: { v: 'NATIONALITY', s: secondHeaderStyle },
    E: { v: 'VISA FEE', s: secondHeaderStyle },
    F: { v: 'AGENT FEE', s: secondHeaderStyle },
    G: { v: 'AGENT GST', s: secondHeaderStyle },
    H: { v: 'SKY TRAVEL', s: secondHeaderStyle },
    I: { v: 'SKY TRAVEL GST', s: secondHeaderStyle },
  };
  const breakLength = 10;
  const tempstudentarray = [...data];
  const wb = XLSX.utils.book_new();
  for (let i = 0; i < Math.ceil(data?.length / breakLength); i++) {
    let table1 = [];
    let j = 0;
    table1.push(secondheader);
    const tableData = tempstudentarray.splice(j, (j += breakLength));
    for (let i in tableData) {
      table1.push({
        A: { v: parseInt(i) + 1, s: bodyStyle },
        B: { v: tableData[i].name, s: nameStyle },
        C: { v: tableData[i].number, s: bodyStyle },
        D: { v: tableData[i].nationality, s: bodyStyle },
        E: { v: tableData[i].visaFee, s: bodyStyle },
        F: { v: tableData[i].agentFee, s: bodyStyle },
        G: { v: tableData[i].agentFeeGST, s: bodyStyle },
        H: { v: tableData[i].skyServicefee, s: bodyStyle },
        I: { v: tableData[i].skyServicefeeGST, s: bodyStyle },
      });
    }
    table1.push({
      A: { v: '', s: lastLineStyle },
      B: { v: '', s: lastLineStyle },
      C: { v: '', s: lastLineStyle },
      D: { v: '', s: lastLineStyle },
      E: { v: '', s: lastLineStyle },
      F: { v: '', s: lastLineStyle },
      G: { v: '', s: lastLineStyle },
      H: { v: '', s: lastLineStyle },
      I: { v: '', s: lastLineStyle },
    });

    //create a worksheet
    const sheet = XLSX.utils.json_to_sheet(table1, { skipHeader: true });
    // setting width
    var wscols = [
      { wch: 5 },
      { wch: 25 },
      { wch: 15 },
      { wch: 20 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 10 },
      { wch: 15 },
    ];
    sheet['!cols'] = wscols;
    XLSX.utils.book_append_sheet(wb, sheet, `SUBMISSION_${i + 1}`);
  }

  return XLSX.writeFile(wb, `SUBMISSION-LIST_SKYCENTRE_${pageNo}_${currentDate}.xlsx`);
};

export default ExporToExcel;
