import React, { useEffect, useState } from 'react';
import { AllButton, LoaderOne } from '../../uiComponents';
import './../../Components/Passport/styles/PassportReturn.css';
import ExportIcon from '../../uiComponents/Icons/ExportIcon';
import PassportHeading from '../../Components/Passport/PassportHeading';
import PassportSearch from '../../Components/Passport/PassportSearch';
import PassportReturnTable from '../../Components/Passport/Return/PassportReturnTable';
import { Pagination, PaginationItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { changeStatus, changeStatusAll, getReturnList } from '../../store/action/VisaInvoice';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import ReturnExporToExcel from '../../Components/Passport/Return/ReturnExportToExcel';
import PassportStatus from '../../utils/Constants/passportStatus.constants';
import PassportMode from '../../utils/Constants/PassportMode.constants';
import VisaInvoiceConstant from '../../store/constants/VisaInvoice.constants';
// import { makeStyles } from "@mui/styles";
const { BorderSecondButton, FullSecondButton } = AllButton;
const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: '#528DC8 !important',
    color: '#fff !important',
  },
}));
const PassportReturn = () => {
  const classes = useStyles();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [searchValue, setsearchValue] = useState('');
  const [country, setcountry] = useState({ key: '', label: 'Select country', value: '' });
  const [page, setpage] = useState(1);
  const [status, setstatus] = useState({ key: '', value: '', label: 'Select status' });
  const [comment, setcomment] = useState('');
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      passData: visaInvoice?.returnList,
      loading: visaInvoice.returnListLoading,
      returnPassports: visaInvoice.returnPassports,
      returnLoading: visaInvoice.returnLoading,
    };
  });
  let { passData, loading, returnPassports, returnLoading } = reduxState;

  useEffect(() => {
    dispatch(
      getReturnList({
        pageNo: page,
        pageSize: 50,
        country: country?.value || '',
        searchValue: searchValue.toUpperCase(),
      })
    );
    return () => {};
  }, []);

  useEffect(() => {
    if (page) {
      dispatch(
        getReturnList({
          pageNo: page,
          pageSize: 50,
          country: country?.value || '',
          searchValue: searchValue.toUpperCase(),
        })
      );
    }
    return () => {};
  }, [page]);

  useEffect(() => {
    if (country) {
      dispatch(
        getReturnList({ pageNo: page, pageSize: 50, country: country?.value, searchValue: searchValue.toUpperCase() })
      );
    }
    return () => {};
  }, [country]);

  const handleExport = async (e) => {
    try {
      e.preventDefault();
      if (passData?.passports?.length == 0) return;
      ReturnExporToExcel({
        data: passData?.passports,
      });
    } catch (err) {
      alert.error(err, { type: 'error' });
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      getReturnList({ pageNo: page, pageSize: 50, country: country?.value, searchValue: searchValue.toUpperCase() })
    );
  };

  const updateAction = (e) => {
    e.preventDefault();
    if (!returnPassports.length) return alert.error("Please select atleast one passport")
    let payload;
      payload = {
        passportNumber: returnPassports,
        status: PassportStatus.APPROVED,
        mode: PassportMode.RETURNED,
      };

    dispatch(changeStatusAll(payload, passportCallback));
  };

  const passportCallback = ({ error, message, data }) => {
    if (error) {
      message?.error && alert.error(message?.error);
      return;
    }
    if (data?.status == 'failure') {
      data?.message && alert.error(data?.message);
      return;
    }
    alert.success('Status changed Successfully');
    dispatch({
      type: VisaInvoiceConstant.ADD_PASSPORT_RETURN,
      returnPassports: [],
    });
    dispatch(
      getReturnList({
        pageNo: page,
        pageSize: 50,
        country: country?.value || '',
        searchValue: searchValue.toUpperCase(),
      })
    );
  };
  return (
    <div className='passport_return_main_div'>
      {/* first section start */}
      <section className='passport_return_header'>
        <div>
          <PassportHeading title={'Passport Return'} />
        </div>
        <div className='passport_return_header_buttons '>
          {/* <div className="passport_return_header_button_download">
            <BorderSecondButton>
              {" "}
              <div style={{ marginRight: "10px", display: "flex", alignItems: "center" }}>
                <DownloadIcon />
              </div>
              Download Invoice
            </BorderSecondButton>
          </div> */}
          <div>
            <BorderSecondButton onClick={handleExport}>
              {' '}
              <div style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                <ExportIcon />
              </div>{' '}
              Export
            </BorderSecondButton>
          </div>
        </div>
      </section>
      {/* first section end */}
      {/* filter section start */}
      <section className=' passport_return_second' style={{ display: 'flex' }}>
        <section>
          <PassportSearch
            value={searchValue}
            callback={(e) => setsearchValue(e.target.value)}
            placeholder='Search by Passport No, name'
          />
        </section>
        <div className='' style={{ marginLeft: '50px' }}>
          <FullSecondButton sx={{ fontSize: '14px' }} onClick={handleSearch}>
            Show
          </FullSecondButton>
        </div>
      </section>
      {/* filter section end */}
      {/* bullk update start */}
      <section className='passport_return_second' style={{ display: 'flex', alignItems:'baseline',justifyContent:'end' }}>
          {/* <section style={{ width: '300px' }}>
            <h5>Passport Status</h5>
            <div style={{ marginTop: '5px', width: '100%' }}>
              <RectSelect
                defaultValue={status}
                data={Object.values(PassportStatus).map((data, index) => ({
                  key: data + index,
                  value: data,
                  label: data,
                }))}
                callback={setstatus}
              />
            </div>
          </section> */}
          {/* {status?.value == PassportStatus.REJECTED && (
                <section style={{ marginTop: '20px', width:"300px" }}>
                  <h5>Comment</h5>
                  <div className='comment_in_modal'>
                    <textarea style={{}} value={comment} onChange={(e) => setcomment(e.target.value)} />
                  </div>
                </section>
              )} */}
            {returnLoading ? (
              <FullSecondButton sx={{ fontSize: '14px', width: '200px', marginLeft: '30px', marginTop:'auto'  }}>
                <LoaderOne />
              </FullSecondButton>
            ) : (
              <FullSecondButton sx={{ fontSize: '14px', width: '200px',  marginTop:'auto' }} onClick={updateAction}>
               Submit Return/Approved
              </FullSecondButton>
            )}
      </section>
      {/* bulk update end */}
      {/* table section start */}
      <section style={{ marginTop: '50px' }}>
        <PassportReturnTable />
      </section>
      {/* table section end */}
      {/* pagination section start */}
      <section className='passport_return_pagination_section'>
        {/* <section className="pagination_select_div" onClick={() => selectRef.current?.focus()}>
          Show :
          <select
            ref={selectRef}
            value={limit}
            onChange={(e) => setlimit(e.target.value)}
            style={{ border: "none", outline: "none" }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </select>
          <FiChevronDown style={{ fontSize: "20px" }} />
        </section> */}

        <section className='pagination_passport'>
          <Pagination
            count={passData?.totalPages}
            shape='rounded'
            variant='outlined'
            color='primary'
            page={page}
            onChange={(e, v) => setpage(v)}
            renderItem={(item) => <PaginationItem {...item} classes={{ selected: classes.selected }} />}
          />
        </section>
      </section>
      {/* pagination section end */}
    </div>
  );
};

export default PassportReturn;
