import { Checkbox, IconButton, TableCell } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AllButton, CommonModal, TableStyle } from '../../../uiComponents';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import RectSelect from '../../../uiComponents/Select/RectSelect';
import PassportStatus from '../../../utils/Constants/passportStatus.constants';
import { useDispatch, useSelector } from 'react-redux';
import { changeStatus } from '../../../store/action/VisaInvoice';
import { useAlert } from 'react-alert';
import PassportMode from '../../../utils/Constants/PassportMode.constants';
import VisaInvoiceConstant from '../../../store/constants/VisaInvoice.constants';
const { StyledTableCell, StyledTableRow, StyledTableCellTranparent, GreyTableRow } = TableStyle;

const { FullSecondButton } = AllButton;

const PassportReturnRow = (props) => {
  const {
    id,
    passport_no,
    passport_expiry_date,
    name,
    gender,
    nationality,
    type_of_pass,
    pass_expiry,
    visa_for_country,
    return_status,
    comments,
  } = props;
  const dispatch = useDispatch();
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      returnPassports: visaInvoice.returnPassports,
    };
  });
  let { returnPassports } = reduxState;

  const alert = useAlert();
  const [showModal, setShowModal] = useState(false);
  const [status, setstatus] = useState({ key: return_status, value: return_status, label: return_status });
  const [comment, setcomment] = useState(comments);
  const [checked, setchecked] = useState(false);

  const updateAction = (e) => {
    e.preventDefault();
    let payload;
    if (status?.value == PassportStatus.APPROVED || status?.value == PassportStatus.REJECTED) {
      console.log("status", status?.value);
      payload = {
        passportNumber: passport_no,
        status: status?.value == PassportStatus.REJECTED ? status?.value : PassportStatus.APPROVED,
        mode: PassportMode.RETURNED,
        comments: comment,
      };
    }else if(status?.value == PassportStatus.WITHDRAWN){
      payload = {
        passportNumber: passport_no,
        status: status?.value,
        mode: PassportMode.WITHDRAWL,
        comments: comment,
      };
    } else {
      payload = {
        passportNumber: passport_no,
        status: status?.value,
        mode: PassportMode.SUBMITTED,
        comments: comment,
      };
    }

    dispatch(changeStatus(payload, passportCallback));
  };

  const passportCallback = ({ error, message, data }) => {
    if (error) {
      message?.error && alert.error(message?.error);
      return;
    }
    if (data?.status == 'failure') {
      data?.message && alert.error(data?.message);
      return;
    }
    alert.success('Status changed Successfully');
    setShowModal(false);
  };
  const updateModal = () => {
    return (
      <CommonModal showModal={showModal} callback={(e) => setShowModal(false)}>
        <div>
          <section>
            <h5>Passport No: {passport_no}</h5>
          </section>
          <section>
            <form action=''>
              <section className='visa_invoice_input_div'>
                <h5>Passport Status</h5>
                <div style={{ marginTop: '5px', width: '100%', marginBottom: '20px' }}>
                  <RectSelect
                    defaultValue={status}
                    data={Object.values(PassportStatus).map((data, index) => ({
                      key: data + index,
                      value: data,
                      label: data,
                    }))}
                    callback={setstatus}
                  />
                </div>
              </section>
              {status?.value == PassportStatus.REJECTED && (
                <section style={{ marginTop: '20px' }}>
                  <h5>Comment</h5>
                  <div className='comment_in_modal'>
                    <textarea style={{}} value={comment} onChange={(e) => setcomment(e.target.value)} />
                  </div>
                </section>
              )}
              <section>
                <div>
                  <FullSecondButton sx={{ fontSize: '14px' }} onClick={updateAction}>
                    Update
                  </FullSecondButton>
                </div>
              </section>
            </form>
          </section>
        </div>
      </CommonModal>
    );
  };
  const returnStatusComponents = () => {
    switch (status?.value) {
      case PassportStatus.APPROVED:
        return (
          <div
            style={{
              color: '#2FB182',
              backgroundColor: '#E7FDF5',
              padding: '5px 12px',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            Approved
          </div>
        );
      case 'NOT_RETURN':
        return (
          <div
            style={{
              color: '#DE486C',
              backgroundColor: '#FFECF0',
              padding: '5px 12px',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            Not Return
          </div>
        );
      case PassportStatus.REJECTED:
        return (
          <div
            style={{
              color: '#FFAE00',
              backgroundColor: '#FFF7E6',
              padding: '5px 12px',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            Rejected
          </div>
        );
      case PassportStatus.PENDING:
        return (
          <div
            style={{
              color: '#2FB182',
              backgroundColor: '#E7FDF5',
              padding: '5px 12px',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            PENDING
          </div>
        );

      default:
        return (
          <div
            style={{
              color: '#2FB182',
              backgroundColor: '#E7FDF5',
              padding: '5px 12px',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            PENDING
          </div>
        );
        break;
    }
  };

  const addPassports = () => {
    if (returnPassports.indexOf(passport_no) === -1) {
      dispatch({
        type: VisaInvoiceConstant.ADD_PASSPORT_RETURN,
        returnPassports: [...returnPassports, passport_no],
      });
      setchecked(true);
    } else {
      dispatch({
        type: VisaInvoiceConstant.ADD_PASSPORT_RETURN,
        returnPassports: returnPassports?.filter((d) => d != passport_no),
      });
      setchecked(false);
    }
  };
  useEffect(() => {
    if (returnPassports.indexOf(passport_no) === -1) {
      setchecked(false);
    } else {
      setchecked(true);
    }
  }, [returnPassports]);
  return (
    <>
      {showModal && updateModal()}
      <StyledTableRow>
      <StyledTableCell>{id}</StyledTableCell>
        <StyledTableCell>{passport_no}</StyledTableCell>
        <StyledTableCell align='center'>{name}</StyledTableCell>
        <StyledTableCell align='center' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {returnStatusComponents()} <IconButton onClick={() => setShowModal(!showModal)}>:</IconButton>{' '}
        </StyledTableCell>
        <StyledTableCell align='center'>
          <Checkbox checked={checked} onChange={addPassports} />
        </StyledTableCell>
      </StyledTableRow>
      {comment && status?.value == PassportStatus.REJECTED && (
        <GreyTableRow>
          <TableCell
            style={{
              backgroundColor: '#EBEFF2',
            }}
            colSpan={7}
          />
          <TableCell
            colSpan={3}
            sx={{
              maxWidth: '300px',
              p: 0,
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                border: '1px dashed black',
                fontWeight: '600',
                fontSize: '13px',
                color: '#000A13',
                fontFamily: 'Nunito',
                padding: '10px 20px',
              }}
            >
              {comment}
            </div>
          </TableCell>
     
        </GreyTableRow>
      )}
    </>
  );
};

export default PassportReturnRow;
