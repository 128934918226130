import React from "react";
import Select, { components, ControlProps, StylesConfig } from "react-select";

const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "1px solid  #d7d7d7",
    padding: "0px 10px",
    textTransform: "uppercase",
    fontSize: "14px",
    ":focus": {
      border: "none",
      outline: "none !important",
      textTransform: "uppercase",
      //   borderColor: "#00b0ff",
    },
    ":active": {
      border: "none",
      outline: "none !important",
      //   borderColor: "#00b0ff",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      backgroundColor: isSelected ? "#528DC8" : isFocused ? "#bdd8f2" : "#fff",
      padding: "3px 15px",
      cursor: "pointer",
      zIndex: 100000,
      textTransform: "uppercase",
      fontSize: "14px",
      color: isSelected ? "#fff" : isFocused ? "#fff" : "#000",
      ":hover": {
        backgroundColor: "#bdd8f2 !important",
        color: "#fff !important",
      },
      ":active": {
        ...styles[":active"],
        backgroundColor: "#bdd8f2 !important",
        color: "#fff !important",
      },
    };
  },
};

const MultiSelect = ({ defaultValue, data, callback }) => {
  return (
    <div className="w-full">
      <Select
        className="w-full"
        defaultValue={defaultValue}
        isClearable
        isSearchable
        isMulti
        // value={value}
        onChange={callback}
        name="Select"
        styles={selectStyles}
        options={data}
      />
    </div>
  );
};

export default MultiSelect;
