import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 10000,
  offset: '20px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};
// const AlertTemplate = ({ style, options, message, close }) => (
//   <div
//     style={{
//       ...style,
//       maxWidth: '350px',
//       backgroundColor: '#4f5a64',
//       overflow: 'hidden',
//       color: 'white',
//       padding: '15px',
//       borderRadius: '5px',
//       display: 'flex',
//       alignItems: 'center',
//     }}
//   >
//     {message}
//     <button onClick={close}>X</button>
//   </div>
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BrowserRouter>
    </AlertProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
