import React, { useEffect, useRef, useState } from 'react';
import styles from '../../Components/VisaInvoice/styles/VisaInvoicePrint.module.css';
import CurrencyFormatter from '../../uiComponents/CurrencyFormatter/CurrencyFormatter';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { AllButton } from '../../uiComponents';
import VisaPrintTopItem from '../../Components/VisaInvoice/Print/VisaPrintTopItem';
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import MiscConstants from '../../store/constants/Misc.constants';
import { getMiscDetails } from '../../store/action/Misc';

const { MainButton } = AllButton;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fff',
    color: theme.palette.common.black,
    fontSize: 13,
    fontWeight: 700,
    textTransform: 'uppercase',
    fontFamily: 'Nunito',
    padding: '2px 5px',
    margin: '0',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontWeight: 500,
    color: '#000',
    fontFamily: 'Nunito',
    textTransform: 'uppercase',
    padding: '10px 5px',
    border: '0px ',
    margin: '0',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#FFF',
    fontFamily: 'Nunito',
  },
  //   hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    fontFamily: 'Nunito',
  },
  [`&:hover`]: {
    backgroundColor: '#FFF',
    color: '#000 !important',
  },
}));

const MiscInvoicePrint = (props) => {
  // const { invoice, invoiceDate, paymentMethod, passportsData, totalAmount, clientName } = props;
  const [searchParams] = useSearchParams();
  let printDiv = useRef();
  const invoiceId = searchParams.get('invoiceId');
  const [total, settotal] = useState(0);
  const dispatch = useDispatch();
  const reduxState = useSelector(({ misc }) => {
    return {
      loading: misc.saveLoading,
      miscDetails: misc.miscDetails,
    };
  });
  let { loading, miscDetails } = reduxState;

  useEffect(() => {
    dispatch(getMiscDetails({ invoiceId }));
    return () => {
      dispatch({ type: MiscConstants.GET_MISC_DETAILS, loading: false, data: {} });
    };
  }, []);

  useEffect(() => {
    if (!miscDetails) return;
    if (miscDetails?.productData?.length === 0) return;
    var reducedArray = miscDetails?.productData?.reduce(function (accumulator, item) {
      accumulator = Number(accumulator) + Number(item['amount']);
      return Number(accumulator);
    }, 0);
    settotal(reducedArray);
    return () => {};
  }, [miscDetails]);

  return (
    <div>
      <div ref={(el) => (printDiv = el)} className={styles.main_print_div}>
        <section style={{ display: 'flex' }}>
          <div className={styles.logo_div}>
            <img
              src={require('../../assets/logo/mainLogo.png')}
              alt='sky centre '
              className=''
              style={{ objectFit: 'contain', width: '150px' }}
            />
          </div>
          <div className='' style={{ textAlign: 'center', width: '100%', marginLeft: '-150px' }}>
            <h2 className={styles.heading}>Sky Centre Air Travel </h2>
            <p>93A Syed Alwi Road,</p>
            <p>S(207669),</p>
            <p>Phone No. - +65 62911218,</p>
            <p>GST REG NO : 198701479E</p>
          </div>
        </section>
        <hr style={{ padding: '0.5px', background: '#000', marginTop: '15px' }} />
        <h4 style={{ textAlign: 'center', fontSize: '20px', marginTop: '20px', fontWeight: '700' }}>INVOICE</h4>
        <section style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <h5>To: {miscDetails?.clientName}</h5>
          </div>
          <div style={{ marginRight: '20px' }}>
            <VisaPrintTopItem heading='INVOICE NO' label={miscDetails?.invoiceId} isMain={true} />
            <VisaPrintTopItem heading='INVOICE DATE' label={moment(miscDetails?.invoiceDate).format('DD/MM/YYYY')} />
            {/* <VisaPrintTopItem heading="CONSULTANT" label="AZMI" /> */}
            <VisaPrintTopItem
              heading='CREDIT TERMS'
              label={
                miscDetails?.paymentMethod
                  ? miscDetails?.paymentMethod?.length > 1
                    ? 'MULTIPLE TYPES'
                    : miscDetails?.paymentMethod[0]?.paymentMethod
                  : 'CASH'
              }
            />
            {/* <VisaPrintTopItem heading="YOUR REF NO." label="MR RAJIV" />  */}
          </div>
        </section>

        <section style={{ width: '100%', marginTop: '20px' }}>
          <TableContainer>
            <Table sx={{ marginBottom: '20px' }} aria-label='simple table'>
              <TableHead sx={{ borderBottom: '1px solid #000', borderTop: '1px solid #000' }}>
                <StyledTableRow>
                  <StyledTableCell sx={{ width: '100px' }} align='left'>
                    Service
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '150px' }} align='left'>
                    Description
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '50px' }} align='left'>
                    qty
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '50px' }} align='left'>
                    rate (S$)
                  </StyledTableCell>
                  <StyledTableCell align='center' sx={{ width: '50px' }}>
                    Amount (S$)
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {miscDetails?.productData?.map(({ productName, description, rate, quantity, amount }, index) => (
                  <StyledTableRow key={index + rate + productName}>
                    <StyledTableCell align='left'>{productName}</StyledTableCell>
                    <StyledTableCell align='left'>{description}</StyledTableCell>
                    <StyledTableCell align='left'>{quantity}</StyledTableCell>
                    <StyledTableCell align='left'>{rate}</StyledTableCell>
                    <StyledTableCell align='center'>{Number(amount)?.toFixed(2)}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </section>
        {/* <section>
        <p style={{ textAlign: "center" }}>************** END ***************</p>
      </section> */}
        <hr style={{ padding: '0.5px', background: '#000', marginTop: '40px' }} />
        <section style={{ display: 'flex', padding: '10px 20px', textTransform: 'uppercase' }}>
          <div
            style={{ marginLeft: 'auto', marginRight: '20px', display: 'flex', fontWeight: '700', fontSize: '15px' }}
          >
            <p style={{ marginRight: '10px' }}>Total</p>:{' '}
            <p style={{ marginLeft: '20px' }}>{Number(total).toFixed(2)}</p>
          </div>
        </section>
        <section style={{ width: 'max-content', marginTop: '20px' }}>
          <h4 style={{ borderTop: '2px solid #000', padding: '10px 30px' }}>Authorised Signature</h4>
        </section>
        <hr style={{ padding: '0.5px', background: '#000', marginTop: '40px' }} />
        <section>
          <div className={styles.terms}>
            <h3 style={{ fontWeight: '500', fontSize: '14px' }}>Terms and conditions.</h3>
            <ul>
              <li>All visa services are non refundable. </li>
              <li>
                Visa applications are subjected to approval by the immigration authority and we do not take guarantee of
                approval.
              </li>
              <li> For collection of Visa, please bring the original hardcopy receipt. </li>
            </ul>
          </div>
        </section>
      </div>
      <section className=' align_center '>
        <div style={{ margin: '20px 0' }}>
          <ReactToPrint trigger={() => <MainButton>Print</MainButton>} content={() => printDiv} />
        </div>
      </section>
    </div>
  );
};

export default MiscInvoicePrint;
