import { Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { TableStyle } from '../../../uiComponents';
import LoaderTwo from '../../../uiComponents/Loader/LoaderTwo';
import MiscStatementRow from './MiscStatementRow';

const { StyledTableRow, StyledTableCellTranparent } = TableStyle;
const MiscStamentsTable = () => {
  const reduxState = useSelector(({ statements }) => {
    return {
      miscData: statements.miscData,
      loading: statements.miscLoading,
    };
  });
  let { miscData, loading } = reduxState;

  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 'max-content', marginBottom: '20px' }} aria-label='simple table'>
          <TableHead sx={{ borderBottom: '2px solid #EBEFF2' }}>
            <StyledTableRow>
              <StyledTableCellTranparent sx={{ width: '100px' }}>Invoice No.</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '110px' }}>
                Invoice Date
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Client Name</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Cash (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Cheque (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Nets (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Paynow (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Credit Card (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>TOTAL (S$)</StyledTableCellTranparent>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              miscData?.data?.length > 0 ? (
                miscData?.data.map((data, index) => (
                  <MiscStatementRow key={data?.id + index} invoiceId={data?.id} data={data} />
                ))
              ) : null
            ) : (
              <StyledTableRow>
                <TableCell colSpan={2}></TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '80px 0px' }}
                >
                  <LoaderTwo />
                </TableCell>
                <TableCell colSpan={3}></TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MiscStamentsTable;
