import React from "react";
import CurrencyFormat from "react-currency-format";
function CurrencyFormatter({ price = 0 }) {
  return (
    <CurrencyFormat
      renderText={(value) => value}
      decimalScale={2}
      value={price || 0}
      displayType={"text"}
      thousandSeparator={true}
      prefix={""}
      decimalSeparator={"."}
    />
  );
}

export default CurrencyFormatter;
