import { Button } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CommonHeading from '../../Components/VisaInvoice/CommonHeading';
import VisaInvoiceTable from '../../Components/VisaInvoice/Invoice/VisaInvoiceTable';
import '../../Components/VisaInvoice/styles/VisaInvoice.css';
import { CommonGreenHeading, ErrorAlert, LoaderOne } from '../../uiComponents';
import { AllButton } from '../../uiComponents';
import RectSelect from '../../uiComponents/Select/RectSelect';
import { Country, City } from 'country-state-city';
import { useDispatch, useSelector } from 'react-redux';
import VisaInvoiceConstant from '../../store/constants/VisaInvoice.constants';
import { useAlert } from 'react-alert';
import { getInvoiceDetail, updateInvoiceDetails } from '../../store/action/VisaInvoice';
import { passportValidate, visaInvoiceValidate } from '../../utils/validator';
import PassportStatus from '../../utils/Constants/passportStatus.constants';
import { get } from '../../utils/methods';
import PaymentMethodList from '../../utils/Constants/PaymentMethods.constants';

const { FullSecondButton } = AllButton;
const DashedButton = styled(Button)(({ theme }) => ({
  color: '#000',
  margin: '0px',
  fontFamily: 'Nunito',
  textTransform: 'capitalize',
  whiteSpace: ' nowrap',
  fontWeight: 700,
  border: '1px dashed black',
  backgroundColor: '#fff !important',
  fontSize: '14px',
  [theme.breakpoints.up('md')]: {
    padding: '2px 40px 2px 35px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '2px 35px 2px 25px',
  },
  '&:hover': {
    backgroundColor: '#fffff',
  },
  '&:active': {
    backgroundColor: '#fffff',
  },
}));

const VisaInvoiceEdit = () => {
  let countries = Country.getAllCountries();
  const navigate = useNavigate();
  const alert = useAlert();
  const [searchParams] = useSearchParams();
  const invoiceid = searchParams.get('invoiceId');
  const dispatch = useDispatch();
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      invoiceDetails: visaInvoice.invoiceDetails,
      loading: visaInvoice.detailsLoading,
      passData: visaInvoice.passportDetails,
    };
  });
  let { invoiceDetails, loading, passData } = reduxState;
  let personalDetails = invoiceDetails?.personalDetail;

  useEffect(() => {
    dispatch(getInvoiceDetail({ invoiceId: invoiceid }));
  }, []);

  const initialState = {
    clientName: personalDetails?.name || '',
    mobileNumber: personalDetails?.mobile || '',
    mobile1: personalDetails?.mobile1 || '',
    consultantName: personalDetails?.consultantName || '',
    address: personalDetails?.address || '',
    pinCode: personalDetails?.pinCode || '',
    emailAddress: personalDetails?.email || '',
    passportNumber: '',
    passportExpiry: '1900/01/01',
    memberName: '',
    typeOfPass: '',
    passExpiry: '',
    comments: invoiceDetails?.comments || '',
    agentFee: 0,
    skyServicefee: 0,
    visaFee: 0,
  };
  const [values, setValues] = useState(initialState);
  const {
    clientName,
    mobileNumber,
    mobile1,
    consultantName,
    address,
    pinCode,
    emailAddress,
    passportNumber,
    passportExpiry,
    memberName,
    typeOfPass,
    passExpiry,
    comments,
    agentFee,
    skyServicefee,
    visaFee,
  } = values;

  const [errors, seterrors] = useState({});
  const [passportError, setpassportError] = useState({});
  const [gender, setgender] = useState({ key: '', label: 'Select gender', value: 'M' });
  const [city, setcity] = useState({
    key: personalDetails?.city,
    label: personalDetails?.city,
    value: personalDetails?.city,
  });
  const [country, setcountry] = useState({
    key: personalDetails?.country,
    label: personalDetails?.country,
    value: personalDetails?.country,
  });
  const [visaForCountry, setvisaForCountry] = useState({ key: '', label: 'Select visa country', value: '' });
  const [nationality, setnationality] = useState({ key: '', label: 'Select nationality', value: '' });
  const [totalfee, settotalfee] = useState(0);
  const [grandTotal, setgrandTotal] = useState(0);
  const [agentFeeGST, setagentFeeGST] = useState(0);
  const [skyServicefeeGST, setskyServicefeeGST] = useState(0);
  const [total, settotal] = useState(0);
  const [balance, setbalance] = useState(0);
  const [amountList, setamountList] = useState([0]);
  const [refrenceIdList, setrefrenceIdList] = useState(['']);
  const [gstRate, setgstRate] = useState(7);
  const [paymentList, setpaymentList] = useState([
    {
      amountPaid: 0,
      paymentType: '',
    },
  ]);
  const [paymentListError, setpaymentListError] = useState({});

  useEffect(() => {
    if (invoiceDetails) {
      setValues({
        clientName: personalDetails?.name,
        mobileNumber: personalDetails?.mobile,
        mobile1: personalDetails?.mobile1 || '',
        consultantName: personalDetails?.consultantName || '',
        comments: invoiceDetails?.comments,
      });

      setcountry({
        key: personalDetails?.country,
        label: personalDetails?.country,
        value: personalDetails?.country,
      });
      setcity({
        key: personalDetails?.city,
        label: personalDetails?.city,
        value: personalDetails?.city,
      });
      const tempList = invoiceDetails?.paymentDetails?.map(({ paymentMethod, paidAmount }) => paidAmount);
      const tempPaymentList = invoiceDetails?.paymentDetails?.map(({ paymentMethod, paidAmount }) => {
        return { amountPaid: paidAmount, paymentType: paymentMethod };
      });
      const tempRefList = invoiceDetails?.paymentDetails?.map(({ referenceNumber }) => referenceNumber);
      setrefrenceIdList(tempRefList);
      setpaymentList(tempPaymentList);
      setamountList(tempList);
      settotal(parseFloat(invoiceDetails?.totalAmount).toFixed(2));
    }
    return () => {};
  }, [invoiceDetails]);

  useEffect(() => {
    if (agentFee) {
      let gstAmount = (agentFee * gstRate) / (100 + gstRate);
      gstAmount = gstAmount.toFixed(2);
      setagentFeeGST(Number(gstAmount));
    } else {
      setagentFeeGST(0);
    }
    return () => {};
  }, [agentFee]);

  useEffect(() => {
    if (skyServicefee) {
      let gstAmount = (skyServicefee * gstRate) / (100 + gstRate);
      gstAmount = gstAmount.toFixed(2);
      setskyServicefeeGST(Number(gstAmount));
    } else if (!skyServicefee) {
      setskyServicefeeGST(0);
    }
    return () => {};
  }, [skyServicefee]);

  useEffect(() => {
    let total = Number(skyServicefee) + Number(visaFee) + Number(agentFee);
    total = total.toFixed(2);
    settotalfee(total);
    return () => {};
  }, [skyServicefee, visaFee, agentFee]);

  useEffect(() => {
    if (!passData) return;
    if (passData?.length === 0) return;
    var reducedArray = passData.reduce(function (accumulator, item) {
      accumulator = Number(accumulator) + Number(item['visaFee']);
      accumulator = Number(accumulator) + Number(item['agentFee']);
      accumulator = Number(accumulator) + Number(item['skyServicefee']);
      return Number(accumulator);
    }, 0);
    setgrandTotal(reducedArray);
    setbalance((reducedArray - total).toFixed(2));
    return () => {};
  }, [passData]);

  useEffect(() => {
    return () => {
      dispatch({
        type: VisaInvoiceConstant.SET_PASSPORT_DATA,
        passportDetails: [],
      });
    };
  }, []);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleChangePaymentMethod = (e, index, amountPaid = '') => {
    let newArray = [...paymentList];
    newArray[index] = { amountPaid, paymentType: e.value };
    setpaymentList(newArray);
  };
  const handleChangePayment = (enterAmount, index) => {
    let newArray = [...amountList];
    newArray.splice(parseInt(index), 1, enterAmount);
    var reducedArray = newArray.reduce(function (accumulator, item) {
      accumulator = accumulator + Number(item);
      return accumulator;
    }, 0);
    const newPaymentTotal = reducedArray;
    const newBalance = grandTotal - newPaymentTotal;
    if (newBalance < 0) return;
    setamountList(newArray);
    settotal(reducedArray);
    setbalance(newBalance.toFixed(2));
  };
  const handleChangeReferenceId = (refId, index) => {
    let tempRefList = [...refrenceIdList];
    tempRefList.splice(parseInt(index), 1, refId);
    setrefrenceIdList(tempRefList);
  };
  const handleAddPaymentMethod = () => {
    if (paymentList.length === PaymentMethodList.length) return;
    setpaymentList([...paymentList, { amountPaid: 0, paymentType: '' }]);
    setamountList([...amountList, 0]);
    setrefrenceIdList([...refrenceIdList, '']);
  };
  const handleRemovePaymentMethod = (index) => {
    const paymentMethodArr = [...paymentList];
    paymentMethodArr.splice(index, 1);
    let newArray = [...amountList];
    newArray.splice(index, 1);
    setpaymentList(paymentMethodArr);
    setamountList(newArray);
    // refrence id list
    let newRefList = [...refrenceIdList];
    newRefList.splice(index, 1);
    setrefrenceIdList(newRefList);
    var reducedArray = newArray.reduce(function (accumulator, item) {
      accumulator = accumulator + item;
      return accumulator;
    }, 0);
    let tempObject = { ...paymentListError };
    delete tempObject[index];
    setpaymentListError(tempObject);
    settotal(reducedArray);
    setbalance(grandTotal - reducedArray);
  };

  const checkAvailability = async (e) => {
    // try {
    e.preventDefault();
    let validationValues = {
      memberName,
      passportNumber,
      gender: gender?.value,
      nationality: nationality?.value,
      passExpiry,
      passportExpiry,
      typeOfPass,
      visaFee,
      agentFee,
      agentFeeGST,
      skyServicefee,
      skyServicefeeGST,
      totalfee,
    };
    setpassportError(passportValidate(validationValues));
    if (Object.values(passportValidate(validationValues))?.length != 0) return;
    // const res = await get(`/check{passportNumber}?passportNumber=${passportNumber}`);
    // const data = res?.data;
    // if (data?.status == 'failure') {
    //   alert.error(data?.message);
    //   return;
    // }
    addPassportData();
    // } catch (err) {
    //   if (err?.response?.data) {
    //     alert.error(err?.response?.data?.message);
    //   } else {
    //     console.log('check availability', err);
    //   }
    // }
  };
  const addPassportData = (e) => {
    let passportObject = {
      passportNumber: passportNumber.toUpperCase(),
      name: memberName.toUpperCase(),
      gender: gender.value.toUpperCase(),
      nationality: nationality.value.toUpperCase(),
      passExpiry: passExpiry,
      passportExpiry: passportExpiry,
      typeOfPass: typeOfPass.toUpperCase(),
      visaForCountry: visaForCountry.value.toUpperCase(),
      status: PassportStatus.PENDING,
      visaFee,
      agentFee,
      agentFeeGST,
      skyServicefee: skyServicefee,
      skyServicefeeGST,
    };
    let isAlready = false;
    passData.forEach((element) => {
      if (element.passportNumber == passportNumber.toUpperCase()) {
        isAlready = true;
      }
    });
    if (isAlready) {
      alert.info('passport number already added');
    } else {
      dispatch({
        type: VisaInvoiceConstant.SET_PASSPORT_DATA,
        passportDetails: [...passData, passportObject],
      });
      setValues({
        ...values,
        passportNumber: '',
        memberName: '',
        passExpiry: '',
        passportExpiry: '',
        typeOfPass: '',
        visaFee: '',
        agentFee: '',
        skyServicefee: '',
      });
      setskyServicefeeGST('');
      setagentFeeGST('');
      setgender({ key: '', label: 'Select gender', value: '' });
      setnationality({ key: '', label: 'Select nationality', value: '' });
      setvisaForCountry({ key: '', label: 'Select visa country', value: '' });
    }
  };

  const addInvoiceData = async (e) => {
    e.preventDefault();
    let validationValues = {
      clientName,
      mobileNumber,
      address,
      country: country?.value,
      city: city?.value,
      pinCode,
      emailAddress,
      passports: passData,
      consultantName
    };
    let isError = false;
    let mainTempError = { ...paymentListError };
    paymentList.forEach((element, index) => {
      let tempObject = { ...mainTempError };
      tempObject[index] = {
        amountPaid: '',
        paymentType: '',
      };
      if (amountList[index] === '') {
        tempObject[index].amountPaid = 'Either enter payment or remove it';
        isError = true;
      }
      if (element.paymentType === '') {
        tempObject[index].paymentType = 'Either select method or remove it';
        isError = true;
      }
      if ((amountList[index] !== '') && element.paymentType !== '') {
        delete tempObject[index];
      }
      mainTempError = { ...tempObject };
    });
    setpaymentListError(mainTempError);
    seterrors(visaInvoiceValidate(validationValues));
    console.log(Object.values(visaInvoiceValidate(validationValues)).length != 0, isError);
    if (Object.values(visaInvoiceValidate(validationValues)).length != 0 || isError) return;
    if (balance > 0) return alert.error('Balance Amount should be zero');

    let body = {
      clientInfo: {
        name: clientName.toUpperCase(),
        newMobile: mobileNumber,
        currentMobile: personalDetails?.mobile,
        mobile1: mobile1,
        consultantName: consultantName,
        address: address?.toUpperCase(),
        country: country?.value?.toUpperCase(),
        city: city?.value?.toUpperCase(),
        pinCode,
        email: emailAddress?.toLowerCase(),
      },
      passportInfo: passData,
      invoiceId: invoiceid,
      comments,
      charges: paymentList.map((element, index) => {
        return {
          amountPaid: amountList[index],
          paymentType: element.paymentType,
          referenceNumber: refrenceIdList[index] || null,
        };
      }),
    };
    dispatch(updateInvoiceDetails(body, addInvoiceCallback));
  };

  const addInvoiceCallback = ({ error, message, data }) => {
    if (error) {
      message?.error && alert.error(message?.error);
      return;
    }
    if (data?.status == 'failure') {
      data?.message && alert.error(data?.message);
      return;
    }
    dispatch({
      type: VisaInvoiceConstant.SET_PASSPORT_DATA,
      passportDetails: [],
    });
    alert.success('Invoice updated Successfully');
    navigate(`/visa-invoice-review?invoiceId=${invoiceid}`);
  };
  const handleEditPassport = (data) => {
    setValues({
      passportNumber: data.passport_no,
      memberName: data.name,
      passExpiry: '',
      passportExpiry: '',
      typeOfPass: '',
      visaFee: data.visaFee,
      agentFee: data.agentFee,
      skyServicefee: data.skyServicefee,
      clientName,
      mobileNumber,
      address,
      pinCode,
      emailAddress,
      comments,
      consultantName
    });
    setagentFeeGST(data.agentFeeGST);
    setskyServicefeeGST(data.skyServicefeeGST);
    setnationality({ key: 'kbwda', label: data.nationality, value: data.nationality });
    let copyArray = passData.filter((prevData) => prevData.passportNumber != data.passport_no);
    dispatch({
      type: VisaInvoiceConstant.SET_PASSPORT_DATA,
      passportDetails: copyArray,
    });
  };
  return (
    <div className='visa_invoice'>
      {/* client details section start */}
      <section>
        <CommonHeading heading={'Visa Invoice Edit'} tag={'Please add details of your clients'} />
      </section>
      <section style={{marginTop:"20px"}}>
        <h3>Invoice No: <span>{invoiceid}</span></h3>
      </section>
      {/* header section end */}
      <div className='visa_invoice_second'>
        {/* client details section start */}
        <section className='visa_invoice_details_div' style={{ marginBottom: '50px' }}>
          <section className='visa_invoice_input_div'>
            <h5>Client Name</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='text'
                placeholder='Client Name'
                value={clientName}
                name={'clientName'}
                onChange={handleChangeInput}
              />
            </div>
            {errors?.clientName && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={errors?.clientName} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Mobile Number</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='text'
                placeholder='Mobile Number'
                value={mobileNumber}
                name={'mobileNumber'}
                onChange={handleChangeInput}
              />
            </div>
            {errors?.mobileNumber && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={errors?.mobileNumber} />
              </div>
            )}
          </section>{' '}
          <section className='visa_invoice_input_div'>
            <h5>Mobile Number (Optional 1)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='number'
                placeholder='Mobile 1'
                value={mobile1}
                name={'mobile1'}
                onChange={handleChangeInput}
              />
            </div>
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Consultant name</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='text'
                placeholder='Consultant name'
                value={consultantName}
                name={'consultantName'}
                onChange={handleChangeInput}
              />
            </div>
            {errors?.consultantName && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={errors?.consultantName} />
              </div>
            )}
          </section>
        </section>
        <CommonGreenHeading heading={'Passport Details'} />
        {/* client details section end */}
        {/* members details section start */}
        <section className='visa_invoice_details_div'>
          <section className='visa_invoice_input_div'>
            <h5>Name</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='text'
                placeholder='Client Name'
                value={memberName}
                name={'memberName'}
                onChange={handleChangeInput}
              />
            </div>
            {passportError?.memberName && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={passportError?.memberName} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Passport Number</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='text'
                placeholder='Passport Number'
                value={passportNumber}
                name={'passportNumber'}
                onChange={handleChangeInput}
              />
            </div>
            {passportError?.passportNumber && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={passportError?.passportNumber} />
              </div>
            )}
          </section>

          <section className='visa_invoice_input_div'>
            <h5>Nationality</h5>
            <div style={{ marginTop: '5px' }}>
              <RectSelect
                defaultValue={nationality}
                data={[
                  {
                    key: 'indaiNew2',
                    value: 'india',
                    label: 'india',
                  },
                  {
                    key: 'chinaNew2',
                    value: 'china',
                    label: 'china',
                  },
                  {
                    key: 'bangladeshNew2',
                    value: 'bangladesh',
                    label: 'bangladesh',
                  },
                  {
                    key: 'pakistanNew2',
                    value: 'pakistan',
                    label: 'pakistan',
                  },
                  {
                    key: 'myanmarNew2',
                    value: 'myanmar',
                    label: 'myanmar',
                  },
                  {
                    key: 'srilankaNew2',
                    value: 'sri lanka',
                    label: 'sri lanka',
                  },
                  ...countries.map(({ name, flag, isoCode }) => ({
                    key: isoCode,
                    value: name,
                    label: name,
                  })),
                  {
                    key: 'other2',
                    value: 'other',
                    label: 'other',
                  },
                  {
                    key: 'stateless',
                    value: 'stateless',
                    label: 'stateless',
                  },
                ]}
                callback={setnationality}
              />
            </div>
            {passportError?.nationality && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={passportError?.nationality} />
              </div>
            )}
          </section>

          <section className='visa_invoice_input_div'>
            <h5>Visa Fee (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='number'
                placeholder='Visa Fee'
                value={visaFee}
                name={'visaFee'}
                onChange={handleChangeInput}
              />
            </div>
            {passportError?.visaFee && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={passportError?.visaFee} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Agent Fee (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='number'
                placeholder='Agent Fee'
                value={agentFee}
                name={'agentFee'}
                onChange={handleChangeInput}
              />
            </div>
            {passportError?.agentFee && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={passportError?.agentFee} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Agent Fee GST (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                disabled={true}
                type='number'
                placeholder='Agent Fee GST'
                value={agentFeeGST}
                name={'agentFeeGST'}
                onChange={handleChangeInput}
              />
            </div>
            {passportError?.agentFeeGST && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={passportError?.agentFeeGST} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Sky travel Fee (S$) </h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='number'
                placeholder='Sky travel Fee'
                value={skyServicefee}
                name={'skyServicefee'}
                onChange={handleChangeInput}
              />
            </div>
            {passportError?.skyServicefee && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={passportError?.skyServicefee} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Sky travel GST (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                disabled
                type='text'
                placeholder='Sky travel GST'
                value={skyServicefeeGST}
                name={'skyServicefeeGST'}
                onChange={handleChangeInput}
              />
            </div>
            {passportError?.skyServicefeeGST && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={passportError?.skyServicefeeGST} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Total Fee (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                disabled
                type='number'
                placeholder='Total Fee'
                value={totalfee}
                name={'totalfee'}
                onChange={(e) => settotalfee(e.target.value)}
              />
            </div>
            {passportError?.skyServicefeeGST && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={passportError?.skyServicefeeGST} />
              </div>
            )}
          </section>
        </section>
        <section style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
          <DashedButton onClick={checkAvailability}> +Add to List </DashedButton>
        </section>
        {/* members details section end */}
      </div>

      {/* passport details section start */}
      <section style={{ marginBottom: '40px' }}>
        <VisaInvoiceTable callback={(data) => handleEditPassport(data)} />
        {errors?.passports && (
          <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
            <ErrorAlert error={errors?.passports} />
          </div>
        )}
      </section>
      <CommonGreenHeading heading={'Charges'} />
      {/* passport details section end */}
      {/* charges and commonets details section start */}
      <section>
        <section className='visa_invoice_input_div'>
          <h5>Grand Total (S$)</h5>
          <div className='visa_invoice_input_inner'>
            <input
              disabled
              type='number'
              placeholder='Total '
              value={grandTotal}
              name={'grandTotal'}
              onChange={(e) => setgrandTotal(e.target.value)}
            />
          </div>
        </section>
        {paymentList?.map(({ amountPaid, paymentType }, index) => (
          <div key={amountPaid + paymentType + index} className='visa_invoice_details_div'>
            <section className='visa_invoice_input_div' style={{ width: '230px' }}>
              <h5>Payment Method</h5>
              <div style={{ marginTop: '5px' }}>
                <RectSelect
                  defaultValue={{ key: index + amountPaid, label: paymentType || 'Select', value: paymentType }}
                  data={PaymentMethodList}
                  callback={(e) => handleChangePaymentMethod(e, index)}
                />
              </div>
              {paymentListError[index] && paymentListError[index].paymentType && (
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                  <ErrorAlert error={paymentListError[index].paymentType} />
                </div>
              )}
            </section>
            <section className='visa_invoice_input_div' style={{ width: '220px' }}>
              <h5>Paid Amount (S$)</h5>
              <div className='visa_invoice_input_inner'>
                <input
                  type='number'
                  placeholder='Total '
                  value={amountList[index]}
                  onChange={(e) => handleChangePayment(e.target.value, index)}
                />
              </div>
              {paymentListError[index] && paymentListError[index].amountPaid && (
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                  <ErrorAlert error={paymentListError[index].amountPaid} />
                </div>
              )}
            </section>
            {paymentType !== 'CASH' && (
              <section className='visa_invoice_input_div' style={{ width: '220px' }}>
                <h5>Reference Id</h5>
                <div className='visa_invoice_input_inner'>
                  <input
                    type='text'
                    placeholder='Reference ID '
                    value={refrenceIdList[index]}
                    onChange={(e) => handleChangeReferenceId(e.target.value, index)}
                  />
                </div>
                {paymentListError[index] && paymentListError[index]?.refrenceId && (
                  <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                    <ErrorAlert error={paymentListError[index]?.refrenceId} />
                  </div>
                )}
              </section>
            )}
            {paymentList?.length !== 1 && (
              <section className='visa_invoice_input_div' style={{ marginTop: '55px' }}>
                <DashedButton sx={{ py: 50 }} onClick={() => handleRemovePaymentMethod(index)}>
                  - Remove
                </DashedButton>
              </section>
            )}
          </div>
        ))}
        {paymentList?.length !== PaymentMethodList.length && (
          <section className='visa_invoice_input_div' style={{ marginTop: '55px' }}>
            <DashedButton sx={{ py: 50 }} onClick={handleAddPaymentMethod}>
              +Add Payment Option{' '}
            </DashedButton>
          </section>
        )}
        <section style={{ display: 'flex', flexWrap: 'wrap' }}>
          <section className='visa_invoice_input_div'>
            <h5>Total (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                disabled
                type='number'
                placeholder='Total '
                value={total}
                name={'total'}
                onChange={(e) => settotal(e.target.value)}
              />
            </div>
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Balance (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                disabled
                type='number'
                placeholder='Balance '
                value={balance}
                name={'balance'}
                onChange={(e) => setbalance(e.target.value)}
              />
            </div>
          </section>
        </section>
        <section>
          {errors?.totalError && (
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
              <ErrorAlert error={errors?.totalError} />
            </div>
          )}
        </section>
      </section>
      {/* charges and commonets details section end */}
      <section className='visa_invoice_comments'>
        <textarea
          type='text'
          placeholder='Add comments(if any)'
          value={comments}
          name='comments'
          onChange={handleChangeInput}
        />
      </section>
      {Object.values(errors).length != 0 ||
        (Object.values(paymentListError).length !== 0 && (
          <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
            <ErrorAlert error={'Please resolve all errors'} />
          </div>
        ))}
      <section style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
        <div style={{ minWidth: '280px' }}>
          {loading ? (
            <FullSecondButton>
              <LoaderOne />
            </FullSecondButton>
          ) : (
            <FullSecondButton onClick={addInvoiceData}>Update </FullSecondButton>
          )}
        </div>
      </section>
    </div>
  );
};

export default VisaInvoiceEdit;
