import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F9F6DF',
    color: theme.palette.common.black,
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase',
    fontFamily: 'Nunito',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    fontWeight: 600,
    color: '#000',
    fontFamily: 'Nunito',
    textTransform: 'uppercase',
  },
}));
const StyledTableCellTranparent = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F0F0F0',
    color: theme.palette.common.black,
    fontSize: 13,
    fontWeight: 700,
    textTransform: 'uppercase',
    fontFamily: 'Nunito',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    fontWeight: 600,
    color: '#000',
    fontFamily: 'Nunito',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F2FBFF',
    fontFamily: 'Nunito',
  },
  //   hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    fontFamily: 'Nunito',
  },
  [`&:hover`]: {
    backgroundColor: '#F0F0F0',
    color: '#000 !important',
  },
}));

const GreyTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#EBEFF2',
  },
}));

export default { StyledTableRow, StyledTableCell, StyledTableCellTranparent, GreyTableRow };
