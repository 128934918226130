import React, { useEffect, useState } from "react";
import TrackDataItem from "../../Components/VisaInvoice/TrackDataItem";
import CurrentIcon from "./CurrentIcon";
import "./TrackBarStyle.css";
const TrackBar = ({ parsePercentage, trackMessage, comments, status }) => {
  let milestone = trackMessage.length - 1;
  let screenWidth = window.innerWidth;
  const [myWidth, setmyWidth] = useState(0);
  let percentage = 100 < parsePercentage ? 100 : parsePercentage;
  let progress_bar_main = {
    position: "relative",
    padding: " 5px 5px",
    height: "35px",
    justifyContent: "space-between",
    backgroundColor: "#ecf2f8",
    borderRadius: "30px",
    width: "100%",
  };
  let progress_bar_innner = {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: "#528DC8",
    height: "100%",
    transition: "2s width ease-in-out",
    borderRadius: "30px",
  };
  let progress_milestone = {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: "white",
    position: "absolute",
    top: "50%",
    zIndex: 10,
    transform: "translate(-50%, -50%)",
  };
  let current_style = {
    position: "absolute",
    top: "52%",
    zIndex: 10,
    transform: "translate(-50%, -45%)",
  };
  let remaining_style = {
    position: "absolute",
    top: "50%",
    zIndex: 10,
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    padding: "3px",
    backgroundColor: "#528DC8",
    borderRadius: "50%",
  };
  let remaining_inner_div_style = {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    backgroundColor: "white",
  };
  let milestoneData = [];
  let trackMessageData = [];

  for (let index = 0; index < milestone; index++) {
    let milePercentage = Math.floor(((index + 1) / milestone) * 100);
    let isCurrent = milePercentage === percentage;
    let isCompleted = milePercentage < percentage;
    let isRemaining = isCompleted || isCurrent ? false : true;
    milestoneData.push({
      message: "",
      isCurrent,
      isRemaining,
      isCompleted,
      position: milePercentage,
    });
  }
  // track data message loop
  for (let index = 0; index < trackMessage.length; index++) {
    let milePercentage = Math.floor((index / milestone) * 100);
    let isCurrent = milePercentage === percentage;
    let isCompleted = milePercentage < percentage;
    let isRemaining = isCompleted || isCurrent ? false : true;
    trackMessageData.push({
      message: trackMessage[index].message,
      date: trackMessage[index].date,
      isRemaining,
      isCompleted,
      isCurrent,
    });
  }
  useEffect(() => {
    if (percentage == 0) {
      setmyWidth(percentage + 4);
    } else {
      setmyWidth(percentage);
    }
  }, [percentage]);

  let leftPostion = (position) => {
    if (position === 100) {
      if (screenWidth < 1000 && screenWidth > 767) {
        return "97%";
      }
      if (screenWidth < 767) {
        return "96%";
      }
      return "98%";
    } else {
      if (screenWidth < 1000 && screenWidth > 767) {
        return `${position - 1}%`;
      }
      if (screenWidth < 767) {
        return `${position - 2}%`;
      }
      return `${position}%`;
    }
  };
  return (
    <div className="track_bar_main_div">
      <div style={progress_bar_main}>
        <div
          style={{
            ...progress_bar_innner,
            width: myWidth === 100 ? `${myWidth}%` : `${myWidth + 2}%`,
            opacity: 1,
          }}
        />
        {milestoneData.map(({ message, isCurrent, isRemaining, isCompleted, position }) => (
          <div key={position}>
            {isCompleted && <div style={{ ...progress_milestone, left: position === 100 ? "98%" : `${position}%` }} />}
            {isCurrent && (
              <div className="" style={{ ...current_style, left: leftPostion(position) }}>
                <CurrentIcon />
              </div>
            )}
            {percentage == 0 && (
              <div className="" style={{ ...current_style, left: leftPostion(3) }}>
                <CurrentIcon />
              </div>
            )}

            {isRemaining && (
              <div style={{ ...remaining_style, left: leftPostion(position) }}>
                <div style={{ ...remaining_inner_div_style }} />
              </div>
            )}
          </div>
        ))}
      </div>

      <section style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
        {trackMessageData.map(({ message, isCurrent, isRemaining, isCompleted, date }, index) => (
          <TrackDataItem
            key={message}
            data={message}
            date={date}
            isCurrent={isCurrent}
            isCompleted={isCompleted}
            isRemaining={isRemaining}
            isLast={index === trackMessageData.length - 1}
            comments={comments}
            status={status}
          />
        ))}
      </section>
    </div>
  );
};

export default TrackBar;
