import React from "react";

const CommonHeading = ({ heading, tag }) => {
  return (
    <div className="common_heading">
      <h2>{heading}</h2>
    </div>
  );
};

export default CommonHeading;
