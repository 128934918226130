import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisaInvoiceConstant from '../../../store/constants/VisaInvoice.constants';
import { TableStyle } from '../../../uiComponents';
import LoaderTwo from '../../../uiComponents/Loader/LoaderTwo';
import PassportStatus from '../../../utils/Constants/passportStatus.constants';
import PassportReturnRow from '../Return/PassportReturnRow';

const { StyledTableRow, StyledTableCellTranparent } = TableStyle;
const PassportReturnTable = () => {
  const dispatch = useDispatch();
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      passData: visaInvoice?.returnList,
      loading: visaInvoice.returnListLoading,
      returnPassports: visaInvoice.returnPassports,
    };
  });
  let { passData, loading, returnPassports } = reduxState;
  const [checked, setchecked] = useState(false);
function arrayEquals(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}
  const addPassports = () => {
    let tempArray = [];
    passData.passports?.forEach((element) => {
      tempArray.push(element.number);
    });
    console.log(arrayEquals(returnPassports, tempArray));
    if (!arrayEquals(returnPassports, tempArray)) {
      dispatch({
        type: VisaInvoiceConstant.ADD_PASSPORT_RETURN,
        returnPassports: tempArray,
      });
      setchecked(true);
    } else {
      dispatch({
        type: VisaInvoiceConstant.ADD_PASSPORT_RETURN,
        returnPassports: [],
      });
      setchecked(false);
    }
  };

  
  return (
    <div>
      {' '}
      <TableContainer>
        <Table sx={{ minWidth: 650, marginBottom: '20px' }} aria-label='simple table'>
          <TableHead sx={{ borderBottom: '2px solid #EBEFF2' }}>
            <StyledTableRow>
            <StyledTableCellTranparent>Invoice</StyledTableCellTranparent>
              <StyledTableCellTranparent>Passport</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Name</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>
                Return <br /> Status
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '100px' }}>
                Select <Checkbox checked={checked} onChange={addPassports} />{' '}
              </StyledTableCellTranparent>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              passData?.passports?.length > 0 &&
              passData?.passports?.map(
                ({
                  id,
                  invoiceId,
                  gender,
                  name,
                  nationality,
                  number,
                  passExpiry,
                  passportExpiry,
                  typeOfPass,
                  visaForCountry,
                  status,
                  comments,
                }) => (
                  <PassportReturnRow
                    key={id + number}
                    id={invoiceId}
                    passport_no={number}
                    passport_expiry_date={passportExpiry}
                    name={name}
                    gender={gender}
                    nationality={nationality}
                    type_of_pass={typeOfPass}
                    pass_expiry={passExpiry}
                    visa_for_country={visaForCountry}
                    comments={comments}
                    return_status={status}
                  />
                )
              )
            ) : (
              <StyledTableRow>
                <TableCell colSpan={3}></TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '80px 0px' }}
                >
                  <LoaderTwo />
                </TableCell>
                <TableCell colSpan={3}></TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PassportReturnTable;
