import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisaInvoiceConstant from '../../../store/constants/VisaInvoice.constants';
import { TableStyle } from '../../../uiComponents';
import LoaderTwo from '../../../uiComponents/Loader/LoaderTwo';
import PassportSubmissionRow from './PassportSubmissionRow';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const { StyledTableRow, StyledTableCellTranparent } = TableStyle;
const PassportSubmissionTable = () => {
  const dispatch = useDispatch();
  const [checked, setchecked] = useState(false);
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      passData: visaInvoice?.submissionList,
      loading: visaInvoice.submissionListLoading,
      submissionPassports: visaInvoice.submissionPassports,
      submissionPassportList: visaInvoice.submissionPassportList,
    };
  });
  let { passData, loading, submissionPassportList } = reduxState;

  const addPassports = () => {
    if (submissionPassportList != passData.passports) {
      let tempArray = [];
      passData.passports?.forEach((element) => {
        tempArray.push(element.number);
      });

      dispatch({
        type: VisaInvoiceConstant.ADD_PASSPORT_SUBMISSION,
        submissionPassports: tempArray,
        submissionPassportList: passData.passports,
      });
      setchecked(true);
    } else {
      dispatch({
        type: VisaInvoiceConstant.ADD_PASSPORT_SUBMISSION,
        submissionPassports: [],
        submissionPassportList: [],
      });
      setchecked(false);
    }
  };
  useEffect(() => {
    if (submissionPassportList?.length !== 0) return;
    setchecked(false);
    return () => {};
  }, [submissionPassportList]);

  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 650, marginBottom: '20px' }} aria-label='simple table'>
          <TableHead sx={{ borderBottom: '2px solid #EBEFF2' }}>
            <StyledTableRow>
              <StyledTableCellTranparent sx={{ width: '80px' }}>PASSPORTS</StyledTableCellTranparent>

              <StyledTableCellTranparent align='center' sx={{ width: '220px' }}>
                Name
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '220px' }}>
                Nationality
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '100px' }}>
                Select <Checkbox {...label} checked={checked} onChange={addPassports} />{' '}
              </StyledTableCellTranparent>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              passData?.passports?.length > 0 &&
              passData?.passports?.map((data, index) => (
                <PassportSubmissionRow key={data.id + data.number + index} data={data} />
              ))
            ) : (
              <StyledTableRow>
                <TableCell colSpan={3}></TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '80px 0px' }}
                >
                  <LoaderTwo />
                </TableCell>
                <TableCell colSpan={3}></TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PassportSubmissionTable;
