import { Pagination, PaginationItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CommonHeading from '../../Components/VisaInvoice/CommonHeading';
import '../../Components/VisaInvoice/styles/VisaInvoice.css';
import { AllButton, LoaderOne } from '../../uiComponents';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import SubmissionListTable from '../../Components/Passport/SubmissionList/SubmissionListTable';
import { getSubmittedList } from '../../store/action/VisaInvoice';
import LoaderTwo from '../../uiComponents/Loader/LoaderTwo';
import PassportSearch from '../../Components/Passport/PassportSearch';
import { format } from 'date-fns';
import useOnClickOutsideRef from '../../utils/outsideClick';
import ExportIcon from '../../uiComponents/Icons/ExportIcon';
import { DateRangePicker } from 'react-date-range';
import { FaExchangeAlt } from 'react-icons/fa';
import { useAlert } from 'react-alert';
import SubmissionListExport from '../../Components/Passport/SubmissionList/SubmissionListExport';
import { get } from '../../utils/methods';
const { FullSecondButton, BorderSecondButton } = AllButton;

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: '#528DC8 !important',
    color: '#fff !important',
  },
}));

const SubmissionList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useAlert();
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      loading: visaInvoice.latestSubmittedLoading,
      passData: visaInvoice.latestSubmissions,
    };
  });
  let { passData, loading } = reduxState;
  let screenWidth = window.innerWidth;
  const [page, setpage] = useState(1);
  const [search, setsearch] = useState('');
  const [showFilter, setshowFilter] = useState(false);
  const [sheetLoader, setsheetLoader] = useState(false);
  const [dateRange, setdateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  let modalRef = useOnClickOutsideRef(showFilter, () => setshowFilter(false));

  useEffect(() => {
    dispatch(getSubmittedList({ pageNo: page, search: search.toUpperCase() }));
  }, []);
  useEffect(() => {
    if (page) {
      dispatch(getSubmittedList({ pageNo: page, search: search.toUpperCase() }));
    }
  }, [page]);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getSubmittedList({ pageNo: page, search: search.toUpperCase() }));
  };

  const handleSelect = (e) => {
    setdateRange(e.selection);
  };

  const handleShow = (e) => {
    e.preventDefault();
    let payload = {
      from: format(dateRange?.startDate, 'yyyy-MM-dd'),
      to: format(dateRange?.endDate, 'yyyy-MM-dd'),
    };
    dispatch(getSubmittedList({ pageNo: page, search: search.toUpperCase(), from: payload.from, to: payload.to }));
    setshowFilter(false);
  };

  const handleExport = async (e) => {
    try {
      e.preventDefault();
      let response = {};
      setsheetLoader(true);
      if (format(dateRange?.startDate, 'yyyy-MM-dd')) {
        response = await get(
          `/latestSubmissionsExport?&search=${search}&from=${format(dateRange?.startDate, 'yyyy-MM-dd')}&to=${format(
            dateRange?.endDate,
            'yyyy-MM-dd'
          )}`
        );
      } else {
        response = await get(`/latestSubmissionsExport?&search=${search}`);
      }
      if (response?.data?.status === 'failure') {
        setsheetLoader(false);
        alert.error(response?.data?.message);
      } else {
        handleExportSheet(response?.data);
        setsheetLoader(false);
      }
    } catch (error) {
      alert.error(error?.response?.data?.message);
      setsheetLoader(false);
    }
  };
  const handleExportSheet = async (passData) => {
    try {
      if (passData?.submissions?.length === 0) return alert.error('Data not available');
      SubmissionListExport({
        data: passData.submissions,
        from: format(dateRange?.startDate, 'yyyy-MM-dd'),
        to: format(dateRange?.endDate, 'yyyy-MM-dd'),
        pageNo: page,
        totalAmount: passData?.total,
      });
    } catch (err) {
      alert.error(err, { type: 'error' });
    }
  };

  return (
    <div className='visa_invoice'>
      {/* client details section start */}
      <section className='visa_invoice_list_header_div'>
        <CommonHeading heading={'Submission List'} tag={''} />
        <div style={{ minWidth: '280px' }}>
          <Link to={`/passport-submission`}>
            <FullSecondButton>Add new </FullSecondButton>
          </Link>
        </div>
      </section>
      <section className=' passport_return_second' style={{ display: 'flex' }}>
        <section>
          <form action='' onSubmit={handleSearch}>
            <PassportSearch
              value={search}
              callback={(e) => setsearch(e.target.value)}
              placeholder='Search by Invoice No, Passport No, Name'
            />
          </form>
        </section>
        <div className='' style={{ marginLeft: '50px' }}>
          <FullSecondButton sx={{ fontSize: '14px' }} onClick={handleSearch}>
            Show
          </FullSecondButton>
        </div>
      </section>
      {/* filter section start */}
      <section className='passport_return_header  passport_return_second'>
        <section className='' ref={modalRef}>
          <h5>Select Date Range</h5>
          <div ref={modalRef} className='visa_invoice_section_second ' style={{ position: 'relative' }}>
            <div className='select_date_range_div'>
              <div className='date_range_input_div'>
                <input
                  onClick={() => setshowFilter(!showFilter)}
                  type='text'
                  value={format(dateRange?.startDate, 'dd/MM/yyyy')}
                  placeholder='dd/MM/yyyy'
                  onChange={() => {}}
                />
              </div>

              <FaExchangeAlt style={{ fontSize: '20px', marginRight: '20px' }} />
              <div className='date_range_input_div'>
                <input
                  onClick={() => setshowFilter(!showFilter)}
                  type='text'
                  value={format(dateRange?.endDate, 'dd/MM/yyyy')}
                  placeholder='dd/MM/yyyy'
                  onChange={() => {}}
                />
              </div>
            </div>

            <div>
              <FullSecondButton sx={{ fontSize: '14px' }} onClick={handleShow}>
                Show
              </FullSecondButton>
            </div>
            {showFilter && (
              <div className='date_range_picker_div' style={{ position: 'absolute', top: '50px', zIndex: 20 }}>
                <DateRangePicker
                  ranges={[dateRange]}
                  onChange={handleSelect}
                  editableDateInputs={true}
                  rangeColors={['#528DC8', '#000', '#fff']}
                  moveRangeOnFirstSelection={false}
                  months={screenWidth > 766 ? 2 : 1}
                  direction='horizontal'
                  className='calendarElement'
                />
              </div>
            )}
          </div>
        </section>
        <section>
          <div>
           {sheetLoader ?<BorderSecondButton  disabled={passData?.invoices?.length === 0 ? true : false}>
              <LoaderOne/>
            </BorderSecondButton> : <BorderSecondButton onClick={handleExport} disabled={passData?.invoices?.length === 0 ? true : false}>
              <div style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                <ExportIcon />
              </div>
              Export to Excel
            </BorderSecondButton>}
          </div>
        </section>
      </section>
      {/* filter section end */}

      {/* passport details section start */}
      <section style={{ margin: '40px 0' }}>
        <SubmissionListTable page={page} />
        {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px', marginBottom: '30px' }}>
            <LoaderTwo />
          </div>
        )}
      </section>
      <section className='pagination_passport'>
        <Pagination
          count={passData?.totalPages}
          shape='rounded'
          variant='outlined'
          color='primary'
          page={page}
          onChange={(e, v) => setpage(v)}
          renderItem={(item) => <PaginationItem {...item} classes={{ selected: classes.selected }} />}
        />
      </section>
      <section style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}></section>
    </div>
  );
};

export default SubmissionList;
