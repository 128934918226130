import moment from 'moment';
import * as XLSX from 'xlsx-js-style';
import PaymentMethodList from '../../../utils/Constants/PaymentMethods.constants';

const MiscExporToExcel = async (props) => {
  // extracting props
  let { data, from, to, totalAmount, paymentTotals } = props;
  //   if (data.length == 0) return;
  // file header, and required variable
  const subHeaderStyle = {
    alignment: { horizontal: 'center' },
    font: { bold: true, sz: '13' },
  };

  const title = [
    { A: { v: 'SKY CENTRE AIR TRAVEL PTE LTD', s: subHeaderStyle } },
    { A: { v: 'MISCELLANEOUS INVOICES STATEMENTS', s: subHeaderStyle } },
    { A: { v: `From ${from} To ${to}`, s: subHeaderStyle } },
    { A: { v: `Total. S$ ${totalAmount}`, s: subHeaderStyle } },
  ];
  var currentDate = moment(new Date(Date.now())).format('DD-MM-YYYY');
  let table1 = [];
  table1 = title.concat(table1);
  const secondHeaderStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
    font: { bold: true, sz: '12' },
  };
  let firstheader = {
    A: { v: 'S/NO.', s: secondHeaderStyle },
    B: { v: '', s: secondHeaderStyle },
    C: { v: '', s: secondHeaderStyle },
    D: { v: '', s: secondHeaderStyle },
    E: { v: '', s: secondHeaderStyle },
    F: { v: '', s: secondHeaderStyle },
    G: { v: '', s: secondHeaderStyle },
    H: { v: '', s: secondHeaderStyle },
    I: { v: 'DATE', s: secondHeaderStyle },
    J: { v: currentDate, s: secondHeaderStyle },
  };
  table1.push(firstheader);
  let secondheader = {
    A: { v: 'ITEM', s: secondHeaderStyle },
    B: { v: 'NAME', s: secondHeaderStyle },
    C: { v: 'INVOICE', s: secondHeaderStyle },
    D: { v: 'DATE', s: secondHeaderStyle },
    E: { v: 'TOTAL', s: secondHeaderStyle },
    F: { v: 'CASH (S$)', s: secondHeaderStyle },
    G: { v: 'CHEQUE (S$)', s: secondHeaderStyle },
    H: { v: 'NETS (S$)', s: secondHeaderStyle },
    I: { v: 'PAYNOW (S$)', s: secondHeaderStyle },
    J: { v: 'CREDIT CARD (S$)', s: secondHeaderStyle },
  };
  table1.push(secondheader);
  // pushing body
  const bodyStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
  };
  const nameStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'left' },
  };
  for (let i in data) {
    let cash = data[i].paymentData.map(({ paymentMethod, paidAmount }, index) => {
      return paymentMethod === PaymentMethodList[0].value ? paidAmount : 0;
    });
    let cheque = data[i].paymentData.map(({ paymentMethod, paidAmount }, index) =>
      paymentMethod === PaymentMethodList[1].value ? paidAmount : 0
    );
    let nets = data[i].paymentData.map(({ paymentMethod, paidAmount }, index) =>
      paymentMethod === PaymentMethodList[2].value ? paidAmount : 0
    );
    let paynow = data[i].paymentData.map(({ paymentMethod, paidAmount }, index) =>
      paymentMethod === PaymentMethodList[3].value ? paidAmount : 0
    );
    let creditCard = data[i].paymentData.map(({ paymentMethod, paidAmount }, index) =>
      paymentMethod === PaymentMethodList[4].value ? paidAmount : 0
    );

    table1.push({
      A: { v: parseInt(i) + 1, s: bodyStyle },
      B: { v: data[i].clientName, s: nameStyle },
      C: { v: data[i].id, s: bodyStyle },
      D: { v: moment(data[i].invoiceDate).format('DD/MM/YYYY'), s: bodyStyle },
      E: { v: data[i].totalAmount, s: bodyStyle },
      F: { v: cash[0] || 0, s: bodyStyle },
      G: { v: cheque[0] || 0, s: bodyStyle },
      H: { v: nets[0] || 0, s: bodyStyle },
      I: { v: paynow[0] || 0, s: bodyStyle },
      J: { v: creditCard[0] || 0, s: bodyStyle },
    });
  }
  let totalFooter = {
    A: { v: 'TOTAL', s: secondHeaderStyle },
    B: { v: '', s: secondHeaderStyle },
    C: { v: '', s: secondHeaderStyle },
    D: { v: '', s: secondHeaderStyle },
    E: { v: Number(paymentTotals?.totalAmount).toFixed(2), s: secondHeaderStyle },
    F: { v: Number(paymentTotals?.cash).toFixed(2), s: secondHeaderStyle },
    G: { v: Number(paymentTotals?.cheque).toFixed(2), s: secondHeaderStyle },
    H: { v: Number(paymentTotals?.nets).toFixed(2), s: secondHeaderStyle },
    I: { v: Number(paymentTotals?.paynow).toFixed(2), s: secondHeaderStyle },
    J: { v: Number(paymentTotals?.creditCard).toFixed(2), s: secondHeaderStyle },
  };
  table1.push(totalFooter);
  // create a web book
  const wb = XLSX.utils.book_new();
  //create a worksheet
  const sheet = XLSX.utils.json_to_sheet(table1, { skipHeader: true });

  // merging cells
  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 10 } },
    { s: { r: 2, c: 0 }, e: { r: 2, c: 10 } },
    { s: { r: 3, c: 0 }, e: { r: 3, c: 10 } },
  ];
  // cells width
  var wscols = [
    { wch: 5 },
    { wch: 25 },
    { wch: 12 },
    { wch: 12 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 12 },
    { wch: 15 },
  ];
  sheet['!merges'] = merge;
  sheet['!cols'] = wscols;

  XLSX.utils.book_append_sheet(wb, sheet, 'voter_data');

  // const workBookBlob = workbook2blob(wb);

  return XLSX.writeFile(wb, `Misc-Statement_${currentDate}.xlsx`);
};

export default MiscExporToExcel;
