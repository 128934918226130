import { IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { LoaderOne, TableStyle } from '../../../uiComponents';
import { FcEditImage, FcPrint } from 'react-icons/fc';
import { useReactToPrint } from 'react-to-print';
import { useAlert } from 'react-alert';
import { get } from '../../../utils/methods';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import UsersRoles from '../../../utils/Constants/UserRoles.constants';
import MiscPrint from '../MiscPrint';
const { StyledTableCell, StyledTableRow } = TableStyle;

const MiscInvoiceListRow = (props) => {
  const { invoiceId, clientName, mobile, invoiceDate, productData } = props;
  const alert = useAlert();
  let printDiv = useRef();
  let [invoiceDetails, setinvoiceDetails] = useState({});
  const [loading, setloading] = useState(false);
  const [total, settotal] = useState(0);
  const reduxState = useSelector(({ users }) => {
    return {
      user: users.user,
    };
  });
  let { user } = reduxState;

  const handleGetInvoice = async () => {
    if (printDiv?.current) {
      try {
        setloading(true);
        const response = await get(`/miscInvoice/getByInvoiceId?invoiceId=${invoiceId}`);
        if (response?.data?.status === 'failure') {
          setloading(false);
          response?.data?.message && alert.error(response?.data?.message);
        } else {
          setinvoiceDetails(response?.data);
          // handlePrint();
        }
      } catch (error) {
        console.log({ error });
        setloading(false);
        // CB && CB({ error: true, message: error?.response?.data });
      }
    }
  };
  const handlePrint = useReactToPrint({
    content: () => printDiv?.current,
  });

  useEffect(() => {
    if (Object.values(invoiceDetails).length) {
      handlePrint();
      setinvoiceDetails({});
      setloading(false);
    }
    return () => {};
  }, [invoiceDetails]);

  useEffect(() => {
    if (!productData) return;
    if (productData?.length === 0) return;
    var reducedArray = productData?.reduce(function (accumulator, item) {
      console.log({ accumulator, item });
      accumulator = Number(accumulator) + Number(item['amount']);
      return Number(accumulator);
    }, 0);
    settotal(reducedArray);
    return () => {};
  }, []);

  return (
    <>
      <StyledTableRow>
        <StyledTableCell>
          <Link to={`/misc-invoice?invoiceId=${invoiceId}`}>
            <span style={{ color: 'black' }}>{invoiceId}</span>
          </Link>
        </StyledTableCell>
        <StyledTableCell align='center'>{moment(invoiceDate).format('DD/MM/YYYY')} </StyledTableCell>
        <StyledTableCell align='center'>{clientName}</StyledTableCell>
        <StyledTableCell align='center'>{mobile}</StyledTableCell>
        <StyledTableCell align='center'>
          <IconButton onClick={handleGetInvoice} sx={{ width: '40px', height: '30px' }}>
            {loading ? <LoaderOne /> : <FcPrint style={{ fontSize: '20px', color: 'red' }} />}
          </IconButton>
        </StyledTableCell>
        {user?.role === UsersRoles.ROLE_ADMIN && (
          <StyledTableCell align='center'>
            <Link to={`/misc-invoice?invoiceId=${invoiceId}`}>
              <IconButton onClick={() => {}}>
                <FcEditImage style={{ fontSize: '20px' }} />
              </IconButton>
            </Link>
          </StyledTableCell>
        )}
      </StyledTableRow>
      <StyledTableRow sx={{ display: 'none' }}>
        <div ref={printDiv}>
          <MiscPrint miscDetails={invoiceDetails} total={total} />
        </div>
      </StyledTableRow>
    </>
  );
};

export default MiscInvoiceListRow;
