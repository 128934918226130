import React from "react";

const ExportIcon = ({ width = "13", height = "15" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.95651 14.7243C4.69991 14.7243 3.44332 14.7275 2.18673 14.7233C1.07656 14.7196 0.217051 13.8974 0.0255427 12.663C0.00610236 12.537 0.00196611 12.4067 0.00196611 12.2784C0.00072524 9.4137 -0.00175651 6.54948 0.00196611 3.68479C0.00362061 2.47146 0.715882 1.5036 1.76442 1.27171C1.89554 1.24267 2.03121 1.22955 2.16522 1.22955C3.51488 1.22674 4.86454 1.22627 6.21419 1.22861C6.61127 1.22908 6.90122 1.51578 6.92646 1.92054C6.95417 2.36418 6.66008 2.72631 6.25804 2.72912C5.60658 2.7338 4.95512 2.73052 4.30325 2.73052C3.60505 2.73052 2.90685 2.73005 2.20907 2.73052C1.67136 2.73146 1.3206 3.11935 1.32019 3.72649C1.31812 6.56166 1.31812 9.39684 1.32019 12.2325C1.32019 12.8307 1.66846 13.2298 2.19418 13.2303C4.70198 13.2331 7.2102 13.2331 9.71801 13.2303C10.2446 13.2298 10.5883 12.8288 10.5887 12.2311C10.5899 10.4738 10.5879 8.71615 10.5899 6.95892C10.5903 6.39395 11.0449 6.02573 11.4912 6.22202C11.7597 6.34008 11.9144 6.6146 11.9152 6.98797C11.9168 7.67943 11.9156 8.37042 11.9156 9.06188C11.9156 10.122 11.9185 11.1826 11.9148 12.2428C11.9102 13.503 11.1752 14.4877 10.0812 14.6985C9.94467 14.7247 9.80321 14.7243 9.66382 14.7243C8.42791 14.7261 7.192 14.7252 5.95609 14.7252L5.95651 14.7243Z"
        fill="black"
      />
      <path
        d="M10.8033 1.42642C10.7512 1.42642 10.6991 1.42642 10.647 1.42642C9.95656 1.42642 9.26608 1.42821 8.57561 1.42418C8.30099 1.4224 8.10293 1.26644 8.00034 0.982232C7.90328 0.71277 7.94392 0.454033 8.11713 0.232834C8.25365 0.0585551 8.43159 -0.000878391 8.63479 1.5345e-05C9.51781 0.00359029 10.4004 0.00135595 11.2835 0.00135595C11.6437 0.00135595 12.0035 -0.00266586 12.3638 0.00314342C12.7374 0.00895271 12.9966 0.29897 12.9978 0.723495C13.0014 2.14811 13.001 3.57317 12.9978 4.99779C12.997 5.37316 12.769 5.66764 12.4652 5.71054C12.142 5.75612 11.8556 5.55414 11.7692 5.20871C11.7455 5.11397 11.7431 5.0103 11.7427 4.91065C11.7407 4.10629 11.7415 3.30192 11.7415 2.45109C11.6796 2.51588 11.6386 2.5561 11.6003 2.599C9.91907 4.50311 8.23708 6.40676 6.55744 8.31266C6.37082 8.52447 6.16289 8.64423 5.8938 8.56559C5.44716 8.4351 5.27 7.843 5.55527 7.43277C5.59472 7.37602 5.64128 7.32553 5.68666 7.27414C7.35721 5.3812 9.02737 3.48872 10.6999 1.59802C10.7398 1.55288 10.8021 1.53367 10.8542 1.50194C10.8372 1.47647 10.8202 1.45144 10.8029 1.42597L10.8033 1.42642Z"
        fill="black"
      />
    </svg>
  );
};

export default ExportIcon;
