import users from './Users';
import { FrontEnd } from './FrontEnd';
import visaInvoice from './VisaInvoice';
import statements from './Statements';
import tickets from './Ticket';
import misc from './Misc';
export default {
  users,
  FrontEnd,
  visaInvoice,
  statements,
  tickets,
  misc,
};
