import React, { useEffect, useRef, useState } from 'react';
import { FaExchangeAlt } from 'react-icons/fa';
import { AllButton, LoaderOne } from '../../uiComponents';
import './../../Components/Passport/styles/PassportReturn.css';
import PassportHeading from '../../Components/Passport/PassportHeading';
import { Pagination, PaginationItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useOnClickOutsideRef from '../../utils/outsideClick';
import ExportIcon from '../../uiComponents/Icons/ExportIcon';
import CostItem from '../../uiComponents/CostItem/CostItem';
import '../../Components/VisaInvoice/styles/VisaInvoiceStatment.css';
import { DateRangePicker } from 'react-date-range';
import format from 'date-fns/format';
import TicketStamentsTable from '../../Components/Ticket/Statements/TicketStatementsTable';
import { useDispatch, useSelector } from 'react-redux';
import { getMiscStatement, getMiscStatementTotal } from '../../store/action/Statements';
import MiscStamentsTable from '../../Components/Misc/Statements/MiscStatementsTable';
import MiscExporToExcel from '../../Components/Misc/Statements/MiscExportToExcel';
import { post } from '../../utils/methods';

// import { makeStyles } from "@mui/styles";
const { BorderSecondButton, FullSecondButton } = AllButton;
const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: '#528DC8 !important',
    color: '#fff !important',
  },
}));
const MiscStatements = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reduxState = useSelector(({ statements }) => {
    return {
      miscData: statements.miscData,
      loading: statements.miscLoading,
      miscTotalLoading: statements.miscTotalLoading,
      miscTotal: statements.miscTotal,
    };
  });
  let { miscData, loading, miscTotalLoading, miscTotal } = reduxState;
  const [page, setpage] = useState(1);
  const [showFilter, setshowFilter] = useState(false);
  const [sheetLoader, setsheetLoader] = useState(false);
  let screenWidth = window.innerWidth;
  const [dateRange, setdateRange] = useState({
    startDate: new Date().setDate(new Date().getDate() - 10),
    endDate: new Date(),
    key: 'selection',
  });
  let modalRef = useOnClickOutsideRef(showFilter, () => setshowFilter(false));

  const handleSelect = (e) => {
    setdateRange(e.selection);
  };

  const handleShow = (e) => {
    e.preventDefault();
    let payload = {
      from: format(dateRange.startDate, 'yyyy-MM-dd'),
      to: format(dateRange.endDate, 'yyyy-MM-dd'),
      pageNo: 1,
      pageSize: 50,
    };
    dispatch(getMiscStatement({ payload }));
    dispatch(getMiscStatementTotal({ from: payload.from, to: payload.to }));
    setshowFilter(false);
  };
  useEffect(() => {
    let payload = {
      from: format(dateRange.startDate, 'yyyy-MM-dd'),
      to: format(dateRange.endDate, 'yyyy-MM-dd'),
      pageNo: page,
      pageSize: 50,
    };
    dispatch(getMiscStatementTotal({ from: payload.from, to: payload.to }));
    dispatch(getMiscStatement({ payload }));
  }, [page]);

  const handleExport = async (e) => {
    try {
      e.preventDefault();
      setsheetLoader(true);
      let payload = {
        from: format(dateRange.startDate, 'yyyy-MM-dd'),
        to: format(dateRange.endDate, 'yyyy-MM-dd'),
        pageNo: page,
        pageSize: 50,
      };

      let response = await post(
          `/miscInvoice/getMiscInvoicesExport`, payload
        );
      if (response?.data?.status === 'failure') {
        setsheetLoader(false);
        alert.error(response?.data?.message);
      } else {
        handleExportSheet(response?.data);
        setsheetLoader(false);
      }
    } catch (error) {
      alert.error(error?.response?.data?.message);
      setsheetLoader(false);
    }
  };


  const handleExportSheet = async (miscData) => {
    try {
      if (miscData.data?.length == 0) return alert.error('please select Date range');
      MiscExporToExcel({
        data: miscData.data,
        from: format(dateRange.startDate, 'yyyy-MM-dd'),
        to: format(dateRange.endDate, 'yyyy-MM-dd'),
        totalAmount: miscTotal?.totalAmount || 0,
        paymentTotals:miscTotal
      });
    } catch (err) {
      alert.error(err, { type: 'error' });
    }
  };

  return (
    <div className='passport_return_main_div'>
      {/* first section start */}
      <section className='' style={{ textAlign: 'center' }}>
        <PassportHeading title={'Ticket Invoice Statement'} />
      </section>
      {/* first section end */}
      {/* filter section start */}
      <section className='passport_return_header  passport_return_second'>
        <section className='' ref={modalRef}>
          <h5>Select Date Range</h5>
          <div ref={modalRef} className='visa_invoice_section_second ' style={{ position: 'relative' }}>
            <div className='select_date_range_div'>
              <div className='date_range_input_div'>
                <input
                  onClick={() => setshowFilter(!showFilter)}
                  type='text'
                  value={format(dateRange.startDate, 'dd/MM/yyyy')}
                  placeholder='dd/MM/yyyy'
                  onChange={() => {}}
                />
              </div>

              <FaExchangeAlt style={{ fontSize: '20px', marginRight: '20px' }} />
              <div className='date_range_input_div'>
                <input
                  onClick={() => setshowFilter(!showFilter)}
                  type='text'
                  value={format(dateRange.endDate, 'dd/MM/yyyy')}
                  placeholder='dd/MM/yyyy'
                  onChange={() => {}}
                />
              </div>
            </div>

            <div>
              <FullSecondButton sx={{ fontSize: '14px' }} onClick={handleShow}>
                Show
              </FullSecondButton>
            </div>
            {showFilter && (
              <div className='date_range_picker_div' style={{ position: 'absolute', top: '50px', zIndex: 20 }}>
                <DateRangePicker
                  ranges={[dateRange]}
                  onChange={handleSelect}
                  editableDateInputs={true}
                  rangeColors={['#528DC8', '#000', '#fff']}
                  moveRangeOnFirstSelection={false}
                  months={screenWidth > 766 ? 2 : 1}
                  direction='horizontal'
                  className='calendarElement'
                />
              </div>
            )}
          </div>
        </section>
        <section>
          <div>
          {sheetLoader ?<BorderSecondButton  >
              <LoaderOne/>
            </BorderSecondButton> : <BorderSecondButton onClick={handleExport} >
              <div style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                <ExportIcon />
              </div>
              Export to Excel
            </BorderSecondButton>}
          </div>
        </section>
      </section>
      {/* filter section end */}
      {/* total cost section start */}
      <section className='total_cost_div'>
        <CostItem label={'Total Amount'} value={miscTotal?.totalAmount ? Number(miscTotal.totalAmount) : '0'} />
      </section>{' '}
      <section className='total_cost_div'>
        <CostItem label={'Cash'} value={miscTotal?.cash} />
        <CostItem label={'Nets'} value={miscTotal?.nets} />
        <CostItem label={'Cheque'} value={miscTotal?.cheque} />
        <CostItem label={'Paynow'} value={miscTotal?.paynow} />
        <CostItem label={'Credit Card'} value={miscTotal?.creditCard} />
      </section>
      {/* total cost section end */}
      {/* No of Records section start */}
      <section
        style={{ display: 'flex', fontFamily: 'Nunito', fontSize: '14px', fontWeight: 700, marginTop: '50px' }}
        className=''
      >
        No. of records: {miscData?.totalCount || 0}
      </section>
      {/* No of Records section end */}
      {/* table section start */}
      <section style={{ marginTop: '10px' }}>
        <MiscStamentsTable />
        {miscData?.data?.length == 0 && (
          <div style={{ display: 'flex', justifyContent: 'center', margin: '80px 0' }}>No Data available </div>
        )}
      </section>
      {/* table section end */}
      {/* pagination section start */}
      <section className='passport_return_pagination_section'>
        <section className='pagination_passport'>
          <Pagination
            count={miscData?.totalPages}
            shape='rounded'
            variant='outlined'
            color='primary'
            page={page}
            onChange={(e, v) => setpage(v)}
            renderItem={(item) => <PaginationItem {...item} classes={{ selected: classes.selected }} />}
          />
        </section>
      </section>
      {/* pagination section end */}
    </div>
  );
};

export default MiscStatements;
