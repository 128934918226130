import { get, post } from '../../utils/methods';
import MiscConstants from '../constants/Misc.constants';

export const getMiscList =
  ({ pageNo, pageSize = 50, search }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: MiscConstants.GET_MISC_LIST, loading: true });
      const response = await get(`/miscInvoice/getMiscsInvoice?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}`);
      if (response?.data?.status === 'failure') {
        dispatch({ type: MiscConstants.GET_MISC_LIST, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: MiscConstants.GET_MISC_LIST, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      dispatch({ type: MiscConstants.GET_MISC_LIST, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const saveMiscInvoice = (payload, CB) => async (dispatch) => {
  try {
    dispatch({ type: MiscConstants.SAVE_INVOICE, loading: true });
    const response = await post(`/miscInvoice/save`, payload);
    if (response?.data?.status === 'failure') {
      dispatch({ type: MiscConstants.SAVE_INVOICE, loading: false });
      CB && CB({ error: false, data: response?.data });
    } else {
      dispatch({ type: MiscConstants.SAVE_INVOICE, loading: false });
      CB && CB({ error: false, data: response?.data });
    }
  } catch (error) {
    dispatch({ type: MiscConstants.SAVE_INVOICE, loading: false });
    CB && CB({ error: true, message: error?.response?.data });
  }
};

export const editMisc = (payload, invoiceId, CB) => async (dispatch) => {
  try {
    dispatch({ type: MiscConstants.SAVE_INVOICE, loading: true });
    const response = await post(`/miscInvoice/edit?invoiceId=${invoiceId}`, payload);
    if (response?.data?.status === 'failure') {
      dispatch({ type: MiscConstants.SAVE_INVOICE, loading: false });
      CB && CB({ error: false, data: response?.data });
    } else {
      dispatch({ type: MiscConstants.SAVE_INVOICE, loading: false });
      CB && CB({ error: false, data: response?.data });
    }
  } catch (error) {
    dispatch({ type: MiscConstants.SAVE_INVOICE, loading: false });
    CB && CB({ error: true, message: error?.response?.data });
  }
};

export const getMiscDetails =
  ({ invoiceId }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: MiscConstants.GET_MISC_DETAILS, loading: true });
      const response = await get(`/miscInvoice/getByInvoiceId?invoiceId=${invoiceId}`);
      if (response?.data?.status === 'failure') {
        dispatch({ type: MiscConstants.GET_MISC_DETAILS, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: MiscConstants.GET_MISC_DETAILS, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      dispatch({ type: MiscConstants.GET_MISC_DETAILS, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };
