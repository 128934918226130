import { Table, TableBody, TableContainer, TableHead } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { TableStyle } from '../../../uiComponents';
import MiscInvoiceRow from './MiscInvoiceRow';

const { StyledTableCell, StyledTableRow } = TableStyle;

const MiscInvoiceTable = ({ callback }) => {
  const reduxState = useSelector(({ misc }) => {
    return {
      miscData: misc.miscData,
      loading: misc.saveLoading,
      miscDetails: misc.miscDetails,
    };
  });
  let { miscData, loading, miscDetails } = reduxState;

  return (
    <div>
      <TableContainer>
        <Table sx={{ marginBottom: '20px', minWidth: 'max-content' }} aria-label='simple table'>
          <TableHead sx={{ borderBottom: '2px solid #EBEFF2' }}>
            <StyledTableRow>
              <StyledTableCell sx={{ width: '200px' }}>Service</StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '200px' }}>
                Description
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '40px' }}>
                Qty
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                Rate (S$)
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                Amount (S$)
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '50px' }}>
                Edit
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '50px' }}></StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {miscData?.length > 0 &&
              miscData?.map(({ productName, description, rate, quantity, amount }, index) => (
                <MiscInvoiceRow
                  key={rate + quantity + index}
                  productName={productName}
                  description={description}
                  rate={rate}
                  quantity={quantity}
                  amount={amount}
                  index={index}
                  callback={callback}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MiscInvoiceTable;
