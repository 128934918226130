import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import "./styles/MainLayout.css";
const MainLayout = ({ children }) => {
  let screenWidth = window.innerWidth;
  let sidebarWidth = screenWidth > 850 ? "250px" : "280px";
  let sidebarCloseWidth = screenWidth > 850 ? "0" : "280px";

  const { frontEnd } = useSelector((state) => {
    return { frontEnd: state.FrontEnd };
  });
  let { open } = frontEnd;

  return (
    <div style={{ display: "flex", position: "relative", background: "#F7F8FA" }}>
      <section
        style={{ width: open ? sidebarWidth : sidebarCloseWidth, overflowX: "hidden" }}
        className={`main_layout_left ${open ? `sidebar_open` : `sidebar_close`}`}
      >
        <Sidebar />
      </section>
      <section style={{ flex: 1 }} className="main_layout_right">
        <div style={{ background: "#FFFFFF" }}>
          <Header />
        </div>

        <div style={{ background: "#FFFFFF", marginTop: "10px" }}>
          <Outlet />
        </div>
      </section>
    </div>
  );
};

export default MainLayout;
