import { Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableStyle } from '../../../uiComponents';
import VisaInvoiceRow from './VisaInvoiceRow';

const { StyledTableCell, StyledTableRow, GreyTableRow } = TableStyle;
const VisaInvoiceTable = ({ callback }) => {
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      passData: visaInvoice.passportDetails,
    };
  });
  let { passData } = reduxState;
  return (
    <div>
      {' '}
      <TableContainer>
        <Table sx={{ minWidth: 650, marginBottom: '20px', minWidth: 'max-content' }} aria-label='simple table'>
          <TableHead sx={{ borderBottom: '2px solid #EBEFF2' }}>
            <StyledTableRow>
              <StyledTableCell sx={{ width: '70px' }}>PASSPORT</StyledTableCell>
              {/* <StyledTableCell align="center" sx={{ width: "70px" }}>
                Expiry
              </StyledTableCell> */}
              <StyledTableCell align='center' sx={{ width: '200px' }}>
                {' '}
                Name
              </StyledTableCell>
              {/* <StyledTableCell align="center">
                No. of <br />
                Passports
              </StyledTableCell> */}
              {/* <StyledTableCell align="center" sx={{ width: "40px" }}>
                SEX
              </StyledTableCell> */}
              <StyledTableCell align='center'>Nationality</StyledTableCell>
              {/* <StyledTableCell align="center"> Pass type</StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "80px" }}>
                Pass Expiry
              </StyledTableCell> */}
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                Visa Fee(S$)
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                Agt. Fee(S$)
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                Agt. GST(S$)
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                ST. Fee(S$)
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '100px' }}>
                ST. GST(S$)
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '10px' }}>
                Edit
              </StyledTableCell>
              <StyledTableCell align='center' sx={{ width: '50px' }}></StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {passData?.length > 0 &&
              passData?.map(
                (
                  {
                    passportNumber,
                    name,
                    gender,
                    nationality,
                    passExpiry,
                    passportExpiry,
                    typeOfPass,
                    visaForCountry,
                    visaFee,
                    agentFee,
                    agentFeeGST,
                    skyServicefee,
                    skyServicefeeGST,
                  },
                  index
                ) => (
                  <VisaInvoiceRow
                    key={passportNumber + name + index}
                    passport_no={passportNumber}
                    passport_expiry_date={passportExpiry}
                    name={name}
                    gender={gender}
                    nationality={nationality}
                    type_of_pass={typeOfPass}
                    pass_expiry={passExpiry}
                    visa_for_country={visaForCountry}
                    comments={''}
                    return_status={'Not return'}
                    visaFee={visaFee}
                    agentFee={agentFee}
                    agentFeeGST={agentFeeGST}
                    skyServicefee={skyServicefee}
                    skyServicefeeGST={skyServicefeeGST}
                    callback={callback}
                  />
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default VisaInvoiceTable;
