import React from "react";
import { MdClose } from "react-icons/md";
import { Backdrop, CloseButton, StyledModal } from "./styles/ModalStyles";

const CommonModal = ({ showModal, callback, children }) => {
  return (
    <StyledModal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={showModal}
      onClose={callback}
      BackdropComponent={Backdrop}
    >
      <div
        className=""
        style={{
          backgroundColor: "#fff",
          borderRadius: "10px",
          position: "relative",
          padding: "40px",
          boxShadow: "0px 0px 20px rgba(0,0,0,0.2)",
        }}
      >
        <div className="" style={{ position: "absolute", right: "10px", top: "10px", borderRadius: "20px" }}>
          <CloseButton onClick={callback}>
            {" "}
            <MdClose />
          </CloseButton>
        </div>
        {children}
      </div>
    </StyledModal>
  );
};

export default CommonModal;
