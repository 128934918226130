import React, { useEffect, useRef, useState } from 'react';

import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

// [
//     "jan",
//     "feb",
//     "march",
//     "april",
//     "may",
//     "june",
//     "july",
//     "aug",
//     "sept",
//     "nov",
//     "dec",
//   ]
const VisaChart = ({ data }) => {
  const [userData, setuserData] = useState([
    {
      id: 1,
      year: '1 sep',
      visaInvoice: data['1-8'],
    },

    {
      id: 2,
      year: '9 sep',
      visaInvoice: data['9-16'],
    },
    {
      id: '17 sep',
      year: '17 sep',
      visaInvoice: data['17-24'],
    },

    {
      id: 4,
      year: Object.keys(data)[0],
      visaInvoice: data['25-31'],
    },
  ]);

  useEffect(() => {
    if (Object.values(data).length != 0) {
      setuserData([
        {
          id: 1,
          year: Object.keys(data)[3],
          visaInvoice: Object.values(data)[3],
        },
        {
          id: 2,
          year: Object.keys(data)[2],
          visaInvoice: Object.values(data)[2],
        },
        {
          id: 3,
          year: Object.keys(data)[1],
          visaInvoice: Object.values(data)[1],
        },

        {
          id: 4,
          year: Object.keys(data)[0],
          visaInvoice: Object.values(data)[0],
        },
      ]);
    }
  }, [data]);
  const chartRef = useRef();
  let month = moment(new Date(Date.now())).format('MMM');
  const lastDay = moment(new Date().setDate(new Date().getDate() - 30)).date();
  let lablesData = [];
  for (let index = 1; index <= 30; index++) {
    let currentDate = moment(new Date().setDate(new Date().getDate() - index)).date();
    lablesData = [...lablesData, index];
  }

  function createGradient(ctx, area) {
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
    gradient.addColorStop(0, '#109CF100');
    gradient.addColorStop(0.9, '#3db6ff99');
    gradient.addColorStop(1, '#109CF188');

    return gradient;
  }

  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const preData = {
    labels: lablesData,
    datasets: [
      {
        fill: true,
        label: 'Visa',
        data: data.map((data) => data),
        borderWidth: 2,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Visa',
      },
    },
    scales: {
      xAxes: {
        grid: {
          display: false,
        },
      },

      yAxes: {
        grid: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) {
      return;
    }

    const chartData = {
      ...preData,
      datasets: preData.datasets.map((dataset) => ({
        ...dataset,
        borderColor: '#109CF1',
        backgroundColor: createGradient(chart.ctx, chart.chartArea),
      })),
    };

    setChartData(chartData);
  }, [chartRef]);
  return <Line ref={chartRef} data={chartData} options={options} />;
};

export default VisaChart;
