import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SidebarSubHeading = ({ Icon, label, link }) => {
  const [active, setactive] = useState(false);
  useEffect(() => {
    if (window.location.pathname == link) {
      setactive(true);
    } else {
      setactive(false);
    }
  }, [window.location.pathname]);
  return (
    <Link to={link}>
      <div
        style={{ display: "flex", alignItems: "center", backgroundColor: active ? "#f9f6df" : "#fff" }}
        className="sidebar_sub_heading_component"
      >
        {Icon && <Icon />} <h4>{label}</h4>
      </div>
    </Link>
  );
};

export default SidebarSubHeading;
