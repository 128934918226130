import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { SIDEBAR_FUNCTION } from '../../store/constants/FrontEnd';
import Users from '../../store/constants/Users';
import Hamburger from '../../uiComponents/Hamburger/Hamburger';
import UsersRoles from '../../utils/Constants/UserRoles.constants';
import { TOKEN } from '../../utils/storage/Constant';
import { clearStorageData } from '../../utils/storage/Functions';
import InvoiceIcon from '../Dashboard/Icons/InvoiceIcon';
import TicketIcon from '../Dashboard/Icons/TicketIcon';
import DashboardIcon from './Icons/DashboardIcon';
import Logout from './Icons/Logout';
import PassportIcon from './Icons/PassportIcon';
import PassportStatuIcon from './Icons/PassportStatuIcon';
import ReportIcon from './Icons/ReportIcon';
import SettingIcon from './Icons/SettingIcon';
import TicketIncoiceIcons from './Icons/TicketIncoiceIcons';
import VisaIcon from './Icons/VisaIcon';
import SidebarHeadings from './SidebarHeadings';
import './styles/Sidebar.css';
const SidebarData = [
  {
    label: 'Dashboard',
    icon: DashboardIcon,
    link: '/',
  },
  {
    label: 'Visa Invoice',
    icon: VisaIcon,
    link: '/visa-invoice-list',
  },
  {
    label: 'Ticket Invoice',
    icon: TicketIncoiceIcons,
    link: '/ticket-invoice-list',
  },
  {
    label: 'Miscellaneous Invoice',
    icon: TicketIncoiceIcons,
    link: '/misc-list',
  },
  {
    label: 'Passport Activity',
    icon: PassportIcon,
    link: '/passport-submission',
    subItem: [
      {
        label: 'Submission',
        icon: '',
        link: '/passport-submission-list',
      },
      {
        label: 'Return',
        icon: '',
        link: '/passport-return-list',
      },
      {
        label: 'Delivery',
        icon: '',
        link: '/passport-delivery-list',
      },
    ],
  },
  {
    label: 'Passport Status',
    icon: PassportStatuIcon,
    link: '/visa-status-track',
  },
  {
    label: 'Reports',
    icon: ReportIcon,
    link: '/visa-invoice-statement',
    subItem: [
      {
        label: 'Visa Invoice Statement',
        icon: '',
        link: '/visa-invoice-statement',
      },
      {
        label: 'Ticket Invoice Statement',
        icon: '',
        link: '/ticket-invoice-statement',
      },
      {
        label: 'Miscellaneous Invoice Statement',
        icon: '',
        link: '/misc-invoice-statement',
      },
    ],
  },
  {
    label: 'Settings',
    icon: SettingIcon,
    link: '/users-list',
    subItem: [
      {
        label: 'Create/Manage Users',
        icon: '',
        link: '/users-list',
      },
      {
        label: 'Change Password',
        icon: '',
        link: '/change-password',
      },
    ],
  },
];
const UsersSidebarData = [
  {
    label: 'Dashboard',
    icon: DashboardIcon,
    link: '/',
  },
  {
    label: 'Visa Invoice',
    icon: VisaIcon,
    link: '/visa-invoice-list',
  },
  {
    label: 'Ticket Invoice',
    icon: TicketIncoiceIcons,
    link: '/ticket-invoice-list',
  },
  {
    label: 'Miscellaneous Invoice',
    icon: TicketIncoiceIcons,
    link: '/misc-list',
  },
  {
    label: 'Passport Activity',
    icon: PassportIcon,
    link: '/passport-submission',
    subItem: [
      {
        label: 'Submission',
        icon: '',
        link: '/passport-submission-list',
      },
      {
        label: 'Return',
        icon: '',
        link: '/passport-return-list',
      },
      {
        label: 'Delivery',
        icon: '',
        link: '/passport-delivery-list',
      },
    ],
  },
  {
    label: 'Passport Status',
    icon: PassportStatuIcon,
    link: '/visa-status-track',
  },
];
const Sidebar = () => {
  const navigate = useNavigate();
  let screenWidth = window.innerWidth;
  const dispatch = useDispatch();
  const reduxState = useSelector(({ FrontEnd, users }) => {
    return {
      frontEnd: FrontEnd,
      users: users,
    };
  });
  let { open } = reduxState?.frontEnd;
  let { user } = reduxState?.users;
  // const handleSidebar = () => {
  //   dispatch({
  //     type: SIDEBAR_FUNCTION,
  //     open: !open,
  //   });
  // };
  const handleLogout = () => {
    clearStorageData(TOKEN);
    dispatch({
      type: Users.LOGIN_USER_API,
      userLoggedIn: false,
    });
    navigate('/login');
  };
  return (
    <div style={{ position: 'relative', paddingBottom: '40px' }}>
      {screenWidth < 850 && (
        <section className='hamburger_small_screen'>
          <Hamburger sidebar={open} />
        </section>
      )}
      <section>
        {/* logo section start */}
        <div className='sidebar_heading_padding logo_sidebar'>
          <img
            src={require('./../../assets/logo/mainLogo.png')}
            alt='sky centre '
            className=''
            style={{ objectFit: 'contain', width: '80px' }}
          />
        </div>
        {/* routing pages start */}
        <section className='sidebar_routing_section sidebar_heading_padding'>
          <div className='administration_style'>
            <h4>Administration Overview</h4>
          </div>
          {user.role === UsersRoles.ROLE_ADMIN
            ? SidebarData.map(({ label, icon, link, subItem }, index) => (
                <div key={label + link + index} style={{ marginTop: '10px' }}>
                  <SidebarHeadings Icon={icon} label={label} link={link} subItem={subItem} />
                </div>
              ))
            : UsersSidebarData.map(({ label, icon, link, subItem }, index) => (
                <div key={label + link + index} style={{ marginTop: '10px' }}>
                  <SidebarHeadings Icon={icon} label={label} link={link} subItem={subItem} />
                </div>
              ))}
          {/* <SidebarHeadings Icon={<Logout />} label={"Logout"} /> */}
          <div style={{ marginTop: '20px' }} onClick={handleLogout}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              className='sidebar_heading_component'
            >
              <Logout width='15' height='15' color={'#7C8DB5'} /> <h4>Log Out</h4>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};

export default Sidebar;
