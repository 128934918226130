import React from "react";
import "./styles/VisaInvoiceReview.css";
const DetailItem = ({ label, value }) => {
  return (
    <div className="details_item">
      <p className="details_item_label">{label}</p>
      <p className="details_item_value">{value}</p>
    </div>
  );
};

export default DetailItem;
