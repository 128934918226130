import React from "react";

const Logout = ({ height = "26", width = "27", color = "#7C8DB5" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50553 25.031C3.5368 25.0646 1.0672 22.7562 0.900528 19.792L0.894531 19.542V6.47596C0.917992 3.50818 3.27644 1.08631 6.24255 0.984015L6.49953 0.978033H12.3465C15.3153 0.943976 17.7853 3.25184 17.9525 6.21607L17.9585 7.57007C17.9464 8.193 17.4597 8.70301 16.838 8.74415C16.2164 8.78528 15.6666 8.34388 15.5725 7.72803L15.5635 7.589V6.47596C15.547 4.79114 14.2195 3.41138 12.5365 3.32996H12.3365H6.50553C4.81979 3.31483 3.41338 4.61426 3.29554 6.29603L3.29054 6.4889V19.5399C3.30763 21.2243 4.6351 22.603 6.31753 22.684L6.51753 22.6899H12.3615C14.0411 22.7063 15.4432 21.4124 15.5615 19.7369L15.5665 18.445C15.5791 17.8221 16.066 17.3126 16.6875 17.2717C17.309 17.2308 17.8585 17.6722 17.9525 18.288L17.9615 18.427V19.553C17.9357 22.5105 15.5873 24.9242 12.6316 25.031L12.3675 25.036L6.50553 25.031ZM20.5425 17.255C20.1266 16.8457 20.0786 16.1914 20.4305 15.726L20.5305 15.6119L21.9995 14.178H10.4745C9.84747 14.1942 9.31887 13.7138 9.27521 13.088C9.23154 12.4623 9.68831 11.9131 10.3115 11.842L10.4545 11.8309H21.9995L20.5455 10.4149C20.128 10.0078 20.0775 9.35413 20.4275 8.88782L20.5275 8.77295C20.9513 8.35279 21.6153 8.29676 22.1035 8.6399L22.2215 8.73987L25.7315 12.1619C25.9665 12.3804 26.0996 12.687 26.0985 13.0078C26.0968 13.3318 25.961 13.6406 25.7235 13.8608L22.2385 17.2609C21.7662 17.7185 21.0159 17.7185 20.5435 17.2609L20.5425 17.255Z"
        fill={color}
      />
    </svg>
  );
};

export default Logout;
