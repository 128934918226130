import VisaInvoiceConstant from "../constants/VisaInvoice.constants";

const initialState = {
  loading: false,
  passportDetails: [],
  allPassports: [],
  submissionPassports: [],
  returnPassports: [],
  submissionPassportList: [],
  allInvoices: {},
  invoiceListLoading: false,
  getPassportLoading: false,
  invoiceLoading: false,
  detailsLoading: false,
  submissionLoading: false,
  returnLoading: false,
  invoiceDetails: {},
  message: "",
  submissionListLoading: false,
  returnListLoading: false,
  deliveryListLoading: false,
  submissionList: {},
  returnList: {},
  deliveryList: {},
  latestSubmittedLoading: false,
  latestReturnedLoading: false,
  latestSubmissions: {},
  latestReturned: {},
  deliveryPassportsData: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case VisaInvoiceConstant.SET_DELIVERY_PASSPORTS:
      return {
        ...state,
        deliveryPassportsData: action.deliveryPassportsData,
      };
    case VisaInvoiceConstant.SET_PASSPORT_DATA:
      return {
        ...state,
        passportDetails: action.passportDetails,
      };
    case VisaInvoiceConstant.ADD_PASSPORT_SUBMISSION:
      return {
        ...state,
        submissionPassports: action.submissionPassports,
        submissionPassportList: action.submissionPassportList,
      };
      case VisaInvoiceConstant.ADD_PASSPORT_RETURN:
        return {
          ...state,
          returnPassports: action.returnPassports,
        };
    case VisaInvoiceConstant.PASSPORT_SUBMISSION:
      return {
        ...state,
        submissionLoading: action.loading,
      };
    case VisaInvoiceConstant.PASSPORT_RETURN:
      return {
        ...state,
        returnLoading: action.loading,
      };
    case VisaInvoiceConstant.ADD_INVOICE_DETAILS:
    case VisaInvoiceConstant.UPDATE_INVOICE_DETAILS:
      return {
        ...state,
        message: action.message,
        invoiceLoading: action.loading,
      };
    case VisaInvoiceConstant.GET_INVOICE_LIST:
      return {
        ...state,
        allInvoices: action.data,
        invoiceListLoading: action.loading,
      };
    case VisaInvoiceConstant.GET_INVOICE:
      return {
        ...state,
        invoiceDetails: action.data,
        passportDetails: action.data?.passportInfo,
        detailsLoading: action.loading,
      };
    case VisaInvoiceConstant.GET_PASSPORTS:
      return {
        ...state,
        allPassports: action.data,
        getPassportLoading: action.loading,
      };
    case VisaInvoiceConstant.GET_SUBMISSION_LIST:
      return {
        ...state,
        submissionList: action.data,
        submissionListLoading: action.loading,
      };
    case VisaInvoiceConstant.GET_LATEST_SUBMITTED:
      return {
        ...state,
        latestSubmissions: action.data,
        latestSubmittedLoading: action.loading,
      };
    case VisaInvoiceConstant.GET_RETURN_PASSPORT_LIST:
      return {
        ...state,
        returnList: action.data,
        returnListLoading: action.loading,
      };
    case VisaInvoiceConstant.GET_LATEST_RETURNED:
      return {
        ...state,
        latestReturned: action.data,
        latestReturnedLoading: action.loading,
      };
    case VisaInvoiceConstant.GET_DELIVERED_PASSPORT_LIST:
      return {
        ...state,
        deliveryList: action.data,
        deliveryListLoading: action.loading,
      };
    default:
      return state;
  }
};
