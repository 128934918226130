import MiscConstants from '../constants/Misc.constants';

const initialState = {
  listLoading: false,
  saveLoading: false,
  detailsLoading: false,
  miscList: {},
  miscDetails: {},
  miscData: [],
  message: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case MiscConstants.GET_MISC_LIST:
      return {
        ...state,
        listLoading: action.loading,
        miscList: action.data,
      };
    case MiscConstants.GET_MISC_DETAILS:
      return {
        ...state,
        detailsLoading: action.loading,
        miscDetails: action.data,
      };
    case MiscConstants.ADD_MISC:
      return {
        ...state,
        miscData: action.miscData,
      };
    case MiscConstants.SAVE_INVOICE:
      return {
        ...state,
        saveLoading: action.loading,
      };

    default:
      return state;
  }
};
