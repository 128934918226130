import { Button } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CommonHeading from '../../Components/VisaInvoice/CommonHeading';
import '../../Components/VisaInvoice/styles/VisaInvoice.css';
import { CommonGreenHeading, ErrorAlert, LoaderOne } from '../../uiComponents';
import { AllButton } from '../../uiComponents';
import RectSelect from '../../uiComponents/Select/RectSelect';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { miscInvoiceValidate, miscValidate } from '../../utils/validator';
import PaymentMethodList from '../../utils/Constants/PaymentMethods.constants';
import MiscConstants from '../../store/constants/Misc.constants';
import { editMisc, getMiscDetails, saveMiscInvoice } from '../../store/action/Misc';
import MiscInvoiceTable from '../../Components/Misc/Invoice/MiscInvoiceTable';

const { FullSecondButton } = AllButton;
const DashedButton = styled(Button)(({ theme }) => ({
  color: '#000',
  margin: '0px',
  fontFamily: 'Nunito',
  textTransform: 'capitalize',
  whiteSpace: ' nowrap',
  fontWeight: 700,
  border: '1px dashed black',
  backgroundColor: '#fff !important',
  fontSize: '14px',
  [theme.breakpoints.up('md')]: {
    padding: '2px 40px 2px 35px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '2px 35px 2px 25px',
  },
  '&:hover': {
    backgroundColor: '#fffff',
  },
  '&:active': {
    backgroundColor: '#fffff',
  },
}));

const MiscInvoice = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const invoiceId = searchParams.get('invoiceId');

  const reduxState = useSelector(({ misc }) => {
    return {
      miscData: misc.miscData,
      loading: misc.saveLoading,
      miscDetails: misc.miscDetails,
    };
  });
  let { miscData, loading, miscDetails } = reduxState;
  const initialState = {
    clientName: '',
    mobileNumber: '',
    product: '',
    description: '',
    comments: '',
  };
  const [values, setValues] = useState(initialState);
  const { clientName, mobileNumber, product, description, comments } = values;
  const [qty, setqty] = useState(0);
  const [rate, setrate] = useState(0);
  const [errors, seterrors] = useState({});
  const [misListError, setMiscListError] = useState({});
  const [totalfee, settotalfee] = useState(0);
  const [grandTotal, setgrandTotal] = useState(0);
  const [total, settotal] = useState(0);
  const [balance, setbalance] = useState(0);
  const [amountList, setamountList] = useState([0]);
  const [refrenceIdList, setrefrenceIdList] = useState(['']);
  const [paymentList, setpaymentList] = useState([
    {
      amountPaid: 0,
      paymentType: '',
    },
  ]);
  const [paymentListError, setpaymentListError] = useState({});

  useEffect(() => {
    let total = Number(qty) * Number(rate);
    total = total.toFixed(2);
    settotalfee(total);
    return () => {};
  }, [rate, qty]);

  useEffect(() => {
    if (!miscData) return;
    if (miscData?.length === 0) return;
    var reducedArray = miscData.reduce(function (accumulator, item) {
      accumulator = Number(accumulator) + Number(item['amount']);
      return Number(accumulator);
    }, 0);
    setgrandTotal(reducedArray);
    setbalance(reducedArray - total);
    return () => {};
  }, [miscData]);

  useEffect(() => {
    if (invoiceId) {
      dispatch(getMiscDetails({ invoiceId }));
    }
    return () => {
      dispatch({ type: MiscConstants.GET_MISC_DETAILS, loading: false, data: {} });
      dispatch({
        type: MiscConstants.ADD_MISC,
        miscData: [],
      });
    };
  }, []);
  useEffect(() => {
    if (invoiceId && miscDetails && Object.values(miscDetails)?.length !== 0) {
      setValues({
        clientName: miscDetails?.clientName,
        mobileNumber: miscDetails?.clientMobile,
        product: '',
        description: '',
        comments: miscDetails?.comments,
      });
      console.log(miscDetails);
      dispatch({
        type: MiscConstants.ADD_MISC,
        miscData: miscDetails?.productData,
      });

      let tempList = miscDetails?.paymentMethod?.map(({ paidAmount }) => paidAmount);
      let tempPaymentList = miscDetails?.paymentMethod?.map(({ paymentMethod, paidAmount }) => {
        return { amountPaid: paidAmount, paymentType: paymentMethod };
      });
      const tempRefList = miscDetails?.paymentMethod?.map(({ referenceNumber }) => referenceNumber);
      setrefrenceIdList(tempRefList);
      setpaymentList(tempPaymentList);
      setamountList(tempList);
      settotal(parseFloat(miscDetails?.totalAmount).toFixed(2));
    }
    return () => {};
  }, [miscDetails]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleChangePaymentMethod = (e, index, amountPaid = '') => {
    let newArray = [...paymentList];
    newArray[index] = { amountPaid, paymentType: e.value };
    setpaymentList(newArray);
  };
  const handleChangePayment = (enterAmount, index) => {
    let newArray = [...amountList];
    newArray.splice(parseInt(index), 1, enterAmount);
    var reducedArray = newArray.reduce(function (accumulator, item) {
      accumulator = accumulator + Number(item);
      return accumulator;
    }, 0);
    const newPaymentTotal = reducedArray;
    const newBalance = grandTotal - newPaymentTotal;
    if (newBalance < 0) return;
    setamountList(newArray);
    settotal(reducedArray);
    setbalance(newBalance);
  };
  const handleChangeReferenceId = (refId, index) => {
    let tempRefList = [...refrenceIdList];
    tempRefList.splice(parseInt(index), 1, refId);
    setrefrenceIdList(tempRefList);
  };

  const handleAddPaymentMethod = () => {
    if (paymentList.length === PaymentMethodList.length) return;
    setpaymentList([...paymentList, { amountPaid: 0, paymentType: '' }]);
    setamountList([...amountList, 0]);
    setrefrenceIdList([...refrenceIdList, '']);
  };
  const handleRemovePaymentMethod = (index) => {
    const paymentMethodArr = [...paymentList];
    paymentMethodArr.splice(index, 1);
    let newArray = [...amountList];
    newArray.splice(index, 1);
    setpaymentList(paymentMethodArr);
    setamountList(newArray);
    // refrence id list
    let newRefList = [...refrenceIdList];
    newRefList.splice(index, 1);
    setrefrenceIdList(newRefList);
    var reducedArray = newArray.reduce(function (accumulator, item) {
      accumulator = accumulator + item;
      return accumulator;
    }, 0);
    let tempObject = { ...paymentListError };
    delete tempObject[index];
    setpaymentListError(tempObject);
    settotal(reducedArray);
    setbalance(grandTotal - reducedArray);
  };
  const checkAvailability = async (e) => {
    e.preventDefault();
    let validationValues = {
      product,
      description,
      qty,
      rate,
      totalfee,
    };
    setMiscListError(miscValidate(validationValues));
    if (Object.values(miscValidate(validationValues)).length != 0) return;
    addTicketData();
  };
  const addTicketData = (e) => {
    let MiscObject = {
      productName: product.toUpperCase(),
      description: description.toUpperCase(),
      rate: Number(rate).toFixed(2),
      quantity: qty,
      amount: Number(totalfee).toFixed(2),
    };
    dispatch({
      type: MiscConstants.ADD_MISC,
      miscData: [...miscData, MiscObject],
    });
    setValues({
      product: '',
      description: '',
      clientName,
      mobileNumber,
      comments,
    });
    setrate(0);
    setqty(0);
  };

  const addInvoiceData = async (e) => {
    e.preventDefault();
    let validationValues = {
      clientName: clientName.toUpperCase(),
      clientMobile: mobileNumber,
      productData: miscData,
      comments,
      paymentMethod: paymentList.map((element, index) => {
        return {
          amountPaid: amountList[index],
          paymentType: element.paymentType,
          referenceNumber: refrenceIdList[index] || null,
        };
      }),
    };
    let isError = false;
    let mainTempError = { ...paymentListError };
    paymentList.forEach((element, index) => {
      let tempObject = { ...mainTempError };
      tempObject[index] = {
        amountPaid: '',
        paymentType: '',
      };
      if (amountList[index] === '' || amountList[index] == 0) {
        tempObject[index].amountPaid = 'Either enter payment or remove it';
        isError = true;
      }
      if (element.paymentType === '') {
        tempObject[index].paymentType = 'Either select method or remove it';
        isError = true;
      }
      if ((amountList[index] !== '' || amountList[index] !== 0) && element.paymentType !== '') {
        delete tempObject[index];
      }
      mainTempError = { ...tempObject };
    });
    setpaymentListError(mainTempError);
    if (balance > 0) return alert.error('Balance Amount should be zero');
    seterrors(miscInvoiceValidate(validationValues));
    console.log(miscInvoiceValidate(validationValues));
    if (Object.values(miscInvoiceValidate(validationValues)).length != 0 || isError) return;
    if (miscData?.length == 0) return alert.error('Please add services');
    if (invoiceId) {
      dispatch(editMisc(validationValues, invoiceId, addInvoiceCallback));
    } else {
      dispatch(saveMiscInvoice(validationValues, addInvoiceCallback));
    }
  };

  const addInvoiceCallback = ({ error, message, data }) => {
    if (error) {
      message?.error && alert.error(message?.error);
      return;
    }
    if (data?.status == 'failure') {
      data?.message && alert.error(data?.message);
      return;
    }

    if (invoiceId) {
      alert.success('Invoice updated Successfully');
    } else {
      alert.success('Invoice added Successfully');
    }

    dispatch({
      type: MiscConstants.ADD_MISC,
      miscData: [],
    });
    navigate(`/misc-invoice-print?invoiceId=${data.invoiceId}`);
  };
  const handleEditMiscRow = (data) => {
    setValues({
      ...values,
      product: data.productName,
      description: data.description,
    });
    setqty(data.quantity);
    setrate(data.rate);
    settotalfee(data.amount);
    let copyArray = [...miscData];
    copyArray.splice(data.index, 1);
    dispatch({
      type: MiscConstants.ADD_MISC,
      miscData: copyArray,
    });
  };
  return (
    <div className='visa_invoice'>
      {/* client details section start */}
      <section>
        <CommonHeading heading={'Miscellaneous'} tag={'Please add details of your clients'} />
      </section>
      {invoiceId && <section style={{marginTop:"20px"}}>
        <h3>Invoice No: <span>{invoiceId}</span></h3>
      </section>}
      {/* header section end */}
      <div className='visa_invoice_second'>
        {/* client details section start */}
        <section className='visa_invoice_details_div' style={{ marginBottom: '50px' }}>
          <section className='visa_invoice_input_div'>
            <h5>Client Name</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='text'
                placeholder='Client Name'
                value={clientName}
                name={'clientName'}
                onChange={handleChangeInput}
              />
            </div>
            {errors?.clientName && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={errors?.clientName} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Mobile Number</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='number'
                placeholder='Mobile Number'
                value={mobileNumber}
                name={'mobileNumber'}
                onChange={handleChangeInput}
              />
            </div>
            {errors?.clientMobile && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={errors?.clientMobile} />
              </div>
            )}
          </section>{' '}
          <br />
        </section>
        <CommonGreenHeading heading={'Ticket Details'} />
        {/* client details section end */}
        {/* members details section start */}
        <section className='visa_invoice_details_div'>
          <section className='visa_invoice_input_div'>
            <h5>Product/Service</h5>
            <div className='visa_invoice_input_inner'>
              <input type='text' placeholder='Service' value={product} name={'product'} onChange={handleChangeInput} />
            </div>
            {misListError?.product && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={misListError?.product} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Description</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='text'
                placeholder='Description'
                value={description}
                name={'description'}
                onChange={handleChangeInput}
              />
            </div>
            {misListError?.description && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={misListError?.description} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Quantity</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='number'
                placeholder='Quantity'
                value={qty}
                name={'qty'}
                onChange={(e) => setqty(e.target.value)}
              />
            </div>
            {misListError?.qty && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={misListError?.qty} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Rate (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                type='number'
                placeholder='Rate'
                value={rate}
                name={'rate'}
                onChange={(e) => setrate(e.target.value)}
              />
            </div>
            {misListError?.rate && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={misListError?.rate} />
              </div>
            )}
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Amount (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                disabled
                type='number'
                placeholder='Amount'
                value={totalfee}
                name={'totalfee'}
                onChange={(e) => settotalfee(e.target.value)}
              />
            </div>
            {misListError?.gst && (
              <div style={{ marginTop: '10px' }}>
                <ErrorAlert error={misListError?.gst} />
              </div>
            )}
          </section>
        </section>
        <section style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
          <DashedButton onClick={checkAvailability}> +Add to List </DashedButton>
        </section>
        {/* members details section end */}
      </div>

      {/* passport details section start */}
      <section style={{ marginBottom: '40px' }}>
        <MiscInvoiceTable callback={(e) => handleEditMiscRow(e)} />
        {errors?.passports && (
          <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
            <ErrorAlert error={errors?.passports} />
          </div>
        )}
      </section>
      <CommonGreenHeading heading={'Charges'} />
      {/* passport details section end */}
      {/* charges and commonets details section start */}
      <section>
        <section className='visa_invoice_input_div'>
          <h5>Grand Total (S$)</h5>
          <div className='visa_invoice_input_inner'>
            <input
              disabled
              type='number'
              placeholder='Total '
              value={grandTotal}
              name={'grandTotal'}
              onChange={(e) => setgrandTotal(e.target.value)}
            />
          </div>
        </section>
        {paymentList?.map(({ amountPaid, paymentType }, index) => (
          <div key={amountPaid + paymentType + index} className='visa_invoice_details_div'>
            <section className='visa_invoice_input_div' style={{ width: '230px' }}>
              <h5>Payment Method</h5>
              <div style={{ marginTop: '5px' }}>
                <RectSelect
                  defaultValue={{ key: index + amountPaid, label: paymentType || 'Select', value: paymentType }}
                  data={PaymentMethodList}
                  callback={(e) => handleChangePaymentMethod(e, index)}
                />
              </div>
              {paymentListError[index] && paymentListError[index].paymentType && (
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                  <ErrorAlert error={paymentListError[index].paymentType} />
                </div>
              )}
            </section>
            <section className='visa_invoice_input_div' style={{ width: '220px' }}>
              <h5>Paid Amount (S$)</h5>
              <div className='visa_invoice_input_inner'>
                <input
                  type='number'
                  placeholder='Total '
                  value={amountList[index]}
                  onChange={(e) => handleChangePayment(e.target.value, index)}
                />
              </div>
              {paymentListError[index] && paymentListError[index].amountPaid && (
                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                  <ErrorAlert error={paymentListError[index].amountPaid} />
                </div>
              )}
            </section>
            {paymentType !== 'CASH' && (
              <section className='visa_invoice_input_div' style={{ width: '220px' }}>
                <h5>Reference Id</h5>
                <div className='visa_invoice_input_inner'>
                  <input
                    type='text'
                    placeholder='Reference ID '
                    value={refrenceIdList[index]}
                    onChange={(e) => handleChangeReferenceId(e.target.value, index)}
                  />
                </div>
                {paymentListError[index] && paymentListError[index]?.refrenceId && (
                  <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                    <ErrorAlert error={paymentListError[index]?.refrenceId} />
                  </div>
                )}
              </section>
            )}
            {paymentList?.length !== 1 && (
              <section className='visa_invoice_input_div' style={{ marginTop: '55px' }}>
                <DashedButton sx={{ py: 50 }} onClick={() => handleRemovePaymentMethod(index)}>
                  - Remove
                </DashedButton>
              </section>
            )}
          </div>
        ))}
        {paymentList?.length !== PaymentMethodList.length && (
          <section className='visa_invoice_input_div' style={{ marginTop: '55px' }}>
            <DashedButton sx={{ py: 50 }} onClick={handleAddPaymentMethod}>
              +Add Payment Option{' '}
            </DashedButton>
          </section>
        )}
        <section style={{ display: 'flex', flexWrap: 'wrap' }}>
          <section className='visa_invoice_input_div'>
            <h5>Total (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                disabled
                type='number'
                placeholder='Total '
                value={total}
                name={'total'}
                onChange={(e) => settotal(e.target.value)}
              />
            </div>
          </section>
          <section className='visa_invoice_input_div'>
            <h5>Balance (S$)</h5>
            <div className='visa_invoice_input_inner'>
              <input
                disabled
                type='number'
                placeholder='Balance '
                value={balance}
                name={'balance'}
                onChange={(e) => setbalance(e.target.value)}
              />
            </div>
          </section>
        </section>
        <section>
          {errors?.totalError && (
            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
              <ErrorAlert error={errors?.totalError} />
            </div>
          )}
        </section>
      </section>

      {/* charges and commonets details section end */}
      <section className='visa_invoice_comments'>
        <textarea
          type='text'
          placeholder='Add comments(if any)'
          value={comments}
          name='comments'
          onChange={handleChangeInput}
        />
      </section>
      {Object.values(errors).length != 0 && (
        <div style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
          <ErrorAlert error={'Please resolve all errors'} />
        </div>
      )}
      <section style={{ marginTop: '40px', display: 'flex', justifyContent: 'center' }}>
        <div style={{ minWidth: '280px' }}>
          {loading ? (
            <FullSecondButton>
              <LoaderOne />
            </FullSecondButton>
          ) : (
            <FullSecondButton onClick={addInvoiceData}>Submit </FullSecondButton>
          )}
        </div>
      </section>
    </div>
  );
};

export default MiscInvoice;
