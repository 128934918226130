import moment from 'moment';
import * as XLSX from 'xlsx-js-style';
import PaymentMethodList from '../../../utils/Constants/PaymentMethods.constants';
import { showPayment } from '../../../utils/helpers';

const TicketExporToExcel = async (props) => {
  // extracting props
  let { data, from, to, paymentTotal, totalAmount } = props;
  //   if (data.length == 0) return;
  // file header, and required variable
  const subHeaderStyle = {
    alignment: { horizontal: 'center' },
    font: { bold: true, sz: '13' },
  };

  const title = [
    { A: { v: 'SKY CENTRE AIR TRAVEL PTE LTD', s: subHeaderStyle } },
    { A: { v: 'TICKET INVOICES STATEMENTS', s: subHeaderStyle } },
    { A: { v: `From ${from} To ${to}`, s: subHeaderStyle } },
    { A: { v: `Total. S$ ${totalAmount.toFixed(2)}`, s: subHeaderStyle } },
  ];
  var currentDate = moment(new Date(Date.now())).format('DD-MM-YYYY');
  let table1 = [];
  table1 = title.concat(table1);
  const secondHeaderStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
    font: { bold: true, sz: '12' },
  };
  let firstheader = {
    A: { v: 'S/NO.', s: secondHeaderStyle },
    B: { v: '', s: secondHeaderStyle },
    C: { v: '', s: secondHeaderStyle },
    D: { v: '', s: secondHeaderStyle },
    E: { v: '', s: secondHeaderStyle },
    F: { v: '', s: secondHeaderStyle },
    G: { v: '', s: secondHeaderStyle },
    H: { v: '', s: secondHeaderStyle },
    I: { v: '', s: secondHeaderStyle },
    J: { v: '', s: secondHeaderStyle },
    K: { v: '', s: secondHeaderStyle },
    L: { v: '', s: secondHeaderStyle },
    M: { v: 'DATE', s: secondHeaderStyle },
    N: { v: currentDate, s: secondHeaderStyle },
  };
  table1.push(firstheader);
  let secondheader = {
    A: { v: 'ITEM', s: secondHeaderStyle },
    B: { v: 'NAME', s: secondHeaderStyle },
    C: { v: 'INVOICE', s: secondHeaderStyle },
    D: { v: 'DATE', s: secondHeaderStyle },
    E: { v: 'FARE', s: secondHeaderStyle },
    F: { v: 'TAXES', s: secondHeaderStyle },
    G: { v: 'ST FEE', s: secondHeaderStyle },
    H: { v: 'GST', s: secondHeaderStyle },
    I: { v: 'TOTAL (S$)', s: secondHeaderStyle },
    J: { v: 'CASH (S$)', s: secondHeaderStyle },
    K: { v: 'CHEQUE (S$)', s: secondHeaderStyle },
    L: { v: 'NETS (S$)', s: secondHeaderStyle },
    M: { v: 'PAYNOW (S$)', s: secondHeaderStyle },
    N: { v: 'CREDIT CARD (S$)', s: secondHeaderStyle },
  };
  table1.push(secondheader);
  // pushing body
  const bodyStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
  };
  const nameStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'left' },
  };

  for (let i in data) {
    let cash = showPayment(PaymentMethodList[0].value, data[i].paymentData)
    //  data[i].paymentData.map(({ paymentMethod, paidAmount }, index) => {
    //   return paymentMethod === PaymentMethodList[0].value ? paidAmount : 0;
    // });
    let cheque = showPayment(PaymentMethodList[1].value, data[i].paymentData)
    let nets = showPayment(PaymentMethodList[2].value, data[i].paymentData)
    let paynow = showPayment(PaymentMethodList[3].value, data[i].paymentData)
    let creditCard = showPayment(PaymentMethodList[4].value, data[i].paymentData)
    table1.push({
      A: { v: parseInt(i) + 1, s: bodyStyle },
      B: { v: data[i].clientName, s: nameStyle },
      C: { v: data[i].invoiceId, s: bodyStyle },
      D: { v: moment(data[i].invoiceDate).format('DD/MM/YYYY'), s: bodyStyle },
      E: { v: data[i].fare.toFixed(2), s: bodyStyle },
      F: { v: data[i].taxes.toFixed(2), s: bodyStyle },
      G: { v: data[i].skyTravelFee.toFixed(2), s: bodyStyle },
      H: { v: data[i].gstAmount, s: bodyStyle },
      I: { v: data[i].totalAmount.toFixed(2), s: bodyStyle },
      J: { v: cash || 0, s: bodyStyle },
      K: { v: cheque || 0, s: bodyStyle },
      L: { v: nets || 0, s: bodyStyle },
      M: { v: paynow || 0, s: bodyStyle },
      N: { v: creditCard || 0, s: bodyStyle },
    });
  }

  let totalFooter = {
    A: { v: 'TOTAL', s: secondHeaderStyle },
    B: { v: '', s: secondHeaderStyle },
    C: { v: '', s: secondHeaderStyle },
    D: { v: '', s: secondHeaderStyle },
    E: { v: Number(paymentTotal?.fare).toFixed(2), s: secondHeaderStyle },
    F: { v: Number(paymentTotal?.taxes).toFixed(2), s: secondHeaderStyle },
    G: { v: Number(paymentTotal?.skyTravelFee).toFixed(2), s: secondHeaderStyle },
    H: { v: Number(paymentTotal?.gstAmount).toFixed(2), s: secondHeaderStyle },
    I: { v: Number(paymentTotal?.totalAmount).toFixed(2), s: secondHeaderStyle },
    J: { v: Number(paymentTotal?.cash).toFixed(2), s: secondHeaderStyle },
    K: { v: Number(paymentTotal?.cheque).toFixed(2), s: secondHeaderStyle },
    L: { v: Number(paymentTotal?.nets).toFixed(2), s: secondHeaderStyle },
    M: { v: Number(paymentTotal?.paynow).toFixed(2), s: secondHeaderStyle },
    N: { v: Number(paymentTotal?.creditCard).toFixed(2), s: secondHeaderStyle },
  };
  table1.push(totalFooter);

  // create a web book
  const wb = XLSX.utils.book_new();
  //create a worksheet
  const sheet = XLSX.utils.json_to_sheet(table1, { skipHeader: true });
  // merging cells
  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 13 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 13 } },
    { s: { r: 2, c: 0 }, e: { r: 2, c: 13 } },
    { s: { r: 3, c: 0 }, e: { r: 3, c: 13 } },
  ];
  // cells width
  var wscols = [
    { wch: 5 },
    { wch: 25 },
    { wch: 12 },
    { wch: 12 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 10 },
    { wch: 12 },
    { wch: 15 },
  ];
  sheet['!merges'] = merge;
  sheet['!cols'] = wscols;

  XLSX.utils.book_append_sheet(wb, sheet, 'TICKET_STATEMENT');

  // const workBookBlob = workbook2blob(wb);
  // return workBookBlob;

  return XLSX.writeFile(wb, `Ticket-Statement_${currentDate}.xlsx`);
};

export default TicketExporToExcel;
