import React from "react";

const ReportIcon = ({ width = 35, height = 35, color = "#809FB8" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_476_119"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="35"
        height="35"
      >
        <path d="M0 0H35V35H0V0Z" fill={color} />
      </mask>
      <g mask="url(#mask0_476_119)">
        <path
          d="M2.97092 17.493C2.97092 13.1153 2.97092 8.73722 2.97092 4.35951C2.97127 3.75154 3.31359 3.2449 3.83763 3.06785C4.59903 2.81044 5.40094 3.31245 5.50906 4.11774C5.52632 4.24645 5.53089 4.37728 5.53089 4.50741C5.53195 12.7181 5.53265 20.9289 5.52737 29.1396C5.52737 29.3266 5.57597 29.3689 5.75699 29.3689C13.7609 29.3643 21.7648 29.3661 29.7688 29.3625C30.3449 29.3625 30.7683 29.5983 31.0144 30.1202C31.4222 30.9845 30.8042 31.9793 29.8572 31.9996C29.822 32.0003 29.7867 31.9999 29.7515 31.9999C21.2841 31.9999 12.8167 31.9999 4.34899 31.9999C3.61963 31.9999 3.10827 31.5793 2.98571 30.875C2.96775 30.7712 2.97092 30.6627 2.97092 30.5564C2.97092 26.2011 2.97092 21.8472 2.97092 17.493Z"
          fill={color}
        />
        <path
          d="M21.8739 5.11537C24.0972 4.60944 26.2888 4.11098 28.5096 3.60541C28.4659 3.87526 28.4272 4.12947 28.3835 4.38261C28.0472 6.32241 27.7098 8.26221 27.3728 10.2017C27.3657 10.2418 27.3759 10.2955 27.3361 10.3147C27.2812 10.3414 27.2573 10.2795 27.227 10.2504C26.5776 9.63316 25.9274 9.01737 25.284 8.39376C25.1759 8.28888 25.122 8.28141 25.0121 8.39767C22.7934 10.7463 20.5684 13.0893 18.3496 15.4383C18.2348 15.5599 18.176 15.5489 18.0616 15.4433C17.2079 14.6569 16.3447 13.8811 15.4928 13.0929C15.3649 12.9745 15.304 12.9901 15.1917 13.1071C12.9039 15.4959 10.6109 17.8798 8.32351 20.2687C8.21187 20.3853 8.15094 20.3885 8.03966 20.2733C7.72868 19.9508 7.40926 19.6365 7.08455 19.3279C6.98806 19.2362 6.99263 19.1868 7.08279 19.0932C9.19691 16.8975 11.3082 14.6988 13.4195 12.5006C13.9995 11.8969 14.5813 11.2946 15.1568 10.6866C15.2431 10.5956 15.2903 10.5991 15.3794 10.6806C16.2521 11.4812 17.1304 12.2755 18.002 13.0772C18.1028 13.17 18.1538 13.1707 18.2493 13.0694C20.0943 11.1136 21.9426 9.16101 23.7932 7.21089C23.8852 7.11419 23.8971 7.06264 23.7915 6.96309C23.1731 6.38179 22.5627 5.79231 21.9499 5.20496C21.9295 5.18505 21.9126 5.16123 21.8739 5.11537Z"
          fill={color}
        />
        <path
          d="M24.6995 20.8232C24.6995 18.3416 24.7017 15.8603 24.6953 13.3787C24.695 13.2034 24.7429 13.1657 24.9094 13.1675C25.7716 13.1764 26.634 13.1739 27.4965 13.1693C27.6251 13.1685 27.6793 13.1885 27.6793 13.3399C27.6751 18.3206 27.6751 23.3013 27.679 28.282C27.679 28.4267 27.6335 28.4566 27.4997 28.4555C26.6256 28.4498 25.7515 28.4488 24.8774 28.4562C24.7277 28.4576 24.6964 28.4111 24.6967 28.2674C24.7009 25.7858 24.6999 23.3045 24.6999 20.8229L24.6995 20.8232Z"
          fill={color}
        />
        <path
          d="M19.3558 22.5081C19.3558 20.6014 19.3576 18.6947 19.3523 16.7879C19.3519 16.6333 19.3864 16.5899 19.5442 16.5917C20.4123 16.6002 21.2804 16.5988 22.1486 16.5927C22.2887 16.5917 22.337 16.619 22.3366 16.7737C22.332 20.605 22.332 24.4362 22.3359 28.2675C22.3359 28.4118 22.3039 28.458 22.1538 28.4566C21.2917 28.4491 20.4292 28.4484 19.5671 28.457C19.4012 28.4587 19.3509 28.4221 19.3516 28.2461C19.3583 26.3333 19.3558 24.4209 19.3558 22.5081Z"
          fill={color}
        />
        <path
          d="M16.7887 23.7369C16.7887 25.2472 16.787 26.7579 16.7919 28.2682C16.7923 28.4126 16.7588 28.4577 16.6095 28.4567C15.7354 28.4492 14.8609 28.4495 13.9868 28.4567C13.846 28.4577 13.8125 28.4172 13.8125 28.2785C13.8164 25.2458 13.8164 22.2131 13.8125 19.1807C13.8125 19.0456 13.8407 18.9983 13.9847 18.9994C14.8588 19.0065 15.7333 19.0065 16.6074 18.9994C16.7563 18.9983 16.7916 19.0431 16.7912 19.1886C16.7863 20.7049 16.788 22.2213 16.788 23.7376L16.7887 23.7369Z"
          fill={color}
        />
        <path
          d="M8.86451 25.2284C8.86451 24.2154 8.86732 23.2022 8.86134 22.1892C8.86063 22.0474 8.90078 22.0115 9.03848 22.0125C9.91857 22.0186 10.799 22.0186 11.6791 22.0125C11.8143 22.0115 11.8439 22.0531 11.8436 22.1832C11.8397 24.2151 11.8393 26.2473 11.8439 28.2792C11.8439 28.4207 11.8059 28.457 11.6675 28.4563C10.7874 28.4499 9.90695 28.4499 9.02686 28.4563C8.89127 28.4573 8.86098 28.415 8.86169 28.2852C8.86662 27.2663 8.86451 26.2473 8.86451 25.2284Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ReportIcon;
