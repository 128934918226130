import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router';
import { Link } from 'react-router-dom';
import UsersRoles from '../../utils/Constants/UserRoles.constants';
import SidebarSubHeading from './SidebarSubHeading';

const SidebarHeadings = ({ Icon, label, link, subItem }) => {
  const [active, setactive] = useState(false);
  const reduxState = useSelector(({ users }) => {
    return {
      user: users.user,
    };
  });
  const { user } = reduxState;
  useEffect(() => {
    if (window.location.pathname == link) {
      setactive(true);
    } else {
      setactive(false);
      if (!subItem) return;
      subItem.forEach(({ label, link }) => {
        if (window.location.pathname == link) {
          setactive(true);
        }
        return;
      });
      return;
    }
  }, [window.location.pathname]);

  return (
    <div>
      <Link to={link}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: active ? '#fff' : '#395063',
            backgroundColor: active ? '#528dc8' : '#fff',
          }}
          className='sidebar_heading_component'
        >
          <Icon width='15' height='15' color={active ? '#fff' : '#395063'} /> <h4>{label}</h4>
        </div>
      </Link>
      {subItem &&
        subItem.map(({ label, icon, link }, index) => (
          <div
            key={label + link + index}
            style={{
              marginTop: '13px',
              display: user?.role !== UsersRoles.ROLE_ADMIN && label === 'Create/Manage Users' ? 'none' : 'flex',
            }}
          >
            <SidebarSubHeading Icon={icon} label={label} link={link} />
          </div>
        ))}
    </div>
  );
};

export default SidebarHeadings;
