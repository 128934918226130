import React from "react";

const VisaPrintTopItem = ({ heading, label, isMain = false }) => {
  return (
    <div style={{ display: "flex", fontSize: "12px", fontWeight: isMain ? "800" : "500", marginTop: "5px" }}>
      <span style={{ textTransform: "uppercase", minWidth: "90px" }}>{heading}</span>:{" "}
      <span style={{ marginLeft: "10px" }}>{label}</span>
    </div>
  );
};

export default VisaPrintTopItem;
