import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { AllButton, CommonModal, TableStyle } from '../../../uiComponents';
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import VisaInvoiceConstant from '../../../store/constants/VisaInvoice.constants';
import Ticket_Constants from '../../../store/constants/Ticket';
import { FcEditImage } from 'react-icons/fc';
const { StyledTableCell, StyledTableRow } = TableStyle;
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const { BorderSecondButton } = AllButton;

const TicketInvoiceRow = (props) => {
  const dispatch = useDispatch();
  const reduxState = useSelector(({ tickets }) => {
    return {
      ticketsData: tickets.ticketsData,
      loading: tickets.saveLoading,
    };
  });
  let { ticketsData, loading } = reduxState;
  const [showModal, setShowModal] = useState(false);
  const {
    paxName,
    ticketNumber,
    paxType,
    departureDate,
    flightNumber,
    route,
    fare,
    taxes,
    skyTravelFee,
    gstAmount,
    totalAmount,
    callback,
  } = props;
  const addComment = (e) => {
    e.preventDefault();
  };

  const handleRemoveRow = (e) => {
    e.preventDefault();
    let copyArray = ticketsData.filter((data) => data.ticketNumber != ticketNumber);

    dispatch({
      type: Ticket_Constants.ADD_TICKETS,
      ticketsData: copyArray,
    });
  };

  const updateModal = () => {
    return (
      <CommonModal showModal={showModal} callback={(e) => setShowModal(false)}>
        <div>
          <section>
            <section className='' style={{ fontSize: '20px', padding: '30px' }}>
              <h5>Are you sure you want to delete it ?</h5>
            </section>

            <section style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <BorderSecondButton sx={{ fontSize: '14px', marginRight: '15px' }} onClick={() => setShowModal(false)}>
                  cancel
                </BorderSecondButton>
              </div>
              <div>
                <BorderSecondButton
                  sx={{
                    fontSize: '14px',
                    marginLeft: '15px',
                    background: '#FF3131',
                    borderColor: '#FF3131',
                    color: '#fff',
                  }}
                  onClick={handleRemoveRow}
                >
                  Delete
                </BorderSecondButton>
              </div>
            </section>
          </section>
        </div>
      </CommonModal>
    );
  };

  return (
    <>
      {showModal && updateModal()}
      <StyledTableRow>
        <StyledTableCell
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            callback({
              paxName,
              ticketNumber,
              paxType,
              departureDate,
              flightNumber,
              route,
              fare,
              taxes,
              skyTravelFee,
              gstAmount,
              totalAmount,
            })
          }
        >
          {paxName}
        </StyledTableCell>
        <StyledTableCell align='center'>{paxType}</StyledTableCell>
        <StyledTableCell
          align='center'
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            callback({
              paxName,
              ticketNumber,
              paxType,
              departureDate,
              flightNumber,
              route,
              fare,
              taxes,
              skyTravelFee,
              gstAmount,
              totalAmount,
            })
          }
        >
          {ticketNumber}
        </StyledTableCell>
        <StyledTableCell align='center'>{departureDate}</StyledTableCell>
        <StyledTableCell align='center'>{flightNumber}</StyledTableCell>
        <StyledTableCell align='center'>{route}</StyledTableCell>
        <StyledTableCell align='center'>{fare}</StyledTableCell>
        <StyledTableCell align='center'>{taxes}</StyledTableCell>
        <StyledTableCell align='center'>{skyTravelFee}</StyledTableCell>
        {/* <StyledTableCell align='center'>{gstAmount}</StyledTableCell> */}
        <StyledTableCell align='center'>{totalAmount}</StyledTableCell>
        <StyledTableCell align='center'>
          <IconButton
            onClick={() =>
              callback({
                paxName,
                ticketNumber,
                paxType,
                departureDate,
                flightNumber,
                route,
                fare,
                taxes,
                skyTravelFee,
                gstAmount,
                totalAmount,
              })
            }
          >
            <FcEditImage style={{ fontSize: '20px' }} />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align='center'>
          <IconButton onClick={(e) => setShowModal(true)}>
            <IoClose style={{ fontSize: '20px', color: 'red' }} />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default TicketInvoiceRow;
