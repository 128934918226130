import React from "react";

const PassportIcon = ({ width = 28, height = "28", color = "whitw" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.834 24.5H8.16732C3.50065 24.5 2.33398 23.3333 2.33398 18.6667V9.33333C2.33398 4.66667 3.50065 3.5 8.16732 3.5H19.834C24.5007 3.5 25.6673 4.66667 25.6673 9.33333V18.6667C25.6673 23.3333 24.5007 24.5 19.834 24.5Z"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.334 9.33333H22.1673"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.5 14H22.1667" stroke="white" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19.834 18.6667H22.1673"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.91635 13.1716C11.0826 13.1716 12.028 12.2261 12.028 11.0599C12.028 9.89367 11.0826 8.94824 9.91635 8.94824C8.75011 8.94824 7.80469 9.89367 7.80469 11.0599C7.80469 12.2261 8.75011 13.1716 9.91635 13.1716Z"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0007 19.0514C13.8373 17.3597 12.4957 16.0297 10.804 15.8781C10.2207 15.8197 9.62565 15.8197 9.03065 15.8781C7.33898 16.0414 5.99732 17.3597 5.83398 19.0514"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PassportIcon;
