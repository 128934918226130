import React from "react";

const DashboardSummaryItem = ({ Icon, label, value, backColor }) => {
  return (
    <div
      className=""
      style={{
        border: "1px solid #E6EDFF",
        borderRadius: "10px",
        display: "flex",
        padding: "10px",
        whiteSpace: "nowrap",
      }}
    >
      <section
        style={{
          background: backColor,
          padding: "8px",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon />
      </section>
      <section style={{ marginLeft: "15px" }}>
        <p style={{ color: "#809FB8", fontSize: "16px", fontWeight: "600" }}>{label}</p>
        <h3 style={{ color: "#06152B", fontSize: "30px", fontWeight: "800", marginTop: "2px" }}>{value}</h3>
      </section>
    </div>
  );
};

export default DashboardSummaryItem;
