import moment from 'moment';
import * as XLSX from 'xlsx-js-style';
import PaymentMethodList from '../../../utils/Constants/PaymentMethods.constants';
// import * as XlsxPopulate from 'xlsx-populate';
import { showPayment } from '../../../utils/helpers';

const VisaExporToExcel = async (props) => {
  // extracting props
  let { data, totalAmount, from, to, paymentTotals } = props;
  //   if (data.length == 0) return;
  const subHeaderStyle = {
    alignment: { horizontal: 'center' },
    font: { bold: true, sz: '13' },
  };

  const title = [
    { A: { v: 'SKY CENTRE AIR TRAVEL PTE LTD', s: subHeaderStyle } },
    { A: { v: 'VISA INVOICES STATEMENTS', s: subHeaderStyle } },
    { A: { v: `From ${from} To ${to}`, s: subHeaderStyle } },
    { A: { v: `Total. S$ ${totalAmount}`, s: subHeaderStyle } },
  ];
  var currentDate = moment(new Date(Date.now())).format('DD-MM-YYYY');
  let table1 = [];
  table1 = title.concat(table1);
  const secondHeaderStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
    font: { bold: true, sz: '12' },
  };
  let firstheader = {
    A: { v: 'S/NO.', s: secondHeaderStyle },
    B: { v: '', s: secondHeaderStyle },
    C: { v: '', s: secondHeaderStyle },
    D: { v: '', s: secondHeaderStyle },
    E: { v: '', s: secondHeaderStyle },
    F: { v: '', s: secondHeaderStyle },
    G: { v: '', s: secondHeaderStyle },
    H: { v: '', s: secondHeaderStyle },
    I: { v: '', s: secondHeaderStyle },
    J: { v: '', s: secondHeaderStyle },
    K: { v: '', s: secondHeaderStyle },
    L: { v: '', s: secondHeaderStyle },
    M: { v: '', s: secondHeaderStyle },
    N: { v: '', s: secondHeaderStyle },
    O: { v: 'DATE', s: secondHeaderStyle },
    P: { v: currentDate, s: secondHeaderStyle },
  };
  table1.push(firstheader);

  let secondheader = {
    A: { v: 'ITEM', s: secondHeaderStyle },
    B: { v: 'NAME', s: secondHeaderStyle },
    C: { v: 'INVOICE', s: secondHeaderStyle },
    D: { v: 'DATE', s: secondHeaderStyle },
    E: { v: 'PASSPORTS', s: secondHeaderStyle },
    F: { v: 'VISA FEE (S$)', s: secondHeaderStyle },
    G: { v: 'AGT FEE (S$)', s: secondHeaderStyle },
    H: { v: 'AGT GST (S$)', s: secondHeaderStyle },
    I: { v: 'ST FEE (S$)', s: secondHeaderStyle },
    J: { v: 'ST GST (S$)', s: secondHeaderStyle },
    K: { v: 'TOTAL (S$)', s: secondHeaderStyle },
    L: { v: 'CASH (S$)', s: secondHeaderStyle },
    M: { v: 'CHEQUE (S$)', s: secondHeaderStyle },
    N: { v: 'NETS (S$)', s: secondHeaderStyle },
    O: { v: 'PAYNOW (S$)', s: secondHeaderStyle },
    P: { v: 'CREDIT CARD (S$)', s: secondHeaderStyle },
  };
  table1.push(secondheader);
  // pushing body
  const bodyStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
  };
  const nameStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'left' },
  };
  for (let i in data) {
    let cash = showPayment(PaymentMethodList[0].value, data[i].paymentData)
    //  data[i].paymentData.map(({ paymentMethod, paidAmount }, index) => {
    //   return paymentMethod === PaymentMethodList[0].value ? paidAmount : 0;
    // });
    let cheque = showPayment(PaymentMethodList[1].value, data[i].paymentData)
    let nets = showPayment(PaymentMethodList[2].value, data[i].paymentData)
    let paynow = showPayment(PaymentMethodList[3].value, data[i].paymentData)
    let creditCard = showPayment(PaymentMethodList[4].value, data[i].paymentData)

    table1.push({
      A: { v: parseInt(i) + 1, s: bodyStyle },
      B: { v: data[i].clientName, s: nameStyle },
      C: { v: data[i].invoiceId, s: bodyStyle },
      D: { v: data[i].invoiceDate, s: bodyStyle },
      E: { v: data[i].noOfPassports, s: bodyStyle },
      F: { v: data[i].visaFee, s: bodyStyle },
      G: { v: data[i].agentFee, s: bodyStyle },
      H: { v: data[i].agentFeeGST, s: bodyStyle },
      I: { v: data[i].skyServicefee, s: bodyStyle },
      J: { v: data[i].skyServicefeeGST, s: bodyStyle },
      K: { v: data[i].totalAmount, s: bodyStyle },
      L: { v: cash || 0, s: bodyStyle },
      M: { v: cheque || 0, s: bodyStyle },
      N: { v: nets || 0, s: bodyStyle },
      O: { v: paynow || 0, s: bodyStyle },
      P: { v: creditCard || 0, s: bodyStyle },
    });
  }
  let totalFooter = {
    A: { v: 'Totals', s: secondHeaderStyle },
    B: { v: '', s: secondHeaderStyle },
    C: { v: '', s: secondHeaderStyle },
    D: { v: '', s: secondHeaderStyle },
    E: { v: '', s: secondHeaderStyle },
    F: { v: Number(paymentTotals?.visaFee).toFixed(2), s: secondHeaderStyle },
    G: { v: Number(paymentTotals?.agentFee).toFixed(2), s: secondHeaderStyle },
    H: { v: Number(paymentTotals?.agentFeeGST).toFixed(2), s: secondHeaderStyle },
    I: { v: Number(paymentTotals?.skyServicefee).toFixed(2), s: secondHeaderStyle },
    J: { v: Number(paymentTotals?.skyServicefeeGST).toFixed(2), s: secondHeaderStyle },
    K: { v: Number(paymentTotals?.totalAmount).toFixed(2), s: secondHeaderStyle },
    L: { v: Number(paymentTotals?.cash).toFixed(2), s: secondHeaderStyle },
    M: { v: Number(paymentTotals?.cheque).toFixed(2), s: secondHeaderStyle },
    N: { v: Number(paymentTotals?.nets).toFixed(2), s: secondHeaderStyle },
    O: { v: Number(paymentTotals?.paynow).toFixed(2), s: secondHeaderStyle },
    P: { v: Number(paymentTotals?.creditCard).toFixed(2), s: secondHeaderStyle },
  };
  table1.push(totalFooter);
  // create a web book

  //create a worksheet
  const sheet1 = XLSX.utils.json_to_sheet(table1, { skipHeader: true });
  // merging cells
  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 16 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 16 } },
    { s: { r: 2, c: 0 }, e: { r: 2, c: 16 } },
    { s: { r: 3, c: 0 }, e: { r: 3, c: 16 } },
  ];
  // cells width
  var wscols = [
    { wch: 5 },
    { wch: 25 },
    { wch: 12 },
    { wch: 12 },
    { wch: 10 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 15 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
  ];
  sheet1['!merges'] = merge;
  sheet1['!cols'] = wscols;

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, sheet1, 'voter_data');

  return XLSX.writeFile(wb, `visaStatements_${currentDate}.xlsx`);
};

export default VisaExporToExcel;
