import { Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { TableStyle } from '../../../uiComponents';
import VisaStatementRow from './VisaStatementRow';

const { StyledTableRow, StyledTableCellTranparent } = TableStyle;
const VisaStatementTable = () => {
  const reduxState = useSelector(({ statements }) => {
    return {
      visaData: statements.visaData,
      loading: statements.visaLoading,
    };
  });
  let { visaData, loading } = reduxState;

  return (
    <div>
      <TableContainer>
        <Table sx={{ marginBottom: '20px', minWidth: 'max-content' }} aria-label='simple table'>
          <TableHead sx={{ borderBottom: '2px solid #EBEFF2' }}>
            <StyledTableRow>
              <StyledTableCellTranparent sx={{ width: '80px' }}>Invoice </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '80px' }}>
                {' '}
                Date
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '200px' }}>
                Client Name
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>
                No. of <br />
                Passports
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '100px' }}>
                visa fee (S$)
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '80px' }}>
                AGT FEE (S$)
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '80px' }}>
                AGT GST (S$)
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '80px' }}>
                ST FEE (S$)
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '80px' }}>
                ST GST (S$)
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '80px' }}>
                Total (S$)
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Cash (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Cheque (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Nets (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Paynow (S$)</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center'>Credit Card (S$)</StyledTableCellTranparent>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {visaData?.invoices?.length > 0 &&
              visaData?.invoices?.map(
                (
                  {
                    clientMobileNumber,
                    clientName,
                    invoiceDate,
                    invoiceId,
                    noOfPassports,
                    cost,
                    sellingPrice,
                    profit,
                    userEmail,
                    userMobile,
                    userName,
                    visaFee,
                    agentFee,
                    agentFeeGST,
                    skyServicefee,
                    skyServicefeeGST,
                    totalAmount,
                    paymentData,
                  },
                  index
                ) => (
                  <VisaStatementRow
                    key={invoiceId + index + invoiceDate}
                    invoiceId={invoiceId}
                    invoiceDate={invoiceDate}
                    clientName={clientName}
                    noOfPassports={noOfPassports}
                    cost={cost}
                    sellingPrice={sellingPrice}
                    profit={profit}
                    visaFee={visaFee}
                    agentFee={agentFee}
                    agentFeeGST={agentFeeGST}
                    skyServicefee={skyServicefee}
                    skyServicefeeGST={skyServicefeeGST}
                    totalAmount={totalAmount}
                    paymentData={paymentData}
                  />
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default VisaStatementTable;
