import React, { useEffect, useRef, useState } from 'react';
import { AllButton, LoaderOne } from '../../uiComponents';
import './../../Components/Passport/styles/PassportReturn.css';
import PassportHeading from '../../Components/Passport/PassportHeading';
import { Pagination, PaginationItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Country } from 'country-state-city';
import PassportSubmissionTable from '../../Components/Passport/Submission/PassportSubmissionTable';
import RectSelect from '../../uiComponents/Select/RectSelect';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { getPassportDetails, getSubmissionList, passportSubmission } from '../../store/action/VisaInvoice';
import VisaInvoiceConstant from '../../store/constants/VisaInvoice.constants';
import AgentExcelFile from '../../Components/Passport/Submission/AgentExcelFile';
import ExporToExcel from '../../Components/Passport/Submission/ExportToExcel';
// import { makeStyles } from "@mui/styles";
const { FullSecondButton } = AllButton;
const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: '#528DC8 !important',
    color: '#fff !important',
  },
}));
const PassportSubmission = () => {
  const classes = useStyles();
  const alert = useAlert();
  const dispatch = useDispatch();
  let countries = Country.getAllCountries();
  const [country, setcountry] = useState({ key: '', label: 'Select country', value: '' });
  const [searchValue, setsearchValue] = useState('');
  const [page, setpage] = useState(1);
  const [loadingSubmission, setloadingSubmission] = useState(false);
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      passData: visaInvoice?.submissionList,
      loading: visaInvoice.submissionListLoading,
      submissionPassports: visaInvoice.submissionPassports,
      submissionPassportList: visaInvoice.submissionPassportList,
    };
  });
  let { passData, submissionPassports, submissionPassportList, loading } = reduxState;
  useEffect(() => {
    dispatch(getSubmissionList({ pageNo: page, pageSize: 50, country: country?.value, searchValue }));
    return () => {};
  }, []);

  useEffect(() => {
    if (page) {
      dispatch(getSubmissionList({ pageNo: page, pageSize: 50, country: country?.value, searchValue }));
    }
    return () => {};
  }, [page]);
  useEffect(() => {
    if (country) {
      dispatch(getSubmissionList({ pageNo: page, pageSize: 50, country: country?.value, searchValue }));
      dispatch({
        type: VisaInvoiceConstant.ADD_PASSPORT_SUBMISSION,
        submissionPassports: [],
        submissionPassportList: [],
      });
    } else {
      dispatch(getSubmissionList({ pageNo: page, pageSize: 50, country: '', searchValue }));
      dispatch({
        type: VisaInvoiceConstant.ADD_PASSPORT_SUBMISSION,
        submissionPassports: [],
        submissionPassportList: [],
      });
    }

    return () => {};
  }, [country]);

  const handleExport = async (e) => {
    try {
      e.preventDefault();
      if (submissionPassportList?.length == 0) return alert.error('please select passports');
      setloadingSubmission(true);
      console.log({submissionPassportList});
      await dispatch(passportSubmission(submissionPassportList.map(({number})=>number), passportCallback));
      ExporToExcel({
        data: submissionPassportList,
        pageNo: page,
      });
      AgentExcelFile({
        data: submissionPassportList,
        pageNo: page,
      });
      setloadingSubmission(false);
    } catch (err) {
      setloadingSubmission(false);
      alert.error(err, { type: 'error' });
    }
  };

  const passportCallback = ({ error, message, data }) => {
    if (error) {
      message?.error && alert.error(message?.error);
      return;
    }
    if (data?.status == 'failure') {
      data?.message && alert.error(data?.message);
      return;
    }
    alert.success('Passport Submitted Successfully');
    dispatch({
      type: VisaInvoiceConstant.ADD_PASSPORT_SUBMISSION,
      submissionPassports: [],
      submissionPassportList: [],
    });
    dispatch(getSubmissionList({ pageNo: page, pageSize: 50, country: '', searchValue }));
  };
  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getPassportDetails({ pageNo: page, country: country?.value || '' }));
  };

  return (
    <div className='passport_return_main_div'>
      {/* first section start */}
      <section className='passport_return_header'>
        <div>
          <PassportHeading title={'Passport Submission / Re-Submission'} />
        </div>

        <section>
          {loadingSubmission ? (
            <FullSecondButton>
              <LoaderOne />
            </FullSecondButton>
          ) : (
            <FullSecondButton
              disabled={!country?.value || submissionPassportList?.length === 0}
              sx={{
                fontSize: '14px',
                backgroundColor: !country?.value || submissionPassportList?.length === 0 ? '#fff' : '#528DC8',
                color: !country?.value || submissionPassportList?.length === 0 ? '#000' : '#fff',
              }}
              onClick={handleExport}
            >
              Submit & Print
            </FullSecondButton>
          )}
        </section>
      </section>
      {/* first section end */}
      {/* filter section start */}
      <section className='passport_return_header  passport_return_second'>
        <section className='visa_invoice_input_div'>
          <h5 style={{ fontSize: '14px', fontWeight: '500' }}>Country </h5>
          <div style={{ marginTop: '10px' }}>
            <RectSelect
              defaultValue={country}
              data={[
                {
                  key: 'indaiNew2',
                  value: 'india',
                  label: 'india',
                },
                {
                  key: 'chinaNew2',
                  value: 'china',
                  label: 'china',
                },
                {
                  key: 'bangladeshNew2',
                  value: 'bangladesh',
                  label: 'bangladesh',
                },
                {
                  key: 'pakistanNew2',
                  value: 'pakistan',
                  label: 'pakistan',
                },
                {
                  key: 'myanmarNew2',
                  value: 'myanmar',
                  label: 'myanmar',
                },
                {
                  key: 'srilankaNew2',
                  value: 'sri lanka',
                  label: 'sri lanka',
                },
                ...countries.map(({ name, flag, isoCode }) => ({
                  key: isoCode,
                  value: name,
                  label: name,
                })),
                {
                  key: 'other2',
                  value: 'other',
                  label: 'other',
                },
                {
                  key: 'stateless',
                  value: 'stateless',
                  label: 'stateless',
                },
              ]}
              callback={setcountry}
            />
          </div>
        </section>
        {/* <div className=" " style={{ display: "flex", alignSelf: "end" }}>
          <PassportSearch value={searchValue} callback={(e) => setsearchValue(e.target.value)} />{" "}
          <div className="" style={{ marginLeft: "50px" }}>
            <FullSecondButton sx={{ fontSize: "14px" }} onClick={handleSearch}>
              Show
            </FullSecondButton>
          </div>
        </div> */}
      </section>
      {/* filter section end */}
      {/* table section start */}
      <section style={{ marginTop: '50px' }}>
        <PassportSubmissionTable />
      </section>
      {/* table section end */}
      {/* pagination section start */}
      <section className='passport_return_pagination_section'>
        {/* <section className="pagination_select_div" onClick={() => selectRef.current?.focus()}>
          Show :
          <select
            ref={selectRef}
            value={limit}
            onChange={(e) => setlimit(e.target.value)}
            style={{ border: "none", outline: "none" }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
            <option value={50}>50</option>
          </select>
          <FiChevronDown style={{ fontSize: "20px" }} />
        </section> */}

        <section className='pagination_passport'>
          <Pagination
            count={passData?.totalPages}
            shape='rounded'
            variant='outlined'
            color='primary'
            page={page}
            onChange={(e, v) => setpage(v)}
            renderItem={(item) => <PaginationItem {...item} classes={{ selected: classes.selected }} />}
          />
        </section>
      </section>
      {/* pagination section end */}
    </div>
  );
};

export default PassportSubmission;
