import React from 'react';
import CurrencyFormatter from '../CurrencyFormatter/CurrencyFormatter';

const CostItem = ({ label, value }) => {
  return (
    <div style={{ display: 'flex', fontFamily: 'Nunito', fontSize: '14px', fontWeight: 700, marginRight: '30px' }}>
      <h4 style={{ marginRight: '5px' }}>{label}</h4>-{' '}
      <p style={{ color: '#528DC8', marginLeft: '5px', display: 'flex' }}>
        <CurrencyFormatter price={value} />
      </p>
    </div>
  );
};

export default CostItem;
