import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { createUsers } from "../../store/action/Users";
import { AllButton, LoaderOne } from "../../uiComponents";

const { FullWidthButton } = AllButton;
const CreateUser = () => {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const reduxState = useSelector(({ users }) => {
    return {
      loading: users.createUserLoading,
    };
  });
  const { loading } = reduxState;
  const initialState = {
    name: "",
    mobile: "",
    password: "",
    emailId: "",
  };
  const [values, setValues] = useState(initialState);
  const { name, emailId, password, mobile } = values;
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const validateDetails = (e) => {
    e.preventDefault();
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regex.test(emailId)) return alert.error("please enter valid email!");
    if (password.length < 6) return alert.error("Password length should be greater then 6");
    if (!name || !mobile) return alert.error("Please fill all details");
    let payload = {
      mobile,
      password,
      name,
      emailId,
      role: "ROLE_USER",
    };
    dispatch(createUsers(payload, createUserCallback));
  };

  const createUserCallback = ({ error, message, data }) => {
    if (error) {
      message?.error && alert.error(message?.error);
      return;
    }
    if (data?.status == "failure") {
      data?.message && alert.error(data?.message);
      return;
    }
    alert.success("User created Successfully");
    navigate(`/users-list`);
  };
  return (
    <div style={{ minHeight: "100vh" }}>
      {" "}
      <section className="login_right flex_col" style={{ padding: "0" }}>
        <div className="login_form_main_div">
          <h3 className="">Create users</h3>
          <form action="" className="login_form">
            <div className="login_input_main_div">
              <h5>name</h5>
              <div>
                <input type="text" required value={name} name="name" onChange={handleChangeInput} placeholder="name" />
              </div>
            </div>
            <div className="login_input_main_div">
              <h5>Email address</h5>
              <div>
                <input
                  type="email"
                  required
                  value={emailId}
                  name="emailId"
                  onChange={handleChangeInput}
                  placeholder="Email Address"
                />
              </div>
            </div>
            <div className="login_input_main_div">
              <h5>Mobile</h5>
              <div>
                <input type="number" value={mobile} name="mobile" onChange={handleChangeInput} placeholder="mobile" />
              </div>
            </div>
            <div className="login_input_main_div">
              <h5>Password</h5>
              <div>
                <input
                  type="text"
                  required
                  value={password}
                  name="password"
                  onChange={handleChangeInput}
                  placeholder="Password"
                />
              </div>
            </div>

            <div style={{ marginTop: "30px" }}>
              {loading ? (
                <FullWidthButton>
                  <LoaderOne />
                </FullWidthButton>
              ) : (
                <FullWidthButton type="submit" onClick={validateDetails}>
                  create
                </FullWidthButton>
              )}
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default CreateUser;
