import React from 'react';
import { TableStyle } from '../../../uiComponents';
import CurrencyFormatter from '../../../uiComponents/CurrencyFormatter/CurrencyFormatter';
import moment from 'moment';
import PaymentMethodList from '../../../utils/Constants/PaymentMethods.constants';
import { showPayment } from '../../../utils/helpers';
const { StyledTableCell, StyledTableRow } = TableStyle;
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const TicketStatementRow = ({ data }) => {
  const { paymentData } = data;
  const addComment = (e) => {
    e.preventDefault();
  };
// console.log(paymentData, 'paymentData=====>>>>>');


  return (
    <>
      <StyledTableRow>
        <StyledTableCell>{data?.invoiceId}</StyledTableCell>
        <StyledTableCell align='center'>{moment(data?.invoiceDate).format('DD/MM/YYYY')}</StyledTableCell>
        <StyledTableCell align='center'>{data?.clientName}</StyledTableCell>
        <StyledTableCell align='center'>
          <CurrencyFormatter price={data?.fare} />
        </StyledTableCell>
        <StyledTableCell align='center'>
          <CurrencyFormatter price={data?.taxes} />
        </StyledTableCell>
        <StyledTableCell align='center'>
          <CurrencyFormatter price={data?.skyTravelFee} />
        </StyledTableCell>
        <StyledTableCell align='center'>
          <CurrencyFormatter price={data?.totalAmount} />
        </StyledTableCell>
        <StyledTableCell align='center'>
          {/* {paymentData?.length
            ? paymentData.map(({ paymentMethod, paidAmount }, index) =>
                paymentMethod === PaymentMethodList[0].value ? paidAmount : 0
              )
            : 0} */}
            {showPayment(PaymentMethodList[0].value, paymentData)}
        </StyledTableCell>
        <StyledTableCell align='center'>
          {/* {paymentData?.length
            ? paymentData.map(({ paymentMethod, paidAmount }, index) =>
                paymentMethod === PaymentMethodList[1].value ? paidAmount : 0
              )
            : 0} */}
            {showPayment(PaymentMethodList[1].value, paymentData)}
        </StyledTableCell>
        <StyledTableCell align='center'>
          {showPayment(PaymentMethodList[2].value, paymentData)}
          {/* {paymentData?.length
            ? paymentData.map(({ paymentMethod, paidAmount }, index) =>
                paymentMethod === PaymentMethodList[2].value ? paidAmount : 0
              )
            : 0} */}
        </StyledTableCell>
        <StyledTableCell align='center'>
        {showPayment(PaymentMethodList[3].value, paymentData)}
          {/* {paymentData?.length
            ? paymentData.map(({ paymentMethod, paidAmount }, index) =>
                paymentMethod === PaymentMethodList[3].value ? paidAmount : 0
              )
            : 0} */}
        </StyledTableCell>
        <StyledTableCell align='center'>
        {showPayment(PaymentMethodList[4].value, paymentData)}
          {/* {paymentData?.length
            ? paymentData.map(({ paymentMethod, paidAmount }, index) =>
                paymentMethod === PaymentMethodList[4].value ? paidAmount : 0
              )
            : 0} */}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default TicketStatementRow;
