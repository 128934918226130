import React from "react";
import styles from "./styles/LoaderTwo.module.css";
const LoaderTwo = () => {
  return (
    <div className={styles.balls}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoaderTwo;
