import React from 'react';
import CurrencyFormatter from '../../uiComponents/CurrencyFormatter/CurrencyFormatter';

const CollectionItem = ({ Icon, label, value, backColor }) => {
  return (
    <div
      className=''
      style={{
        border: '1px solid #E6EDFF',
        borderRadius: '10px',
        display: 'flex',
        padding: '10px',
        whiteSpace: 'nowrap',
      }}
    >
      <section
        style={{
          background: backColor,
          padding: '8px',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span style={{ fontSize: '25px', color: '#219653', fontWeight: '600' }}>S</span>
        <Icon />
      </section>
      <section style={{ marginLeft: '15px' }}>
        <p style={{ color: '#809FB8', fontSize: '16px', fontWeight: '600' }}>{label}</p>
        <h3 style={{ color: '#06152B', fontSize: '30px', fontWeight: '800', marginTop: '2px' }}>
          {' '}
          <CurrencyFormatter price={value} />
        </h3>
      </section>
    </div>
  );
};

export default CollectionItem;
