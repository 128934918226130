import React from "react";

const ReturnIcon = ({ width = "26", height = "26", color = "#F3654A" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_331_217)">
        <path
          d="M19.3698 4.77705C18.6275 4.09984 17.8706 3.59625 17.0546 3.19926C15.5845 2.48347 14.0358 2.15248 12.4145 2.20426C10.6575 2.26112 9.02068 2.75151 7.51371 3.70489C5.46307 5.00245 4.00553 6.82391 3.13818 9.1581C2.7234 10.2749 2.50632 11.4375 2.47918 12.6376C2.47627 12.7726 2.47627 12.9087 2.46174 13.0417C2.39099 13.6701 1.92776 14.1006 1.37536 14.059C0.804556 14.0174 0.401405 13.5463 0.404312 12.9158C0.409158 11.7705 0.535143 10.6384 0.849135 9.5429C2.11576 5.11819 4.8128 2.05907 9.01874 0.658962C13.2169 -0.739117 17.0943 0.113742 20.556 2.98097C20.6122 3.02768 20.6675 3.07743 20.7237 3.12413C20.7285 3.12819 20.7372 3.12616 20.7818 3.13124C20.7818 2.85507 20.7741 2.58297 20.7838 2.30985C20.8109 1.54634 21.4302 1.05189 22.1047 1.24886C22.5291 1.37272 22.8421 1.76869 22.847 2.2601C22.8596 3.45918 22.8625 4.65826 22.847 5.85734C22.8392 6.47871 22.3964 6.91833 21.8062 6.9224C20.6849 6.93052 19.5646 6.9295 18.4434 6.9224C17.8638 6.91833 17.4258 6.47363 17.3977 5.88374C17.3705 5.3243 17.7795 4.83391 18.3493 4.78213C18.6653 4.7537 18.9851 4.77705 19.3718 4.77705H19.3698Z"
          fill={color}
        />
        <path
          d="M4.90389 22.9246C4.90389 23.2313 4.90776 23.4912 4.90389 23.7511C4.89323 24.3674 4.48039 24.8334 3.92702 24.8598C3.36397 24.8872 2.89977 24.4801 2.85712 23.8618C2.81933 23.3155 2.83968 22.7652 2.83774 22.216C2.8358 21.5915 2.83387 20.9661 2.83774 20.3417C2.84259 19.5751 3.26609 19.1375 4.00068 19.1365C5.08123 19.1345 6.16179 19.1315 7.24235 19.1386C7.72885 19.1416 8.10874 19.4442 8.24248 19.9021C8.37234 20.3447 8.22116 20.8199 7.86355 21.092C7.66004 21.2474 7.42648 21.3012 7.18033 21.3032C6.90123 21.3042 6.62309 21.3032 6.29166 21.3032C7.02237 21.954 7.78118 22.4485 8.60105 22.8404C10.3193 23.6607 12.1218 23.9542 13.9961 23.741C15.7948 23.5369 17.4258 22.8658 18.8794 21.7357C20.8293 20.2199 22.1347 18.239 22.7695 15.7799C22.9701 15.0032 23.0815 14.2123 23.1019 13.4082C23.1067 13.2396 23.1086 13.0701 23.13 12.9036C23.2036 12.3238 23.662 11.9146 24.1911 11.9441C24.7503 11.9756 25.1758 12.4426 25.1758 13.0477C25.1758 14.2864 25.0333 15.5098 24.6679 16.6886C23.1949 21.4403 20.1558 24.4953 15.5108 25.6497C12.2178 26.4681 9.10305 25.8233 6.22963 23.9399C5.77803 23.6445 5.36615 23.2942 4.90486 22.9236L4.90389 22.9246Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_331_217">
          <rect width="24.7724" height={height} fill="white" transform="translate(0.404297)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReturnIcon;
