import { Pagination, PaginationItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CommonHeading from "../../Components/VisaInvoice/CommonHeading";
import "../../Components/VisaInvoice/styles/VisaInvoice.css";
import { AllButton } from "../../uiComponents";
import TicketInvoiceListTable from "../../Components/Ticket/List/TicketInvoiceListTable";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getTicketList } from "../../store/action/Ticket";
import { getUserList } from "../../store/action/Users";
import UsersTable from "../../Components/Auth/UsersList/UsersTable";
const { FullSecondButton } = AllButton;

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: "#528DC8 !important",
    color: "#fff !important",
  },
}));

const UsersList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reduxState = useSelector(({ users }) => {
    return {
      loading: users.getUserLoading,
      usersList: users.usersList,
    };
  });
  let { usersList, loading } = reduxState;
  const [page, setpage] = useState(1);
  useEffect(() => {
    dispatch(getUserList({ pageNo: page }));
  }, []);
  useEffect(() => {
    if (page) {
      dispatch(getUserList({ pageNo: page }));
    }
  }, [page]);
  return (
    <div className="visa_invoice nunito" >
      {/* client details section start */}
      <section className="visa_invoice_list_header_div">
        <CommonHeading heading={"Users list"} tag={""} />
        <div style={{ minWidth: "280px" }}>
          <Link to={`/create-user`}>
            <FullSecondButton>Add new </FullSecondButton>
          </Link>
        </div>
      </section>

      {/* passport details section start */}
      <section style={{ margin: "40px 0" }}>
        <UsersTable />
      </section>
      {/* <section className="pagination_passport">
        <Pagination
          count={ticketData?.totalPages}
          shape="rounded"
          variant="outlined"
          color="primary"
          page={page}
          onChange={(e, v) => setpage(v)}
          renderItem={(item) => <PaginationItem {...item} classes={{ selected: classes.selected }} />}
        />
      </section> */}
      <section style={{ marginTop: "40px", display: "flex", justifyContent: "center" }}></section>
    </div>
  );
};

export default UsersList;
