import { Table, TableBody, TableContainer, TableHead } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { TableStyle } from '../../../uiComponents';
import UsersRoles from '../../../utils/Constants/UserRoles.constants';
import VisaInvoiceListRow from './VisaInvoiceListRow';

const { StyledTableRow, StyledTableCellTranparent } = TableStyle;
const VisaInvoiceListTable = () => {
  const reduxState = useSelector(({ visaInvoice, users }) => {
    return {
      invoiceData: visaInvoice?.allInvoices,
      loading: visaInvoice.invoiceListLoading,
      user: users.user,
    };
  });
  let { invoiceData, loading, user } = reduxState;
  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 650, marginBottom: '20px' }} aria-label='simple table'>
          <TableHead sx={{ borderBottom: '2px solid #EBEFF2' }}>
            <StyledTableRow>
              <StyledTableCellTranparent sx={{ width: '150px' }}>Invoice no</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '120px' }}>
                date
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '300px' }}>
                Client Name
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '150px' }}>
                Mobile No.
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '150px' }}>
                No. of Passports
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '10px' }}>
                Print
              </StyledTableCellTranparent>
              {user?.role === UsersRoles.ROLE_ADMIN && (
                <StyledTableCellTranparent align='center' sx={{ width: '10px' }}>
                  Edit
                </StyledTableCellTranparent>
              )}
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {!loading &&
              invoiceData?.invoices?.length > 0 &&
              invoiceData?.invoices?.map(
                (
                  {
                    clientMobileNumber,
                    clientName,
                    invoiceDate,
                    invoiceId,
                    noOfPassports,
                    userEmail,
                    userMobile,
                    userName,
                    sellingPrice,
                    visaForCountry,
                    country,
                    city,
                    address,
                    clientEmail,
                    totalAmount,
                    totalGST,
                    agentFeeGST,
                    skyServicefeeGST,
                    mobile1,
                    mobile2,
                  },
                  index
                ) => (
                  <VisaInvoiceListRow
                    key={clientMobileNumber + invoiceId + index}
                    invoiceId={invoiceId}
                    invoiceDate={invoiceDate}
                    clientName={clientName}
                    clientMobileNumber={clientMobileNumber}
                    noOfPassports={noOfPassports}
                    index={index}
                    sellingPrice={totalAmount}
                    totalGST={skyServicefeeGST + agentFeeGST}
                    visaForCountry={`${Object.values(visaForCountry)}`}
                    country={country}
                    email={clientEmail}
                    city={city}
                    address={address}
                    mobile1={mobile1}
                    mobile2={mobile2}
                  />
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default VisaInvoiceListTable;
