import React from "react";

const CommonGreenHeading = ({ heading }) => {
  return (
    <div
      style={{
        backgroundColor: "#75AA52",
        color: "#fff",
        fontSize: "20px",
        fontWeight: 700,
        textAlign: "center",
        padding: "5px",
        borderRadius: "3px",
      }}
    >
      {heading}
    </div>
  );
};

export default CommonGreenHeading;
