const VisaInvoiceConstant = {
  SET_CLIENT_DATA: "SET_CLIENT_DATA",
  ADD_INVOICE_DETAILS: "ADD_INVOICE_DETAILS",
  UPDATE_INVOICE_DETAILS: "UPDATE_INVOICE_DETAILS",
  SET_PASSPORT_DATA: "SET_PASSPORT_DATA",
  CLEAR_DATA: "CLEAR_DATA",
  GET_PASSPORTS: "GET_PASSPORTS",
  GET_INVOICE: "GET_INVOICE",
  GET_INVOICE_LIST: "GET_INVOICE_LIST",
  EDIT_INVOICE: "EDIT_INVOICE",
  PASSPORT_SUBMISSION: "PASSPORT_SUBMISSION",
  PASSPORT_RETURN: "PASSPORT_RETURN",
  ADD_PASSPORT_RETURN: "ADD_PASSPORT_RETURN",
  ADD_PASSPORT_SUBMISSION: "ADD_PASSPORT_SUBMISSION",
  GET_SUBMISSION_LIST: "GET_SUBMISSION_LIST",
  GET_RETURN_PASSPORT_LIST: "GET_RETURN_PASSPORT_LIST",
  GET_DELIVERED_PASSPORT_LIST: "GET_DELIVERED_PASSPORT_LIST",
  GET_LATEST_SUBMITTED: "GET_LATEST_SUBMITTED",
  GET_LATEST_RETURNED: "GET_LATEST_RETURNED",
  SET_DELIVERY_PASSPORTS: "SET_DELIVERY_PASSPORTS",
};

export default VisaInvoiceConstant;
