import { Table, TableBody, TableCell, TableContainer, TableHead } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { TableStyle } from "../../../uiComponents";
import LoaderTwo from "../../../uiComponents/Loader/LoaderTwo";
import PassportStatus from "../../../utils/Constants/passportStatus.constants";
import DeliveryRow from "./DeliveryRow";

const { StyledTableCell, StyledTableRow, StyledTableCellTranparent, GreyTableRow } = TableStyle;
const DeliveryTable = () => {
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      loading: visaInvoice.deliveryListLoading,
      deliveryList: visaInvoice.deliveryList,
    };
  });
  let { deliveryList, loading } = reduxState;

  return (
    <div>
      {" "}
      <TableContainer>
        <Table sx={{ minWidth: 650, marginBottom: "20px" }} aria-label="simple table">
          <TableHead sx={{ borderBottom: "2px solid #EBEFF2" }}>
            <StyledTableRow>
              <StyledTableCellTranparent align="center" sx={{ width: "110px" }}>
                Date
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align="center" sx={{ width: "100px" }}>
                Invoice No
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align="center" sx={{ width: "100px" }}>
                PASSPORT NO{" "}
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align="center" sx={{ width: "110px" }}>
                Status
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align="center" sx={{ width: "110px" }}>
                user Id
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align="center" sx={{ width: "110px" }}>
                delete
              </StyledTableCellTranparent>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              deliveryList?.deliveredData?.length > 0 ? (
                deliveryList?.deliveredData?.map((data, index) => (
                  <DeliveryRow key={index + data?.id} index={index + 1} data={data} />
                ))
              ) : (
                <StyledTableRow>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell
                    colSpan={2}
                    sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "80px 0px" }}
                  >
                    No More data
                  </TableCell>
                  <TableCell colSpan={3}></TableCell>
                </StyledTableRow>
              )
            ) : (
              <StyledTableRow>
                <TableCell colSpan={3}></TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "80px 0px" }}
                >
                  <LoaderTwo />
                </TableCell>
                <TableCell colSpan={3}></TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DeliveryTable;
