import { IconButton, TableCell } from "@mui/material";
import React, { useState } from "react";
import { AllButton, CommonModal, TableStyle } from "../../../uiComponents";
import { AiOutlinePlusCircle } from "react-icons/ai";
import RectSelect from "../../../uiComponents/Select/RectSelect";
import PassportStatus from "../../../utils/Constants/passportStatus.constants";
import { useDispatch, useSelector } from "react-redux";
import VisaInvoiceConstant from "../../../store/constants/VisaInvoice.constants";
const { StyledTableCell, StyledTableRow, StyledTableCellTranparent, GreyTableRow } = TableStyle;

const { FullSecondButton } = AllButton;
const delivery_status = {
  DELIVERED: "DELIVERED",
  NOT_DELIVERED: "RETURNED",
};
const PassportDeliveryRow = (props) => {
  const {
    passport_no,
    passport_expiry_date,
    name,
    gender,
    nationality,
    type_of_pass,
    pass_expiry,
    visa_for_country,
    return_status,
    comments,
    delivered,
  } = props;
  const dispatch = useDispatch();
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      invoiceDetails: visaInvoice.invoiceDetails,
      loading: visaInvoice.detailsLoading,
      deliveryPassportsData: visaInvoice.deliveryPassportsData,
    };
  });
  let { invoiceDetails, loading, deliveryPassportsData } = reduxState;
  let passportList = invoiceDetails?.passportInfo;

  const [showModal, setShowModal] = useState(false);
  const [status, setstatus] = useState({
    key: "return_status",
    value: delivered ? delivery_status?.DELIVERED : delivery_status?.NOT_DELIVERED,
    label: delivered ? delivery_status?.DELIVERED : delivery_status?.NOT_DELIVERED,
  });
  const updateAction = (e) => {
    setstatus(e);
    let tempArray = deliveryPassportsData;
    tempArray.find((o, i) => {
      if (o.passportnumber === passport_no) {
        tempArray[i] = { mode: e?.value, passportnumber: passport_no };
        return true; // stop searching
      }
    });
    dispatch({
      type: VisaInvoiceConstant.SET_DELIVERY_PASSPORTS,
      deliveryPassportsData: tempArray,
    });
  };

  const updateModal = () => {
    return (
      <CommonModal showModal={showModal} callback={(e) => setShowModal(false)}>
        <div>
          <section>
            <h5>Passport No: {passport_no}</h5>
          </section>
          <section>
            <form action="">
              <section className="visa_invoice_input_div">
                <h5>Passport Status</h5>
                <div style={{ marginTop: "5px", width: "100%", marginBottom: "20px" }}>
                  <RectSelect
                    defaultValue={status}
                    data={Object.values(delivery_status).map((data, index) => ({
                      key: data + index,
                      value: data,
                      label: data,
                    }))}
                    callback={updateAction}
                  />
                </div>
              </section>
            </form>
          </section>
        </div>
      </CommonModal>
    );
  };
  const returnStatusComponents = () => {
    switch (return_status) {
      case PassportStatus.APPROVED:
        return (
          <div
            style={{
              color: "#2FB182",
              backgroundColor: "#E7FDF5",
              padding: "5px 12px",
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            Approved
          </div>
        );
      case PassportStatus.PENDING:
        return (
          <div
            style={{
              color: "#FFAE00",
              backgroundColor: "#FFF7E6",
              padding: "5px 12px",
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            PENDING
          </div>
        );
      case PassportStatus.REJECTED:
        return (
          <div
            style={{
              color: "#DE486C",
              backgroundColor: "#FFECF0",
              padding: "5px 12px",
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            Rejected
          </div>
        );
      case "DELIVERED":
        return (
          <div
            style={{
              color: "#2FB182",
              backgroundColor: "#E7FDF5",
              padding: "5px 12px",
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            DELIVERED
          </div>
        );

      default:
        break;
    }
  };
  return (
    <>
      {showModal && updateModal()}
      <StyledTableRow>
        <StyledTableCell>{passport_no}</StyledTableCell>
        <StyledTableCell align="center">{passport_expiry_date}</StyledTableCell>
        <StyledTableCell align="center">{name}</StyledTableCell>
        <StyledTableCell align="center">{gender}</StyledTableCell>
        <StyledTableCell align="center">{nationality}</StyledTableCell>
        {/* <StyledTableCell align="center">{type_of_pass}</StyledTableCell>
        <StyledTableCell align="center">{pass_expiry}</StyledTableCell> */}
        <StyledTableCell align="center">{returnStatusComponents()}</StyledTableCell>
        {/* <StyledTableCell align="center">
          {status?.value}
          <IconButton onClick={() => setShowModal(!showModal)}>:</IconButton>
        </StyledTableCell> */}
      </StyledTableRow>
    </>
  );
};

export default PassportDeliveryRow;
