import React from 'react';
import './styles/PassportSearch.css';
const PassportSearch = ({ value, callback, placeholder = 'Search by' }) => {
  return (
    <div className='passport_search_div'>
      <div style={{ marginRight: '10px' }}>
        <svg width='20' height='20' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M10.7001 0C11.2081 0.082472 11.727 0.126457 12.224 0.247416C15.3319 1.00066 17.6587 2.79305 19.0406 5.69606C20.7119 9.20937 20.3678 12.6182 18.2704 15.8621C18.194 15.9831 18.0956 16.0985 18.0082 16.214C17.9209 16.3294 17.8335 16.4449 17.7297 16.5824C17.7898 16.6483 17.8444 16.7088 17.9045 16.7748C19.7834 18.7376 21.6623 20.706 23.5358 22.6688C24.0438 23.2021 24.1366 23.8179 23.8034 24.3787C23.3992 25.055 22.4652 25.2089 21.8644 24.6976C21.7716 24.6206 21.6842 24.5327 21.6022 24.4447C19.7233 22.4819 17.8444 20.5135 15.9709 18.5507C15.9163 18.4902 15.8781 18.4187 15.8562 18.3912C15.2117 18.7321 14.6437 19.0895 14.0319 19.3479C11.29 20.5135 8.54261 20.5135 5.83893 19.238C3.01508 17.9019 1.16892 15.6862 0.360547 12.6512C-0.354974 9.96261 5.51681e-05 7.38399 1.41471 4.9868C2.91676 2.45217 5.12341 0.874203 7.98003 0.219925C8.4443 0.115461 8.91949 0.0714757 9.38922 0C9.82618 0 10.2631 0 10.7001 0ZM10.0501 2.6391C5.97548 2.6391 2.64912 5.98197 2.64366 10.0891C2.6382 14.1907 5.93178 17.5225 10.0392 17.5555C14.1029 17.5885 17.4675 14.2017 17.4566 10.0946C17.4511 5.98197 14.1302 2.6391 10.0501 2.6391Z'
            fill='#ACACAC'
          />
        </svg>
      </div>
      <input type='text' value={value} onChange={(e) => callback(e)} placeholder={placeholder} />
    </div>
  );
};

export default PassportSearch;
