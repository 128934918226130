import React from "react";

const DashboardIcon = ({ height = "20", width = "21", color = "#000" }) => {
  return (
    <svg width={"15"} height={"12"} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.998 20C12.4458 20 11.998 19.5523 11.998 19V12C11.998 11.4477 12.4458 11 12.998 11H19.998C20.5503 11 20.998 11.4477 20.998 12V19C20.998 19.5523 20.5503 20 19.998 20H12.998ZM13.998 18H18.998V13H13.998V18ZM1.99805 20C1.44576 20 0.998047 19.5523 0.998047 19V12C0.998047 11.4477 1.44576 11 1.99805 11H8.99805C9.55033 11 9.99805 11.4477 9.99805 12V19C9.99805 19.5523 9.55033 20 8.99805 20H1.99805ZM2.99805 18H7.99805V13H2.99805V18ZM12.998 9C12.4458 9 11.998 8.55228 11.998 8V1C11.998 0.447723 12.4458 0 12.998 0H19.998C20.5503 0 20.998 0.447723 20.998 1V8C20.998 8.55228 20.5503 9 19.998 9H12.998ZM13.998 7H18.998V2H13.998V7ZM1.99805 9C1.44576 9 0.998047 8.55228 0.998047 8V1C0.998047 0.447723 1.44576 0 1.99805 0H8.99805C9.55033 0 9.99805 0.447723 9.99805 1V8C9.99805 8.55228 9.55033 9 8.99805 9H1.99805ZM2.99805 7H7.99805V2H2.99805V7Z"
        fill={color}
      />
    </svg>
  );
};

export default DashboardIcon;
