import React from 'react';
import styles from '../../Components/VisaInvoice/styles/VisaInvoicePrint.module.css';
import VisaPrintTopItem from '../../Components/VisaInvoice/Print/VisaPrintTopItem';
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fff',
    color: theme.palette.common.black,
    fontSize: 13,
    fontWeight: 700,
    textTransform: 'uppercase',
    fontFamily: 'Nunito',
    padding: '2px 5px',
    margin: '0',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontWeight: 500,
    color: '#000',
    fontFamily: 'Nunito',
    textTransform: 'uppercase',
    padding: '10px 5px',
    border: '0px ',
    margin: '0',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#FFF',
    fontFamily: 'Nunito',
  },
  //   hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    fontFamily: 'Nunito',
  },
  [`&:hover`]: {
    backgroundColor: '#FFF',
    color: '#000 !important',
  },
}));

const TicketPrint = ({ ticketDetails }) => {
  // const [searchParams] = useSearchParams();
  // let printDiv = useRef();
  // const invoiceid = searchParams.get('ticketId');
  // const dispatch = useDispatch();
  // const reduxState = useSelector(({ tickets }) => {
  //   return {
  //     ticketDetails: tickets.ticketDetails,
  //     loading: tickets.detailLoading,
  //   };
  // });
  // let { ticketDetails } = reduxState;

  // useEffect(() => {
  //   dispatch(getTicketDetails({ ticketId: invoiceid }));
  //   return () => {
  //     dispatch({ type: Ticket_Constants.GET_TICKET_DETAILS, loading: false, data: {} });
  //   };
  // }, []);

  return (
    <div>
      {/* <section className=' align_center '>
        <div style={{ margin: '20px 0' }}>
          <ReactToPrint trigger={() => <MainButton>Print</MainButton>} content={() => printDiv} />
        </div>
      </section> */}
      <div className={styles.main_print_div}>
        <section style={{ display: 'flex' }}>
          <div className={styles.logo_div}>
            <img
              src={require('../../assets/logo/mainLogo.png')}
              alt='sky centre '
              className=''
              style={{ objectFit: 'contain', width: '150px' }}
            />
          </div>
          <div className='' style={{ textAlign: 'center', width: '100%', marginLeft: '-150px' }}>
            <h2 className={styles.heading}>Sky Centre Air Travel </h2>
            <p> 93A Syed Alwi Road,</p>
            <p>S(207669),</p>
            <p>Phone No. - +65 62911218,</p>
            <p>GST REG NO : 198701479E</p>
          </div>
        </section>
        <hr style={{ padding: '0.5px', background: '#000', marginTop: '15px' }} />
        <h4 style={{ textAlign: 'center', fontSize: '20px', marginTop: '20px', fontWeight: '700' }}>INVOICE</h4>
        <section style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <h5>To: {ticketDetails?.clientName}</h5>
          </div>
          <div style={{ marginRight: '20px' }}>
            <VisaPrintTopItem heading='INVOICE NO' label={ticketDetails?.invoiceId} isMain={true} />
            <VisaPrintTopItem heading='INVOICE DATE' label={moment(ticketDetails?.invoiceDate).format('DD/MM/YYYY')} />
            <VisaPrintTopItem heading="CONSULTANT" label={ticketDetails?.consultantName} />
            <VisaPrintTopItem
              heading='CREDIT TERMS'
              label={
                ticketDetails?.paymentMethod
                  ? ticketDetails?.paymentMethod.length > 1
                    ? 'MULTIPLE TYPES'
                    : ticketDetails?.paymentMethod[0].paymentMethod
                  : 'CASH'
              }
            />
            {/* <VisaPrintTopItem heading="YOUR REF NO." label="MR RAJIV" />  */}
          </div>
        </section>

        <section style={{ width: '100%', marginTop: '20px' }}>
          <TableContainer>
            <Table sx={{ marginBottom: '20px' }} aria-label='simple table'>
              <TableHead sx={{ borderBottom: '1px solid #000', borderTop: '1px solid #000' }}>
                <StyledTableRow>
                  <StyledTableCell sx={{ width: '40px' }} align='left'>
                    Description
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '150px' }} align='left'>
                    Pax name
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '50px' }} align='left'>
                    pax type
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: '130px' }} align='left'>
                  ticket no/route
                  </StyledTableCell>
                  <StyledTableCell align='center' sx={{ width: '50px' }}>
                    Total(S$)
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {ticketDetails?.passengerData?.map(
                  ({ departureDate, flightNumber, paxName, paxType, route, ticketNumber, totalAmount }, index) => (
                    <StyledTableRow key={index + ticketNumber}>
                      <StyledTableCell align='left'>TKT</StyledTableCell>
                      <StyledTableCell align='left'>
                        <span> {paxName}</span> <br />{' '}
                        <span style={{ fontSize: '10px' }}> Dep Date :{departureDate} </span> <br />{' '}
                        <span style={{ fontSize: '10px' }}> By :{flightNumber} </span>
                      </StyledTableCell>
                      <StyledTableCell align='left'>{paxType}</StyledTableCell>
                      <StyledTableCell align='left'>
                        {ticketNumber} <br />
                        {route}
                      </StyledTableCell>
                      <StyledTableCell align='center'>{Number(totalAmount)?.toFixed(2)}</StyledTableCell>
                    </StyledTableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </section>
        {/* <section>
        <p style={{ textAlign: "center" }}>************** END ***************</p>
      </section> */}
        <hr style={{ padding: '0.5px', background: '#000', marginTop: '40px' }} />
        <section style={{ display: 'flex', padding: '10px 20px', textTransform: 'uppercase' }}>
          <div
            style={{ marginLeft: 'auto', marginRight: '20px', display: 'flex', fontWeight: '700', fontSize: '15px' }}
          >
            <p style={{ marginRight: '10px' }}>Total</p>:{' '}
            <p style={{ marginLeft: '20px' }}>
              {ticketDetails?.totalAmount ? Number(ticketDetails?.totalAmount).toFixed(2) : 0}
            </p>
          </div>
        </section>
        <section style={{ width: 'max-content', marginTop: '20px' }}>
          <h4 style={{ borderTop: '2px solid #000', padding: '10px 30px' }}>Authorised Signature</h4>
        </section>
        <hr style={{ padding: '0.5px', background: '#000', marginTop: '40px' }} />
        <section>
          <div className={styles.terms}>
            <h3 style={{ fontWeight: '500', fontSize: '14px' }}>Terms and conditions.</h3>
            <p style={{ fontWeight: '400', fontSize: '12px' }}>ALL TICKETS AND / OR TRAVEL RELATED PRODUCTS PURCHASED THAT ARE APPLICABLE FOR REFUND / SUBJECT TO REFUND VALUE / TERMS AND CONDITIONS / AIRLINE AND TRAVEL AGENT FEES. EXAMPLE - REFUND / CANCELLATION</p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TicketPrint;
