import Ticket_Constants from '../constants/Ticket';

const initialState = {
  listLoading: false,
  saveLoading: false,
  detailsLoading: false,
  ticketsList: {},
  ticketDetails: {},
  ticketsData: [],
  message: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case Ticket_Constants.GET_TICKET_LIST:
      return {
        ...state,
        listLoading: action.loading,
        ticketsList: action.data,
      };
    case Ticket_Constants.GET_TICKET_DETAILS:
      return {
        ...state,
        detailsLoading: action.loading,
        ticketDetails: action.data,
      };
    case Ticket_Constants.ADD_TICKETS:
      return {
        ...state,
        ticketsData: action.ticketsData,
      };
    case Ticket_Constants.SAVE_TICKET:
      return {
        ...state,
        saveLoading: action.loading,
      };

    default:
      return state;
  }
};
