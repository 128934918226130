import { Checkbox, IconButton, TableCell } from '@mui/material';
import React, { useState } from 'react';
import { TableStyle } from '../../../uiComponents';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import CurrencyFormatter from '../../../uiComponents/CurrencyFormatter/CurrencyFormatter';
import PaymentMethodList from '../../../utils/Constants/PaymentMethods.constants';
import { showPayment } from '../../../utils/helpers';
const { StyledTableCell, StyledTableRow, StyledTableCellTranparent, GreyTableRow } = TableStyle;
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const VisaStatementRow = (props) => {
  const {
    clientMobileNumber,
    clientName,
    invoiceDate,
    invoiceId,
    noOfPassports,
    cost,
    sellingPrice,
    profit,
    visaFee,
    agentFee,
    agentFeeGST,
    skyServicefee,
    skyServicefeeGST,
    totalAmount,
    paymentData,
  } = props;
  const addComment = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <StyledTableRow>
        <StyledTableCell>{invoiceId}</StyledTableCell>
        <StyledTableCell align='center'>{invoiceDate}</StyledTableCell>
        <StyledTableCell align='center'>{clientName}</StyledTableCell>
        <StyledTableCell align='center'>{noOfPassports}</StyledTableCell>
        <StyledTableCell align='center'>
          <CurrencyFormatter price={visaFee} />
        </StyledTableCell>
        <StyledTableCell align='center'>
          <CurrencyFormatter price={agentFee} />
        </StyledTableCell>
        <StyledTableCell align='center'>
          <CurrencyFormatter price={agentFeeGST} />
        </StyledTableCell>
        <StyledTableCell align='center'>
          <CurrencyFormatter price={skyServicefee} />
        </StyledTableCell>
        <StyledTableCell align='center'>
          <CurrencyFormatter price={skyServicefeeGST} />
        </StyledTableCell>
        <StyledTableCell align='center'>
          <CurrencyFormatter price={totalAmount} />
        </StyledTableCell>
        <StyledTableCell align='center'>
          {/* {paymentData?.length
            ? paymentData.map(({ paymentMethod, paidAmount }, index) =>
                paymentMethod === PaymentMethodList[0].value ? paidAmount : 0
              )
            : 0} */}
            {showPayment(PaymentMethodList[0].value, paymentData)}
        </StyledTableCell>
        <StyledTableCell align='center'>
        {showPayment(PaymentMethodList[1].value, paymentData)}
          {/* {paymentData?.length
            ? paymentData.map(({ paymentMethod, paidAmount }, index) =>
                paymentMethod === PaymentMethodList[1].value ? paidAmount : 0
              )
            : 0} */}
        </StyledTableCell>
        <StyledTableCell align='center'>
        {showPayment(PaymentMethodList[2].value, paymentData)}
          {/* {paymentData?.length
            ? paymentData.map(({ paymentMethod, paidAmount }, index) =>
                paymentMethod === PaymentMethodList[2].value ? paidAmount : 0
              )
            : 0} */}
        </StyledTableCell>
        <StyledTableCell align='center'>
        {showPayment(PaymentMethodList[3].value, paymentData)}
          {/* {paymentData?.length
            ? paymentData.map(({ paymentMethod, paidAmount }, index) =>
                paymentMethod === PaymentMethodList[3].value ? paidAmount : 0
              )
            : 0} */}
        </StyledTableCell>
        <StyledTableCell align='center'>
        {showPayment(PaymentMethodList[4].value, paymentData)}
          {/* {paymentData?.length
            ? paymentData.map(({ paymentMethod, paidAmount }, index) =>
                paymentMethod === PaymentMethodList[4].value ? paidAmount : 0
              )
            : 0} */}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default VisaStatementRow;
