import { IconButton, TableCell } from '@mui/material';
import React, { useState } from 'react';
import { AllButton, CommonModal, TableStyle } from '../../../uiComponents';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import RectSelect from '../../../uiComponents/Select/RectSelect';
import PassportStatus from '../../../utils/Constants/passportStatus.constants';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { changeStatus, getReturnedList } from '../../../store/action/VisaInvoice';
import { MdDelete } from 'react-icons/md';
import PassportMode from '../../../utils/Constants/PassportMode.constants';
import moment from 'moment';
const { StyledTableCell, StyledTableRow, StyledTableCellTranparent, GreyTableRow } = TableStyle;

const { BorderSecondButton } = AllButton;

const ReturnRow = (props) => {
  const { date, id, passportNumber, userId, status, passportExpiryDate, name, page, invoiceId, search } = props.data;
  const alert = useAlert();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const updateAction = (e) => {
    e.preventDefault();
    let payload = {
      passportNumber: passportNumber,
      status: PassportStatus.PENDING,
      mode: PassportMode.SUBMITTED,
      comments: '',
    };
    dispatch(changeStatus(payload, passportCallback));
  };

  const passportCallback = ({ error, message, data }) => {
    if (error) {
      message?.error && alert.error(message?.error);
      return;
    }
    if (data?.status == 'failure') {
      data?.message && alert.error(data?.message);
      return;
    }
    alert.success('Deleted Successfully');
    dispatch(getReturnedList({ pageNo: page, search: search || '' }));
  };

  const returnStatusComponents = () => {
    switch (status) {
      case PassportStatus.APPROVED:
        return (
          <div
            style={{
              color: '#2FB182',
              backgroundColor: '#E7FDF5',
              padding: '5px 12px',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            Approved
          </div>
        );
      case 'DELIVERED':
        return (
          <div
            style={{
              color: '#2FB182',
              backgroundColor: '#E7FDF5',
              padding: '5px 12px',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            Deliverd
          </div>
        );
      case 'NOT_RETURN':
        return (
          <div
            style={{
              color: '#DE486C',
              backgroundColor: '#FFECF0',
              padding: '5px 12px',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            Not Return
          </div>
        );
      case PassportStatus.REJECTED:
        return (
          <div
            style={{
              color: '#FFAE00',
              backgroundColor: '#FFF7E6',
              padding: '5px 12px',
              fontWeight: 600,
              fontSize: '14px',
            }}
          >
            Rejected
          </div>
        );

      default:
        break;
    }
  };
  const updateModal = () => {
    return (
      <CommonModal showModal={showModal} callback={(e) => setShowModal(false)}>
        <div>
          <section>
            <section className='' style={{ fontSize: '20px', padding: '30px' }}>
              <h5>this action will reset it to submitted mode </h5>
            </section>

            <section style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <BorderSecondButton sx={{ fontSize: '14px', marginRight: '15px' }} onClick={() => setShowModal(false)}>
                  cancel
                </BorderSecondButton>
              </div>
              <div>
                <BorderSecondButton
                  sx={{
                    fontSize: '14px',
                    marginLeft: '15px',
                    background: '#FF3131',
                    borderColor: '#FF3131',
                    color: '#fff',
                  }}
                  onClick={updateAction}
                >
                  Delete
                </BorderSecondButton>
              </div>
            </section>
          </section>
        </div>
      </CommonModal>
    );
  };

  return (
    <>
      {showModal && updateModal()}
      <StyledTableRow>
        <StyledTableCell align='center'>{invoiceId}</StyledTableCell>
        <StyledTableCell align='center'>{date}</StyledTableCell>
        <StyledTableCell align='center'>{passportNumber}</StyledTableCell>
        <StyledTableCell align='center'>{name}</StyledTableCell>
        {/* <StyledTableCell align='center'>{returnStatusComponents()}</StyledTableCell> */}
        <StyledTableCell align='center'>{userId}</StyledTableCell>
        <StyledTableCell align='center'>
          <IconButton onClick={() => setShowModal(true)}>
            <MdDelete style={{ fontSize: '20px', color: 'red' }} />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default ReturnRow;
