import moment from 'moment';
import * as XLSX from 'xlsx-js-style';

const SubmissionListExport = async (props) => {
  let { data, totalAmount, from, to, pageNo } = props;
  var currentDate = moment(new Date(Date.now())).format('DD-MM-YYYY');
  let table1 = [];
  const subHeaderStyle = {
    alignment: { horizontal: 'center' },
    font: { bold: true, sz: '13' },
  };
  table1.push({
    A: { v: `From ${from} To ${to}`, s: subHeaderStyle },
  });
  // table1.push({
  //   A: { v: `PAGE NO: ${pageNo}`, s: subHeaderStyle },
  // });
  table1.push({
    A: { v: `TOTAL AMOUNT (S$): ${totalAmount}`, s: subHeaderStyle },
  });
  const secondHeaderStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
    font: { bold: true, sz: '12' },
  };

  let secondheader = {
    A: { v: 'S.NO.', s: secondHeaderStyle },
    B: { v: 'DATE', s: secondHeaderStyle },
    C: { v: 'NAME', s: secondHeaderStyle },
    D: { v: 'PASSPORT NO', s: secondHeaderStyle },
    E: { v: 'NATIONALITY', s: secondHeaderStyle },
    F: { v: 'INVOICE', s: secondHeaderStyle },
    G: { v: 'INVOICE DATE', s: secondHeaderStyle },
    H: { v: 'VISA FEE', s: secondHeaderStyle },
    I: { v: 'AGENT FEE', s: secondHeaderStyle },
    J: { v: 'AGENT GST', s: secondHeaderStyle },
    K: { v: 'SKY TRAVEL', s: secondHeaderStyle },
    L: { v: 'SKY TRAVEL GST', s: secondHeaderStyle },
    M: { v: 'TOTAL', s: secondHeaderStyle },
  };
  table1.push(secondheader);
  // pushing body
  const bodyStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'center' },
  };
  const nameStyle = {
    border: { top: { style: 'thin' }, right: { style: 'thin' }, left: { style: 'thin' } },
    alignment: { horizontal: 'left' },
  };
  for (let i in data) {
    table1.push({
      A: { v: parseInt(i) + 1, s: bodyStyle },
      B: { v: moment(data[i].date).format('DD-MM-YYYY'), s: bodyStyle },
      C: { v: data[i].name, s: nameStyle },
      D: { v: data[i].passportNumber, s: bodyStyle },
      E: { v: data[i].nationality, s: bodyStyle },
      F: { v: data[i].invoiceId, s: bodyStyle },
      G: { v: data[i].invoiceDate, s: bodyStyle },
      H: { v: data[i].visaFee, s: bodyStyle },
      I: { v: data[i].agentFee, s: bodyStyle },
      J: { v: data[i].agentFeeGST, s: bodyStyle },
      K: { v: data[i].skyServicefee, s: bodyStyle },
      L: { v: data[i].skyServicefeeGST, s: bodyStyle },
      M: { v: data[i].totalAmount, s: bodyStyle },
    });
  }
  const lastLineStyle = {
    border: { top: { style: 'thin' } },
  };
  table1.push({
    A: { v: '', s: lastLineStyle },
    B: { v: '', s: lastLineStyle },
    C: { v: '', s: lastLineStyle },
    D: { v: '', s: lastLineStyle },
    E: { v: '', s: lastLineStyle },
    F: { v: '', s: lastLineStyle },
    G: { v: '', s: lastLineStyle },
    H: { v: '', s: lastLineStyle },
    I: { v: '', s: lastLineStyle },
    J: { v: '', s: lastLineStyle },
    K: { v: '', s: lastLineStyle },
    L: { v: '', s: lastLineStyle },
    M: { v: '', s: lastLineStyle },
  });
  const wb = XLSX.utils.book_new();
  //create a worksheet
  let sheet = XLSX.utils.json_to_sheet(table1, { skipHeader: true });
  // merging cells
  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 12 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 12 } },
  ];
  // cells width
  var wscols = [
    { wch: 5 },
    { wch: 10 },
    { wch: 25 },
    { wch: 15 },
    { wch: 20 },
    { wch: 10 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 12 },
    { wch: 15 },
    { wch: 10 },
  ];
  sheet['!merges'] = merge;
  sheet['!cols'] = wscols;

  XLSX.utils.book_append_sheet(wb, sheet, 'Submission_List');

  return XLSX.writeFile(wb, `PASSPORT-SUBMISSION-LIST-${currentDate}.xlsx`);
};

export default SubmissionListExport;
