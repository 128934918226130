import { Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { TableStyle } from '../../../uiComponents';
import ReturnRow from './ReturnRow';
import LoaderTwo from '../../../uiComponents/Loader/LoaderTwo';

const { StyledTableCell, StyledTableRow, StyledTableCellTranparent, GreyTableRow } = TableStyle;
const ReturnTable = ({ page, search }) => {
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      loading: visaInvoice.latestReturnedLoading,
      passData: visaInvoice.latestReturned,
    };
  });
  let { passData, loading } = reduxState;

  return (
    <div>
      {console.log(passData)}
      <TableContainer>
        <Table sx={{ minWidth: 650, marginBottom: '20px' }} aria-label='simple table'>
          <TableHead sx={{ borderBottom: '2px solid #EBEFF2' }}>
            <StyledTableRow>
              <StyledTableCellTranparent align='center' sx={{ width: '110px' }}>
                Invoice No
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '110px' }}>
                Date
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '110px' }}>
                PASSPORT NO
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '200px' }}>
                NAME
              </StyledTableCellTranparent>
              {/* <StyledTableCellTranparent align='center' sx={{ width: '110px' }}>
                Status
              </StyledTableCellTranparent> */}
              <StyledTableCellTranparent align='center' sx={{ width: '110px' }}>
                user Id
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '20px' }}>
                delete
              </StyledTableCellTranparent>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              passData?.returnData?.length > 0 ? (
                passData?.returnData?.map((data, index) => (
                  <ReturnRow key={index + data.passportNumber + data?.id} data={data} index={index + 1} page={page} search={search}/>
                ))
              ) : (
                <StyledTableRow>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell
                    colSpan={2}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '80px 0px' }}
                  >
                    No More data
                  </TableCell>
                  <TableCell colSpan={3}></TableCell>
                </StyledTableRow>
              )
            ) : (
              <StyledTableRow>
                <TableCell colSpan={2}></TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '80px 0px' }}
                >
                  <LoaderTwo />
                </TableCell>
                <TableCell colSpan={3}></TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ReturnTable;
