import { get, post } from '../../utils/methods';
import VisaInvoiceConstant from '../constants/VisaInvoice.constants';

export const addInvoiceDetails = (payload, CB) => async (dispatch) => {
  try {
    dispatch({ type: VisaInvoiceConstant.ADD_INVOICE_DETAILS, loading: true });
    const response = await post(`/saveInvoice`, payload);
    if (response?.data?.status === 'failure') {
      dispatch({ type: VisaInvoiceConstant.ADD_INVOICE_DETAILS, loading: false });
      CB && CB({ error: false, data: response?.data });
    } else {
      dispatch({ type: VisaInvoiceConstant.ADD_INVOICE_DETAILS, loading: false });
      CB && CB({ error: false, data: response?.data });
    }
  } catch (error) {
    console.log(error?.response);
    dispatch({ type: VisaInvoiceConstant.ADD_INVOICE_DETAILS, loading: false });
    CB && CB({ error: true, message: error?.response?.data });
  }
};

export const updateInvoiceDetails = (payload, CB) => async (dispatch) => {
  try {
    dispatch({ type: VisaInvoiceConstant.ADD_INVOICE_DETAILS, loading: true });
    const response = await post(`/editInvoice`, payload);
    if (response?.data?.status === 'failure') {
      dispatch({ type: VisaInvoiceConstant.ADD_INVOICE_DETAILS, loading: false });
      CB && CB({ error: false, data: response?.data });
    } else {
      dispatch({ type: VisaInvoiceConstant.ADD_INVOICE_DETAILS, loading: false });
      CB && CB({ error: false, data: response?.data });
    }
  } catch (error) {
    dispatch({ type: VisaInvoiceConstant.ADD_INVOICE_DETAILS, loading: false });
    CB && CB({ error: true, message: error?.response?.data });
  }
};

export const getInvoiceList =
  ({ pageNo = 1, pageSize = 50, search = '' }, CB) =>
  async (dispatch) => {
    try {
      console.log(search);
      dispatch({ type: VisaInvoiceConstant.GET_INVOICE_LIST, loading: true });
      const response = await get(`/getLatestInvoices?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}`);

      if (response?.data?.status === 'failure') {
        dispatch({ type: VisaInvoiceConstant.GET_INVOICE_LIST, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: VisaInvoiceConstant.GET_INVOICE_LIST, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      dispatch({ type: VisaInvoiceConstant.GET_INVOICE_LIST, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const getInvoiceDetail =
  ({ invoiceId }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: VisaInvoiceConstant.GET_INVOICE, loading: true });
      const response = await get(`/getInvoice?invoiceId=${invoiceId}`);
      if (response?.data?.status === 'failure') {
        dispatch({ type: VisaInvoiceConstant.GET_INVOICE, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: VisaInvoiceConstant.GET_INVOICE, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      console.log({ error });
      dispatch({ type: VisaInvoiceConstant.GET_INVOICE, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const getPassportDetails =
  ({ pageNo = 1, country = '', pageSize = 50, searchValue = '' }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: VisaInvoiceConstant.GET_PASSPORTS, loading: true });
      const response = await get(
        `/getPassports?pageNo=${pageNo}&pageSize=${pageSize}&country=${country.toUpperCase()}&passportNumber=${searchValue}`
      );
      if (response?.data?.status === 'failure') {
        dispatch({ type: VisaInvoiceConstant.GET_PASSPORTS, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: VisaInvoiceConstant.GET_PASSPORTS, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      dispatch({ type: VisaInvoiceConstant.GET_PASSPORTS, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const passportSubmission = (payload, CB) => async (dispatch) => {
  try {
    dispatch({ type: VisaInvoiceConstant.ADD_INVOICE_DETAILS, loading: true });
    const response = await post(`/submission`, payload);
    if (response?.data?.status === 'failure') {
      dispatch({ type: VisaInvoiceConstant.ADD_INVOICE_DETAILS, loading: false });
      CB && CB({ error: false, data: response?.data });
    } else {
      dispatch({ type: VisaInvoiceConstant.ADD_INVOICE_DETAILS, loading: false });
      CB && CB({ error: false, data: response?.data });
    }
  } catch (error) {
    dispatch({ type: VisaInvoiceConstant.ADD_INVOICE_DETAILS, loading: false });
    CB && CB({ error: true, message: error?.response?.data });
  }
};

export const changeStatus = (payload, CB) => async (dispatch) => {
  try {
    dispatch({ type: VisaInvoiceConstant.PASSPORT_RETURN, loading: true });
    const response = await post(`/changeStatus`, payload);
    if (response?.data?.status === 'failure') {
      dispatch({ type: VisaInvoiceConstant.PASSPORT_RETURN, loading: false });
      CB && CB({ error: false, data: response?.data });
    } else {
      dispatch({ type: VisaInvoiceConstant.PASSPORT_RETURN, loading: false });
      CB && CB({ error: false, data: response?.data });
    }
  } catch (error) {
    dispatch({ type: VisaInvoiceConstant.PASSPORT_RETURN, loading: false });
    CB && CB({ error: true, message: error?.response?.data });
  }
};
export const changeStatusAll = (payload, CB) => async (dispatch) => {
  try {
    dispatch({ type: VisaInvoiceConstant.PASSPORT_RETURN, loading: true });
    const response = await post(`/changeStatusByList`, payload);
    if (response?.data?.status === 'failure') {
      dispatch({ type: VisaInvoiceConstant.PASSPORT_RETURN, loading: false });
      CB && CB({ error: false, data: response?.data });
    } else {
      dispatch({ type: VisaInvoiceConstant.PASSPORT_RETURN, loading: false });
      CB && CB({ error: false, data: response?.data });
    }
  } catch (error) {
    dispatch({ type: VisaInvoiceConstant.PASSPORT_RETURN, loading: false });
    CB && CB({ error: true, message: error?.response?.data });
  }
};

export const getSubmissionList =
  ({ pageNo = 1, country = '', pageSize = 50, searchValue = '' }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: VisaInvoiceConstant.GET_SUBMISSION_LIST, loading: true });
      const response = await get(
        `/passportSubmission?pageNo=${pageNo}&pageSize=${pageSize}&country=${country.toUpperCase()}&passportNumber=${searchValue}`
      );
      if (response?.data?.status === 'failure') {
        dispatch({ type: VisaInvoiceConstant.GET_SUBMISSION_LIST, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: VisaInvoiceConstant.GET_SUBMISSION_LIST, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      dispatch({ type: VisaInvoiceConstant.GET_SUBMISSION_LIST, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const getReturnList =
  ({ pageNo = 1, country = '', pageSize = 50, searchValue = '' }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: VisaInvoiceConstant.GET_RETURN_PASSPORT_LIST, loading: true });
      const response = await get(
        `/passportReturn?pageNo=${pageNo}&pageSize=${pageSize}&country=${country.toUpperCase()}&passportNumber=${searchValue}`
      );
      if (response?.data?.status === 'failure') {
        dispatch({ type: VisaInvoiceConstant.GET_RETURN_PASSPORT_LIST, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: VisaInvoiceConstant.GET_RETURN_PASSPORT_LIST, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      dispatch({ type: VisaInvoiceConstant.GET_RETURN_PASSPORT_LIST, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const getDeliveryList =
  ({ pageNo = 1, pageSize = 50, search }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: VisaInvoiceConstant.GET_DELIVERED_PASSPORT_LIST, loading: true });
      const response = await get(`/latestDelivery?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}`);
      console.log(response.data);
      if (response?.data?.status === 'failure') {
        dispatch({ type: VisaInvoiceConstant.GET_DELIVERED_PASSPORT_LIST, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: VisaInvoiceConstant.GET_DELIVERED_PASSPORT_LIST, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      dispatch({ type: VisaInvoiceConstant.GET_DELIVERED_PASSPORT_LIST, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const getSubmittedList =
  ({ pageNo = 1, pageSize = 50, search, from = '', to = '' }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: VisaInvoiceConstant.GET_LATEST_SUBMITTED, loading: true });
      let response;
      if (from && to) {
        response = await get(
          `/latestSubmissions?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}&from=${from}&to=${to}`
        );
      } else {
        response = await get(`/latestSubmissions?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}`);
      }

      if (response?.data?.status === 'failure') {
        dispatch({ type: VisaInvoiceConstant.GET_LATEST_SUBMITTED, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: VisaInvoiceConstant.GET_LATEST_SUBMITTED, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      dispatch({ type: VisaInvoiceConstant.GET_LATEST_SUBMITTED, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };

export const getReturnedList =
  ({ pageNo = 1, pageSize = 50, search }, CB) =>
  async (dispatch) => {
    try {
      dispatch({ type: VisaInvoiceConstant.GET_LATEST_RETURNED, loading: true });
      const response = await get(`/latestReturn?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}`);
      if (response?.data?.status === 'failure') {
        dispatch({ type: VisaInvoiceConstant.GET_LATEST_RETURNED, loading: false });
        CB && CB({ error: false, data: response?.data });
      } else {
        dispatch({ type: VisaInvoiceConstant.GET_LATEST_RETURNED, loading: false, data: response?.data });
        CB && CB({ error: false, data: response?.data });
      }
    } catch (error) {
      dispatch({ type: VisaInvoiceConstant.GET_LATEST_RETURNED, loading: false });
      CB && CB({ error: true, message: error?.response?.data });
    }
  };
