import { Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { TableStyle } from '../../../uiComponents';
import SubmissionListRow from './SubmissionListRow';
const { StyledTableRow, StyledTableCellTranparent } = TableStyle;

const SubmissionListTable = ({ page }) => {
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      loading: visaInvoice.latestSubmittedLoading,
      passData: visaInvoice.latestSubmissions,
    };
  });
  let { passData, loading } = reduxState;

  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 650, marginBottom: '20px', minWidth: 'max-content' }} aria-label='simple table'>
          <TableHead sx={{ borderBottom: '2px solid #EBEFF2' }}>
            <StyledTableRow>
              <StyledTableCellTranparent align='center' sx={{ width: '80px' }}>
                Date
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '200px' }}>
                name
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '100px' }}>
                PASSPORT NO{' '}
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '100px' }}>
                Invoice no.
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '100px' }}>
                Invoice Date
              </StyledTableCellTranparent>

              {/* <StyledTableCellTranparent align="center" sx={{ width: "110px" }}>
                user Id
              </StyledTableCellTranparent> */}
              <StyledTableCellTranparent align='center' sx={{ width: '20px' }}>
                delete
              </StyledTableCellTranparent>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              (passData?.submissions?.length > 0 ? (
                passData?.submissions?.map((data, index) => (
                  <SubmissionListRow
                    key={data?.passportNumber + data?.date + index}
                    data={data}
                    index={index + 1}
                    page={page}
                  />
                ))
              ) : (
                <StyledTableRow>
                  <TableCell colSpan={2}></TableCell>
                  <TableCell
                    colSpan={2}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '80px 0px' }}
                  >
                    No More data
                  </TableCell>
                  <TableCell colSpan={3}></TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SubmissionListTable;

{
  /* <SubmissionListRow
key={passportNumber + name}
passport_no={passportNumber}
passport_expiry_date={passportExpiry}
name={name}
gender={gender}
nationality={nationality}
type_of_pass={typeOfPass}
pass_expiry={passExpiry}
visa_for_country={visaForCountry}
comments={""}
return_status={PassportStatus.APPROVED}
/> */
}
