import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { AllButton, CommonModal, TableStyle } from '../../../uiComponents';
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import VisaInvoiceConstant from '../../../store/constants/VisaInvoice.constants';
import { FcEditImage } from 'react-icons/fc';
const { StyledTableCell, StyledTableRow } = TableStyle;
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const { BorderSecondButton } = AllButton;

const VisaInvoiceRow = (props) => {
  const dispatch = useDispatch();
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      passData: visaInvoice.passportDetails,
    };
  });
  let { passData } = reduxState;
  const [showModal, setShowModal] = useState(false);
  const {
    passport_no,
    passport_expiry_date,
    name,
    gender,
    nationality,
    type_of_pass,
    pass_expiry,
    visaFee,
    agentFee,
    agentFeeGST,
    skyServicefee,
    skyServicefeeGST,
    callback,
  } = props;
  const addComment = (e) => {
    e.preventDefault();
  };

  const handleRemoveRow = (e) => {
    e.preventDefault();
    let copyArray = passData.filter((data) => data.passportNumber != passport_no);
    dispatch({
      type: VisaInvoiceConstant.SET_PASSPORT_DATA,
      passportDetails: copyArray,
    });
  };
  const updateModal = () => {
    return (
      <CommonModal showModal={showModal} callback={(e) => setShowModal(false)}>
        <div>
          <section>
            <section className='' style={{ fontSize: '20px', padding: '30px' }}>
              <h5>Are you sure you want to delete it ?</h5>
            </section>

            <section style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <BorderSecondButton sx={{ fontSize: '14px', marginRight: '15px' }} onClick={() => setShowModal(false)}>
                  cancel
                </BorderSecondButton>
              </div>
              <div>
                <BorderSecondButton
                  sx={{
                    fontSize: '14px',
                    marginLeft: '15px',
                    background: '#FF3131',
                    borderColor: '#FF3131',
                    color: '#fff',
                  }}
                  onClick={handleRemoveRow}
                >
                  Delete
                </BorderSecondButton>
              </div>
            </section>
          </section>
        </div>
      </CommonModal>
    );
  };

  return (
    <>
      {showModal && updateModal()}
      <StyledTableRow>
        <StyledTableCell
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            callback({
              passport_no,
              name,
              nationality,
              visaFee,
              agentFee,
              agentFeeGST,
              skyServicefee,
              skyServicefeeGST,
            })
          }
        >
          {passport_no}
        </StyledTableCell>
        {/* <StyledTableCell align="center">{passport_expiry_date}</StyledTableCell> */}
        <StyledTableCell align='center'>{name}</StyledTableCell>
        {/* <StyledTableCell align="center">{gender}</StyledTableCell> */}
        <StyledTableCell align='center'>{nationality}</StyledTableCell>
        {/* <StyledTableCell align="center">{type_of_pass}</StyledTableCell>
        <StyledTableCell align="center">{pass_expiry}</StyledTableCell> */}

        <StyledTableCell align='center'>{visaFee}</StyledTableCell>
        <StyledTableCell align='center'>{agentFee}</StyledTableCell>
        <StyledTableCell align='center'>{agentFeeGST}</StyledTableCell>
        <StyledTableCell align='center'>{skyServicefee}</StyledTableCell>
        <StyledTableCell align='center'>{skyServicefeeGST}</StyledTableCell>
        <StyledTableCell align='center'>
          <IconButton
            onClick={() =>
              callback({
                passport_no,
                name,
                nationality,
                visaFee,
                agentFee,
                agentFeeGST,
                skyServicefee,
                skyServicefeeGST,
              })
            }
          >
            <FcEditImage style={{ fontSize: '20px' }} />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align='center'>
          <IconButton onClick={(e) => setShowModal(true)}>
            <IoClose style={{ fontSize: '20px', color: 'red' }} />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default VisaInvoiceRow;
