import { Button, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CommonHeading from "../../Components/VisaInvoice/CommonHeading";
import VisaInvoiceTable from "../../Components/VisaInvoice/Invoice/VisaInvoiceTable";
import "../../Components/Passport/styles/PassportDelivery.css";
import { CommonGreenHeading, CommonModal, ErrorAlert } from "../../uiComponents";
import { AllButton } from "../../uiComponents";
import RectSelect from "../../uiComponents/Select/RectSelect";
import { Country, State, City } from "country-state-city";
import PassportHeading from "../../Components/Passport/PassportHeading";
import PassportSearch from "../../Components/Passport/PassportSearch";
import PassportDeliveryTable from "../../Components/Passport/Delivery/PassportDeliveryTable";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceDetail } from "../../store/action/VisaInvoice";
import LoaderTwo from "../../uiComponents/Loader/LoaderTwo";
import { useAlert } from "react-alert";
import { get, post } from "../../utils/methods";
import VisaInvoiceConstant from "../../store/constants/VisaInvoice.constants";
import MultiSelect from "../../uiComponents/Select/MultiSelect";
import { MdDelete } from "react-icons/md";

const delivery_status = {
  DELIVERED: "DELIVERED",
  NOT_DELIVERED: "RETURNED",
};

const { FullSecondButton, BorderSecondButton } = AllButton;
const PassportDelivery = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const reduxState = useSelector(({ visaInvoice }) => {
    return {
      invoiceDetails: visaInvoice.invoiceDetails,
      loading: visaInvoice.detailsLoading,
      deliveryPassportsData: visaInvoice.deliveryPassportsData,
    };
  });
  let { invoiceDetails, loading } = reduxState;
  let personalDetails = invoiceDetails?.personalDetail;
  let passportList = invoiceDetails?.passportInfo;
  const [searchValue, setsearchValue] = useState("");
  const initialState = {
    visiterName: "",
    details: "",
  };
  const [values, setValues] = useState(initialState);
  const { visiterName, details } = values;
  const [errors, seterrors] = useState({});
  const [idType, setIdType] = useState("");
  const [passportSelected, setpassportSelected] = useState([]);
  const [passForAddress, setpassForAddress] = useState({ key: "", value: 0, label: "Select Passport" });
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    return () => {
      dispatch({ type: VisaInvoiceConstant.GET_INVOICE, loading: false, data: {} });
      setpassForAddress({ key: "", value: 0, label: "Select Passport" });
    };
  }, []);
  useEffect(() => {
    if (!invoiceDetails?.address) return;
    setValues({
      visiterName: invoiceDetails?.address?.visitorName || "",
      details: invoiceDetails?.details || "",
    });
    return () => {};
  }, [invoiceDetails?.address]);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch({ type: VisaInvoiceConstant.GET_INVOICE, loading: false, data: {} });
    setValues(initialState);
    // setIdType("");
    setpassForAddress({ key: "", value: 0, label: "Select Passport" });
    dispatch(getInvoiceDetail({ invoiceId: searchValue }));
  };
  const validateDelivery = (e) => {
    e.preventDefault();
    if (!visiterName) return alert.error("Please enter all field");
    if (passportSelected.length === 0) return alert.error("Please select passports");
    handleDelivery();
  };
  const handleDelivery = async () => {
    try {
      let payload = {
        clientMobile: personalDetails?.mobile,
        invoiceId: invoiceDetails?.invoiceId,
        visitorName: visiterName,
        passportData: passportSelected,
      };
      const response = await post(`/setDeliveryAddress`, payload);
      if (response?.data?.status == "failure") {
        alert.error(response?.data?.message);
        seterrors({
          delivery: response?.data?.message,
        });
      } else {
        alert.success("Delivery info saved");
        navigate("/passport-delivery-list");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleAddPassport = (e) => {
    if (e.length == 0) return;
    let passDelivery = [];
    e.forEach((element) => {
      passDelivery.push({
        mode: delivery_status?.DELIVERED,
        passportnumber: element.value,
      });
    });
    setpassportSelected(passDelivery);
  };

  const deleteAction = async (e) => {
    try {
      e.preventDefault();
      const response = await get(
        `/unDeliver?invoiceId=${searchValue}&passportNumber=${
          invoiceDetails?.address[passForAddress?.value]?.passportNumber
        }`
      );
      if (response?.data?.status == "failure") {
        alert.error(response?.data?.message);
      } else {
        alert.success("Deleted Successfully");
        setShowModal(false);
        dispatch(getInvoiceDetail({ invoiceId: searchValue }));
      }
    } catch (err) {
      console.log(err);
      if (err?.response) {
        alert.error(err?.response?.data?.error);
      }
    }
  };
  const updateModal = () => {
    return (
      <CommonModal showModal={showModal} callback={(e) => setShowModal(false)}>
        <div>
          <section>
            <section className="" style={{ fontSize: "20px", padding: "30px" }}>
              <h5>this action will reset it to return mode </h5>
            </section>

            <section style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <BorderSecondButton sx={{ fontSize: "14px", marginRight: "15px" }} onClick={() => setShowModal(false)}>
                  cancel
                </BorderSecondButton>
              </div>
              <div>
                <BorderSecondButton
                  sx={{
                    fontSize: "14px",
                    marginLeft: "15px",
                    background: "#FF3131",
                    borderColor: "#FF3131",
                    color: "#fff",
                  }}
                  onClick={deleteAction}
                >
                  Delete
                </BorderSecondButton>
              </div>
            </section>
          </section>
        </div>
      </CommonModal>
    );
  };

  return (
    <div className="visa_invoice">
      {showModal && updateModal()}
      {/* client details section start */}
      <section className="" style={{ textAlign: "center" }}>
        <PassportHeading title={"Passport Delivery"} />
      </section>
      <section className="search_div_button">
        <h4 style={{ fontSize: "16px", fontWeight: "400" }}>Enter invoice No.</h4>{" "}
        <div className="search_div_inner ">
          <PassportSearch
            value={searchValue}
            placeholder="Search by invoice number"
            callback={(e) => setsearchValue(e.target.value)}
          />
        </div>
        <div>
          <FullSecondButton sx={{ fontSize: "14px" }} onClick={handleSearch}>
            Show
          </FullSecondButton>
        </div>
      </section>
      {loading && (
        <div style={{ display: "flex", justifyContent: "center", margin: "100px" }}>
          <LoaderTwo />
        </div>
      )}
      {/* header section end */}
      {invoiceDetails?.invoiceId ? (
        <div className="visa_invoice_second">
          <section className="visa_invoice_details_div" style={{ marginBottom: "50px" }}>
            <section className="singleLineInput">
              <h5 className="first_label">Invoice No. :</h5>{" "}
              <h4 className="second_value">{invoiceDetails?.invoiceId}</h4>
            </section>
            <section className="singleLineInput">
              <h5 className="first_label">Invoice Date :</h5>{" "}
              <h4 className="second_value">{invoiceDetails?.invoiceDate}</h4>
            </section>
          </section>
          <h2>Personal Details</h2>
          {/* client details section start */}
          <section className="visa_invoice_details_div" style={{ marginBottom: "50px" }}>
            <section className="singleLineInput">
              <h5 className="first_label">Client Name :</h5> <h4 className="second_value">{personalDetails?.name}</h4>
            </section>
            <section className="singleLineInput">
              <h5 className="first_label">Mobile No. :</h5> <h4 className="second_value">{personalDetails?.mobile}</h4>
            </section>
            <br />
            {/* <section className="singleLineInput">
              <h5 className="first_label">Address:</h5> <h4 className="second_value">{personalDetails?.address}</h4>
            </section>
            <section className="singleLineInput">
              <h5 className="first_label">City :</h5> <h4 className="second_value">{personalDetails?.city}</h4>
            </section>
            <section className="singleLineInput">
              <h5 className="first_label">Country :</h5> <h4 className="second_value">{personalDetails?.country}</h4>
            </section>
            <section className="singleLineInput">
              <h5 className="first_label">Email :</h5> <h4 className="second_value">{personalDetails?.email}</h4>
            </section> */}
            <br />
          </section>
          <CommonGreenHeading heading={"Passport Details"} />
          {/* client details section end */}
          {/* members details section start */}

          {/* members details section end */}
        </div>
      ) : null}

      {/* passport details section start */}
      {invoiceDetails?.invoiceId ? (
        <section style={{ marginBottom: "40px" }}>
          <PassportDeliveryTable />
        </section>
      ) : null}

      {invoiceDetails?.invoiceId ? (
        <>
          <section className="add_delivery_form ">
            <h3>Add Delivery details</h3>
            <div className="visa_invoice_details_div">
              <section className="visa_invoice_input_div" style={{ width: "100%" }}>
                <h5>Select Passport</h5>
                <MultiSelect
                  defaultValue={passportSelected}
                  data={passportList?.map(({ passportNumber }, index) => {
                    return {
                      key: passportNumber + index,
                      value: passportNumber,
                      label: passportNumber,
                    };
                  })}
                  callback={handleAddPassport}
                />
              </section>{" "}
              <br />
              <br />
              <section className="" style={{width:"100%", marginTop:"20px", marginRight:"20px"}}>
                <h5>Visitor Name and Details</h5>
                <div >
                  <textarea
                  style={{borderRadius:"10px", width:"100%", padding:"10px"}}
                    type="text"
                    placeholder="Visiter Details"
                    rows={5}
                    value={visiterName}
                    name={"visiterName"}
                    onChange={handleChangeInput}
                  />
                </div>
              </section>
              {/* <section className="visa_invoice_input_div">
                <h5>Mobile</h5>
                <div className="visa_invoice_input_inner">
                  <input
                    type="text"
                    placeholder="Mobile"
                    value={mobileNumber}
                    name={"mobileNumber"}
                    onChange={handleChangeInput}
                  />
                </div>
              </section> */}
              {/* <section className="delivery_address_section">
                <h5>Details</h5>
                <div className="visa_invoice_input_inner">
                  <input
                    type="text"
                    placeholder="Details"
                    value={details}
                    name={"details"}
                    onChange={handleChangeInput}
                  />
                </div>
              </section> */}
              {/* <section className="visa_invoice_input_div">
                <h5>ID type</h5>
                <div className="visa_invoice_input_inner">
                  <input
                    type="text"
                    placeholder="ID type"
                    value={idType}
                    name={"idType"}
                    onChange={(e) => setIdType(e.target.value)}
                  />
                </div>
              </section>
              <section className="visa_invoice_input_div">
                <h5>ID No.</h5>
                <div className="visa_invoice_input_inner">
                  <input type="text" placeholder="ID no." value={idNo} name={"idNo"} onChange={handleChangeInput} />
                </div>
              </section> */}
              <br />
            </div>
          </section>
          {errors?.delivery && (
            <div style={{ marginTop: "50px", display: "flex", justifyContent: "center" }}>
              <ErrorAlert error={errors.delivery} />
            </div>
          )}
          <section style={{ marginTop: "40px", display: "flex", justifyContent: "center" }}>
            <div style={{ minWidth: "280px" }}>
              <FullSecondButton onClick={validateDelivery}>Save </FullSecondButton>
            </div>
          </section>
        </>
      ) : null}
      {/* // visitor data  */}
      {invoiceDetails?.address?.length > 0 && invoiceDetails?.invoiceId ? (
        <>
          <section className="add_delivery_form " style={{ marginTop: "50px" }}>
            <h3>Visitor Data</h3>
            <div className="visa_invoice_details_div">
              <section className="visa_invoice_input_div" style={{ width: "100%" }}>
                <h5>Select Passport</h5>
                <RectSelect
                  defaultValue={passForAddress}
                  data={passportList?.map(({ passportNumber }, index) => ({
                    key: passportNumber + index,
                    value: index,
                    label: passportNumber,
                  }))}
                  callback={setpassForAddress}
                />
              </section>{" "}
              <section style={{ textAlign: "end", alignSelf: "end", marginLeft: "auto", width: "100%" }}>
                {invoiceDetails?.address[passForAddress?.value] && (
                  <>
                    <IconButton onClick={() => setShowModal(true)}>
                      <MdDelete style={{ fontSize: "20px", color: "red" }} />
                    </IconButton>
                  </>
                )}
              </section>{" "}
              <br />
              <br />
              <br />
              {invoiceDetails?.address[passForAddress?.value] ? (
                <>
                
                  <section className="" style={{width:"100%", marginTop:"20px", marginRight:"20px"}}>
                <h5>Visitor Name and Details</h5>
                <div >
                  <textarea
                  disabled={true}
                  style={{borderRadius:"10px", width:"100%", padding:"10px"}}
                    type="text"
                    placeholder="Visiter Details"
                    rows={5}
                    value={
                      invoiceDetails?.address[passForAddress?.value]
                        ? invoiceDetails?.address[passForAddress?.value]?.visitorName
                        : ""
                    }
                    name={"visiterName"}
                    onChange={handleChangeInput}
                  />
                </div>
              </section>
                  {/* <section className="visa_invoice_input_div">
                    <h5>Mobile</h5>
                    <div className="visa_invoice_input_inner">
                      <input
                        disabled={true}
                        type="text"
                        placeholder="Mobile"
                        value={
                          invoiceDetails?.address[passForAddress?.value]
                            ? invoiceDetails?.address[passForAddress?.value]?.mobile
                            : ""
                        }
                        name={"mobileNumber"}
                        onChange={handleChangeInput}
                      />
                    </div>
                  </section>
                  <section className="delivery_address_section">
                    <h5>Address</h5>
                    <div className="visa_invoice_input_inner">
                      <input
                        disabled={true}
                        type="text"
                        placeholder="Address"
                        value={
                          invoiceDetails?.address[passForAddress?.value]
                            ? invoiceDetails?.address[passForAddress?.value]?.address
                            : ""
                        }
                        name={"address"}
                        onChange={handleChangeInput}
                      />
                    </div>
                  </section>
                  <section className="visa_invoice_input_div">
                    <h5>ID type</h5>
                    <div className="visa_invoice_input_inner">
                      <input
                        disabled={true}
                        type="text"
                        placeholder="ID type"
                        value={
                          invoiceDetails?.address[passForAddress?.value]
                            ? invoiceDetails?.address[passForAddress?.value]?.idType
                            : ""
                        }
                        name={"idType"}
                        onChange={(e) => setIdType(e.target.value)}
                      />
                    </div>
                  </section>
                  <section className="visa_invoice_input_div">
                    <h5>ID No.</h5>
                    <div className="visa_invoice_input_inner">
                      <input
                        type="text"
                        placeholder="ID no."
                        value={
                          invoiceDetails?.address[passForAddress?.value]
                            ? invoiceDetails?.address[passForAddress?.value]?.idNumber
                            : ""
                        }
                        name={"idNo"}
                        onChange={handleChangeInput}
                      />
                    </div>
                  </section> */}
                </>
              ) : (
                <div style={{ textAlign: "center", marginTop: "25px", width: "100%" }}>
                  <h3>Passport Not delivered till now</h3>
                </div>
              )}
              <br />
            </div>
          </section>
        </>
      ) : null}
    </div>
  );
};

export default PassportDelivery;
