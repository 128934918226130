export const signInValidate = (values) => {
  var errors = {};
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  if (!values.email) {
    errors.email = 'Email is required!';
  } else if (!regex.test(values.email)) {
    errors.email = 'This is not a valid email format!';
  }
  if (!values.password) {
    errors.password = 'password is required';
  }
  return errors;
};

export const visaInvoiceValidate = (values) => {
  let { clientName, mobileNumber, passports, consultantName } = values;
  var errors = {};
  const emptyError = (value) => `Please enter ${value}`;
  const selectError = (value) => `Please select ${value}`;
  const validError = (value) => `Please enter valid ${value}`;
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  if (clientName?.length < 3) {
    errors.clientName = validError('client name');
  }
  if (!clientName) {
    errors.clientName = emptyError('client name');
  }
  if (mobileNumber?.length < 6) {
    errors.mobileNumber = validError('mobile number');
  }
  if (!mobileNumber) {
    errors.mobileNumber = emptyError('mobile number');
  }
  if (!consultantName) {
    errors.consultantName = emptyError('consultant name');
  }
  if (passports?.length == 0) {
    errors.passports = 'Please add atleast 1 passport';
  }
  return errors;
};

export const passportValidate = (values) => {
  let {
    memberName,
    passportNumber,
    nationality,
    visaFee,
    agentFee,
    agentFeeGST,
    skyServicefee,
    skyServicefeeGST,
    totalfee,
  } = values;
  var errors = {};
  const emptyError = (value) => `Please enter ${value}`;
  const selectError = (value) => `Please select ${value}`;
  const validError = (value) => `Please enter valid ${value}`;
  let pattern = /^(d|\w)+$/;
  if (memberName?.length < 3) {
    errors.memberName = validError('name');
  }
  if (!memberName) {
    errors.memberName = emptyError('name');
  }
  if (passportNumber?.length < 5) {
    errors.passportNumber = validError('passport number');
  }
  if (!pattern.test(passportNumber)) {
    errors.passportNumber = validError('passport number');
  }
  if (!passportNumber) {
    errors.passportNumber = emptyError('passport number');
  }
  // if (!gender) {
  //   errors.gender = emptyError('gender');
  // }
  if (!nationality) {
    errors.nationality = selectError('nationality');
  }
  // if (!totalfee) {
  //   errors.total = emptyError('total fee');
  // }

  return errors;
};

export const ticketInvoiceValidate = (values) => {
  let { clientName, mobile, paymentMethod, passengerData, consultantName } = values;
  var errors = {};
  const emptyError = (value) => `Please enter ${value}`;
  const validError = (value) => `Please enter valid ${value}`;
  if (clientName?.length < 3) {
    errors.clientName = validError('client name');
  }
  if (!clientName) {
    errors.clientName = emptyError('client name');
  }
  if (mobile?.length < 6) {
    errors.mobileNumber = validError('mobile number');
  }
  if (!mobile) {
    errors.mobileNumber = emptyError('mobile number');
  }
  if (passengerData?.length == 0) {
    errors.passengerData = 'please add tickets';
  }
  if (!consultantName) {
    errors.consultantName = emptyError('consultant name');
  }
  return errors;
};
export const ticketValidate = (values) => {
  let { ticketNumber, paxName, departureDate, flightNumber, route, fare, taxes, skyTravelFee, totalfee } = values;
  var errors = {};
  const emptyError = (value) => `Please enter ${value}`;
  const selectError = (value) => `Please select ${value}`;
  const validError = (value) => `Please enter valid ${value}`;
  if (paxName.length < 3) {
    errors.paxName = validError('name');
  }
  if (!paxName) {
    errors.paxName = emptyError('name');
  }
  if (!ticketNumber) {
    errors.ticketNumber = emptyError('ticket number');
  }
  if (!flightNumber) {
    errors.flightNumber = emptyError('flight number');
  }
  if (!route) {
    errors.route = emptyError('route');
  }
  if (departureDate.length < 5) {
    errors.departureDate = validError('departure date');
  }
  if (!totalfee) {
    errors.totalfee = emptyError('Total Fee');
  }
  return errors;
};

export const miscInvoiceValidate = (values) => {
  let { clientName, clientMobile, productData } = values;
  var errors = {};
  const emptyError = (value) => `Please enter ${value}`;
  const validError = (value) => `Please enter valid ${value}`;
  if (clientName?.length < 3) {
    errors.clientName = validError('client name');
  }
  if (!clientName) {
    errors.clientName = emptyError('client name');
  }
  if (clientMobile?.length < 6) {
    errors.clientMobile = validError('mobile number');
  }
  if (!clientMobile) {
    errors.clientMobile = emptyError('mobile number');
  }
  if (productData?.length == 0) {
    errors.productData = 'List should not be empty';
  }
  return errors;
};
export const miscValidate = (values) => {
  let { product, description, rate, qty } = values;
  var errors = {};
  const emptyError = (value) => `Please enter ${value}`;
  const validError = (value) => `Please enter valid ${value}`;
  if (product.length < 3) {
    errors.product = validError('name');
  }
  if (!product) {
    errors.product = emptyError('name');
  }
  if (!rate) {
    errors.rate = emptyError('rate');
  }
  if (!qty) {
    errors.qty = emptyError('qty');
  }
  return errors;
};
