import { Route, Routes } from 'react-router-dom';
import Login from '../pages/Auth/Login';
import React, { Fragment } from 'react';
import Dashboard from '../pages/Dashboard';
import VisaInvoiceReview from '../pages/VisaInvoice/VisaInvoiceReview';
import StatusTrack from '../pages/VisaInvoice/StatusTrack';
import PassportReturn from '../pages/Passport/PassportReturn';
import PassportSubmission from '../pages/Passport/PassportSubmission';
import VisaInvoiceStatement from '../pages/VisaInvoice/VisaInvoiceStatment';
import VisaInvoice from '../pages/VisaInvoice/VisaInvoice';
import TicketStatements from '../pages/Ticket/TicketStatements';
import TicketInvoice from '../pages/Ticket/TicketInvoice';
import MainLayout from '../Components/Layout/MainLayout';
import PassportDelivery from '../pages/Passport/PassportDelivery';
import VisaInvoiceList from '../pages/VisaInvoice/VisaInvoiceList';
import TicketInvoiceList from '../pages/Ticket/TicketInvoiceList';
import { getTokenAndSetIntoHeaders, getValueIntoStorage } from '../utils/storage/Functions';
import NotFoundPage from '../pages/NotFoundPage';
import VisaInvoiceEdit from '../pages/VisaInvoice/VisaInvoiceEdit';
import TicketInvoicePrint from '../pages/Ticket/TicketInvoicePrint';
import SubmissionList from '../pages/Passport/SubmissionList';
import ReturnList from '../pages/Passport/ReturnList';
import DeliveryList from '../pages/Passport/DeliveryList';
import UsersList from '../pages/Auth/UsersList';
import PasswordChange from '../pages/Auth/PasswordChange';
import CreateUser from '../pages/Auth/CreateUser';
import { TOKEN } from '../utils/storage/Constant';
import VisaInvoicePrint from '../pages/VisaInvoice/VisaInvoicePrint';
import MiscList from '../pages/Misc/MiscList';
import MiscInvoice from '../pages/Misc/MiscInvoice';
import MiscInvoicePrint from '../pages/Misc/MiscInvoicePrint';
import MiscStatements from '../pages/Misc/MiscStatement';
import { useSelector } from 'react-redux';

const routes = [
  {
    path: '/dashboard',
    exact: true,
    component: <Dashboard />,
  },
  {
    path: '/',
    exact: true,
    component: <Dashboard />,
  },
  {
    path: '/visa-invoice-list',
    exact: true,
    component: <VisaInvoiceList />,
  },
  {
    path: '/visa-invoice',
    exact: true,
    component: <VisaInvoice />,
  },
  {
    path: '/visa-invoice-review',
    exact: true,
    component: <VisaInvoiceReview />,
  },
  {
    path: '/visa-invoice-print',
    exact: true,
    component: <VisaInvoicePrint />,
  },
  {
    path: '/visa-invoice-edit',
    exact: true,
    component: <VisaInvoiceEdit />,
  },
  {
    path: '/passport-submission-list',
    exact: true,
    component: <SubmissionList />,
  },
  {
    path: '/passport-submission',
    exact: true,
    component: <PassportSubmission />,
  },
  {
    path: '/passport-return-list',
    exact: true,
    component: <ReturnList />,
  },
  {
    path: '/passport-return',
    exact: true,
    component: <PassportReturn />,
  },
  {
    path: '/passport-delivery-list',
    exact: true,
    component: <DeliveryList />,
  },
  {
    path: '/passport-delivery',
    exact: true,
    component: <PassportDelivery />,
  },

  {
    path: '/visa-status-track',
    exact: true,
    component: <StatusTrack />,
  },
  {
    path: '/visa-invoice-statement',
    exact: true,
    component: <VisaInvoiceStatement />,
  },

  {
    path: '/ticket-invoice-list',
    exact: true,
    component: <TicketInvoiceList />,
  },
  {
    path: '/ticket-invoice',
    exact: true,
    component: <TicketInvoice />,
  },
  {
    path: '/ticket-invoice-print',
    exact: true,
    component: <TicketInvoicePrint />,
  },
  {
    path: '/ticket-invoice-statement',
    exact: true,
    component: <TicketStatements />,
  },
  {
    path: '/misc-list',
    exact: true,
    component: <MiscList />,
  },
  {
    path: '/misc-invoice',
    exact: true,
    component: <MiscInvoice />,
  },
  {
    path: '/misc-invoice-print',
    exact: true,
    component: <MiscInvoicePrint />,
  },
  {
    path: '/misc-invoice-statement',
    exact: true,
    component: <MiscStatements />,
  },
  {
    path: '/users-list',
    exact: true,
    component: <UsersList />,
  },
  {
    path: '/change-password',
    exact: true,
    component: <PasswordChange />,
  },
  {
    path: '/create-user',
    exact: true,
    component: <CreateUser />,
  },
];

function Auth() {
  let access_token = getValueIntoStorage(TOKEN);

  return (
    <Fragment>
      <Routes>
        <Route path='/' element={<StatusTrack />} />
        <Route path='/login' element={<Login />} />
        <Route path='*' element={access_token ? <NotFoundPage /> : <StatusTrack />} />
      </Routes>
    </Fragment>
  );
}

function Root() {
  // const history = useNavigate();
  // const user = useSelector(({ users }) => users?.user);
  // const dispatch = useDispatch();
  getTokenAndSetIntoHeaders();
  return (
    <Fragment>
      <div className=''>
        <div className=''>
          <Routes>
            <Route path='/' element={<MainLayout />}>
              {routes.map((route, index) => (
                <Route key={index} exact={route?.exact} path={route?.path} element={route?.component} />
              ))}
            </Route>

            {/* <Route path={`/`} exact={true} element={<Dashboard />} /> */}
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
    </Fragment>
  );
}

export { Auth, Root };
