import { Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { TableStyle } from '../../../uiComponents';
import LoaderTwo from '../../../uiComponents/Loader/LoaderTwo';
import UsersRoles from '../../../utils/Constants/UserRoles.constants';
import UsersRow from './UsersRow';
const { StyledTableRow, StyledTableCellTranparent } = TableStyle;
const UsersTable = () => {
  const reduxState = useSelector(({ users }) => {
    return {
      loading: users.getUserLoading,
      usersList: users.usersList,
      user: users.user,
    };
  });
  let { usersList, loading, user } = reduxState;

  return (
    <div>
      {' '}
      <TableContainer>
        <Table sx={{ minWidth: 650, marginBottom: '20px' }} aria-label='simple table'>
          <TableHead sx={{ borderBottom: '2px solid #EBEFF2' }}>
            <StyledTableRow>
              <StyledTableCellTranparent sx={{ width: '200px' }}>name</StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '110px' }}>
                email
              </StyledTableCellTranparent>
              <StyledTableCellTranparent align='center' sx={{ width: '150px' }}>
                mobile
              </StyledTableCellTranparent>

              <StyledTableCellTranparent align='center' sx={{ width: '110px' }}>
                Role
              </StyledTableCellTranparent>
              {user.role === UsersRoles.ROLE_ADMIN && (
                <StyledTableCellTranparent align='center' sx={{ width: '120px' }}>
                  Status
                </StyledTableCellTranparent>
              )}
              {user.role === UsersRoles.ROLE_ADMIN && (
                <StyledTableCellTranparent align='center' sx={{ width: '120px' }}>
                  Change Password
                </StyledTableCellTranparent>
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!loading ? (
              usersList?.length > 0 &&
              usersList?.map(({ emailId, id, mobile, name, role, username, active }, index) => (
                <UsersRow
                  key={emailId + mobile + index}
                  id={id}
                  emailId={emailId}
                  mobile={mobile}
                  name={name}
                  role={role}
                  username={username}
                  active={active}
                />
              ))
            ) : (
              <StyledTableRow>
                <TableCell colSpan={1}></TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '80px 0px' }}
                >
                  <LoaderTwo />
                </TableCell>
                <TableCell colSpan={1}></TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UsersTable;
